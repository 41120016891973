import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import installations from './modules/installations'
import cards from './modules/cards'
import error from './modules/error'
import gp4am_coins from './modules/gp4am_coins'
import userTexts from './modules/userTexts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login: login,
    installations: installations,
    cards: cards,
    error: error,
    gp4am_coins: gp4am_coins,
    userTexts: userTexts,
  }
})
