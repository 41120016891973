<template>
  <!--<v-card  min-height="400" class="pa-2" outlined tile> Card passes </v-card>-->
  <div></div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  methods: {
    do() {
      window.console.log("do");
    },
  },
};
</script>
