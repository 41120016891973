<template>
  <v-row><v-col cols="12">
    <v-row>
      <NotificationNewBtn @create="createNew"></NotificationNewBtn>
    </v-row>
    <v-row class="mr-4 ml-0">
      <NotificationEdit v-model="selected"
        @close="selected = undefined; selectedId = undefined;"
        :deleteBtn="deleteBtn"
        :title="deleteBtn ? $t('notifications.editTitle') : $t('notifications.newTitle')"
        @saveNotification="saveNotification"
        @deleteNotification="deleteNotification">
      </NotificationEdit>
    </v-row>
    <v-row>
      <v-col cols="12" class="pl-4 pt-8">
        <NotificationList :value="notifications" @input="onClickItem" :selectedId="selectedId"></NotificationList>
      </v-col>
    </v-row>
  </v-col></v-row>
</template>

<script>
import Provider from "@/api/provider";
import Config from "@/config/config";
import NotificationEdit from "@/components/main/notifications/NotificationEdit.vue";
import Notification from "@/components/main/notifications/Notification.vue";
import NotificationList from "@/components/main/notifications/NotificationList.vue";
import NotificationNewBtn from "@/components/main/notifications/NotificationNewBtn.vue";
import util from "util";

export default {
  name: "Notifications",
  components: {NotificationEdit, Notification, NotificationList, NotificationNewBtn},

  data() {
    return {
      sortBy: "filterType",
      descending: false,
      notifications: [],
      selectedId: undefined,
      selected: undefined,
      deleteBtn: true,
    }
  },

  created() {
    this.loadNotifications();
  },

  computed: {
    height() {
      return this.$windowHeight -180
    },

  },

  methods: {
    async loadNotifications() {
      this.selectedId = undefined
      await Provider.getData(Config.endpointNotifications, {})
          .then((response) => {
            this.notifications = response;
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          });
    },

    onClickItem: async function (item) {
      this.selectedId = item._id
      this.selected = item
      this.deleteBtn = true
    },

    clickSort: function(field) {
      if(this.sortBy === field)
        this.descending = !this.descending
      else
        this.sortBy = field
    },

    createNew(item) {
      this.selected = item
      this.selectedId = undefined
      this.deleteBtn = false
    },

    async saveNotification(item) {
      if (item._id === undefined) {
        await Provider.postData(Config.endpointNotifications, item, {})
          .then((response) => {
            this.loadNotifications();
            this.onClickItem(response);
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          });
      } else {
        await Provider.putData(util.format(Config.endpointNotificationsVar, item._id), item, {})
          .then((response) => {
            this.loadNotifications();
            this.onClickItem(response);
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          });
      }
    },

    async deleteNotification(item) {
      await Provider.deleteData(util.format(Config.endpointNotificationsVar, item._id), {})
          .then((response) => {
            this.selected = undefined
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          });
      this.loadNotifications();
    }
  }

}
</script>

<style scoped>
  .row-pointer  {
    cursor: pointer;
  }

  .active {
    background-color: #dddddd;
  }

  .overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
</style>