<template>
    <v-row :class="generateColor()+` pa-0`" @click="onClickItem">
          <v-col cols="1" class="pl-5 text-center pb-0"></v-col>
          <v-col cols="1" class="pl-8 pt-0 pb-0"><DeviceStatusIcon :device="device"/></v-col>
          <v-col cols="1" class="pl-8 pt-0 pb-0"><DeviceInfoIcon :device="device"/></v-col>
          <v-col cols="1" class="text-center font-italic pr-0 pt-2 pb-0">{{device.hap}}</v-col>
          <v-col cols="3" class="text-center font-italic pr-0 pl-10 pt-2 pb-0">{{device.name}}</v-col>
          <v-col cols="2" class="text-center pl-4 pr-0 pt-1">
            <DeviceTypeIcon :type="device.type"/>
          </v-col>
          <v-col cols="2" class="text-center pt-0 pr-0 pl-4 pb-3">
            <DeviceDeleteDialog
              :device="device"
              :installation="installation"
              @deleted="deviceDeleted"></DeviceDeleteDialog>
          </v-col>
      </v-row>
</template>

<script>
import DeviceDeleteDialog from "@/components/main/devices/management/DeviceDeleteDialog"
import DeviceTypeIcon from "@/components/main/devices/DeviceTypeIcon";
import DeviceStatusIcon from "@/components/main/devices/DeviceStatusIcon";
import DeviceInfoIcon from "@/components/main/devices/DeviceInfoIcon"

export default {
  name: "",
  props: {
    device: {},
    installation: {},
    selectedDeviceId: undefined,
  },
  components: { DeviceTypeIcon, DeviceStatusIcon, DeviceDeleteDialog, DeviceInfoIcon },
  methods: {
    generateColor() {
      if (this.selectedDeviceId === this.device._id) {
        return "row-pointer grey lighten-3"
      } else {
        return "row-pointer white"
      }
    },
    clickOnReportItem(item) {
      this.selectedReport = item;
      let index = this.expanded.findIndex((t) => t._id === item._id);
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    onClickItem: async function () {
      this.$emit("input", this.device);
      await this.$nextTick();
    },
    deviceDeleted(data) {
      this.$emit("deleted", data)
    },
  },
  data() {
    return {
      pageText: "",
      search: "",
    };
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tr :hover {
  cursor: pointer;
  background-color: #11aaaa;
}

::v-deep tr.v-data-table__selected {
  background: #eeeeee !important;
}
</style>
