<template>
  <div class="pa-0 pt-3 pb-2 ma-0">
    <div class="pa-0 pr-3 pl-3" v-for="device in filterChildDevices(devices)"
          :key="device._id">
          <HubDeviceRow :device="device" @input="deviceSelected" :installation="installation" @deleted="deviceDeleted" :selectedDeviceId="selectedDeviceId"/>
    </div>
  </div>
</template>

<script>
import Config from "@/config/config";
import HubDeviceRow from "@/components/main/devices/management/HubDeviceRow";

export default {
  name: "",
  props: {
    devices: Array,
    installation: {},
    device: {},
    selectedDeviceId: undefined,
  },
  components: { HubDeviceRow },
  computed: {
    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },
  },
  mounted() {
    //this.windowSize = {x: window.innerWidth, y: window.innerHeight};
  },
  methods: {
    paginate(page) {
      this.pageText = Config.generatePaginationOfText(
        page,
        this.$t("general.pagination_page_of")
      );
    },
    itemRowBackground(item) {
      return "grey lighten-4";
    },
    clickOnReportItem(item) {
      this.selectedReport = item;
      let index = this.expanded.findIndex((t) => t._id === item._id);
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    filterChildDevices(devices) {
      let childs = [];
      //window.console.log(JSON.stringify(devices))
      //window.console.log(JSON.stringify(this.device))
      childs = devices.filter(device => (device.parent_id === this.device._id));
      return childs;
    },
    deviceSelected(data) {
      //this.selectedDeviceId=data._id;
      this.$emit("input", data)
    },
    deviceDeleted(data) {
      //this.selectedDeviceId=undefined;
      this.$emit("deleted", data)
    },
  },
  data() {
    return {
      pageText: "",
      search: "",
      headers: [
        {
          text: "",
          align: "center",
          value: "is_connected",
          class: "grey lighten-2 pa-0",
          width: "5px",
          sortable: false,
        },
        {
          textKey: "device.device_type",
          text: this.$t("device.device_type"),
          align: "center",
          value: "type",
          class: "grey lighten-2 pa-0",
        },
        {
          textKey: "device.device_name",
          text: this.$t("device.device_name"),
          align: "center",
          value: "name",
          class: "grey lighten-2 pa-0",
        },
        {
          textKey: "device.hub_name",
          text: this.$t("device.hub_name"),
          align: "center",
          value: "installation_name",
          class: "grey lighten-2 pa-0",
        },
        {
          text: "",
          align: "center",
          value: "actions",
          class: "grey lighten-2 pa-0",
          sortable: false,
        },
      ],
    };
  },
};
</script>
 