<template>
  <v-row no-gutters>
    <v-col cols="4 pa-2" class="font-weight-medium">{{ $t("device.states.paybox.cashbox_state") }}</v-col>

    <v-col cols="8 pa-2">
      <v-row no-gutters class="mt-2">
        <v-col cols="5" class="font-weight-medium">
          {{ $t("device.states.paybox.cashbox_coins_total") }}:
        </v-col>
        <v-col cols="7">
          {{ totalCoins }}
        </v-col>
      </v-row>
      <v-divider class="mt-2" />

      <v-row no-gutters>
        <v-col cols="5" class="font-weight-medium">
          {{ $t("device.states.paybox.cashbox_coins") }}:
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3" v-for="(count, currency) in device.cash_status.cash_actual_status.actual_coin_count" :key="currency">
          {{ getCoinNameForCode(currency) }} ({{count}})
        </v-col>
      </v-row>

      <v-divider class="mt-2"/>

      <v-row no-gutters class="mt-2">
        <v-col cols="5" class="font-weight-medium">
          {{ $t("device.states.paybox.cashbox_state_total_cash") }}:
        </v-col>
        <v-col cols="7" v-for="(count, currency) in device.cash_status.cash_actual_status.actual_cash_value" :key="currency">
          {{count}} {{currency}}
        </v-col>
      </v-row>
      <v-divider class="mt-2" />

      <v-row no-gutters class="mt-2">
        <v-col cols="5" class="font-weight-medium">
            {{ $t("device.states.paybox.cashbox_state_total_cashless") }}:
        </v-col>
        <v-col cols="7">
          {{ device.cash_status.cashless_actual_status.last_withdrawal_cashless_income }}
        </v-col>
      </v-row>
      <v-divider class="mt-2" />

      <v-row no-gutters class="mt-2">
        <v-col cols="5" class="font-weight-medium">
          {{ $t("device.states.paybox.cashbox_last_empty") }}:
        </v-col>
        <v-col cols="7">
          {{ (new Date (Date.parse(device.cash_status.cash_actual_status.last_withdrawal_timestamp))).toLocaleString(this.$i18n.locale) }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12"><v-divider></v-divider></v-col>


    <v-col cols="4" class="pa-2 font-weight-medium">
      {{ $t("device.states.paybox.first_payment") }}:
    </v-col>
    <v-col cols="8" class="pa-2">
      {{ (new Date (Date.parse(device.cash_status.cash_lifetime_status.lifetime_timestamp))).toLocaleString(this.$i18n.locale) }}
    </v-col>
    <v-col cols="2 pa-2" class="font-weight-medium">{{ $t("device.states.paybox.total_cash") }}</v-col>
    <v-col cols="4 pa-2">
      <v-row v-for="(value, currency) in device.cash_status.cash_lifetime_status.lifetime_cash_income" :key="currency" no-gutters>
        {{ value }} {{ currency }}
      </v-row>
    </v-col>
    <v-col cols="2" class="pa-2 font-weight-medium">{{ $t("device.states.paybox.total_cashless") }}</v-col>
    <v-col cols="4" class="pa-2">{{ device.cash_status.cashless_lifetime_status.lifetime_cashless_income }}</v-col>
    <v-col cols="12"><v-divider></v-divider></v-col>

  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GP4AM_states",
  props: ["device"],
  computed: {
    ...mapGetters(["getCoinNameForCode"]),
    totalCoins() {
      if (!this.device.cash_status.cash_actual_status.actual_coin_count ||
          Object.values(this.device.cash_status.cash_actual_status.actual_coin_count) < 1)
        return 0

      return Object.values(this.device.cash_status.cash_actual_status.actual_coin_count)
          .reduce((a, b) => a + b)
    }
  }
}
</script>

<style scoped>

</style>