<template>
  <v-container fill-height align-content-start fluid class="pa-0">
    <v-app-bar app color="green darken-1" dark elevation="5" style="z-index: 5">
      <v-row>
        <v-col cols="8">
          <v-toolbar-title class="pt-2">MARS</v-toolbar-title>
        </v-col>
        <v-col cols="4" class="d-flex flex-row-reverse">
          <div class="pl-6 pt-2 text-right caption font-weight-thin">
            v{{ getVersion() }}
          </div>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain color="white" v-bind="attrs" v-on="on">
                  <span class="pr-2">{{$i18n.locale}}</span><v-icon>mdi-earth</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link v-for="(item, index) in locales" :key="index" @click="selectLocale(item.code)">
                  <v-list-item-title>{{ item.flag}} {{ item.friendly_name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          
        </v-col>
      </v-row>
    </v-app-bar>
    <v-container fluid pa-0>
      <v-container
        fill-height
        class="d-flex align-stretch pa-0 pt-4 flex-nowrap"
        style="width: 100%"
        fluid>
        <v-card class="mr-5 mx-auto" elevation="7" style="z-index: 5" tile>
          <v-navigation-drawer
            app
            v-model="drawer"
            permanent
            :mini-variant="menuHide">
            <v-list-item
              @click.native="disableShow(false)"
              class="green pt-1 pb-1 row-pointer"
              dark>
              <v-list-item-icon>
                <v-icon v-if="menuHide">arrow_forward</v-icon>
                <v-icon v-else>arrow_back</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{ $t("menu.menu") }}</v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav class="pb-4 pa-0">
              <v-list-item
                to="/Main/Welcome"
                class="pl-4 pt-2 pb-2"
                @click.native="disableShow(true)">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ userName }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("menu.user")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="pb-4"></v-divider>
              <v-list-item
                class="pl-4"
                v-for="item in filterMenuItemsByRoles()"
                :key="item.title"
                :to="item.route"
                :disabled="item.disabled"
                @click.native="disableShow(true)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="pl-8"></v-divider>

            <template v-slot:append>
              <v-list-item
                :key="$t('menu.logout')"
                link
                dense
                class="mb-auto pb-0"
                v-on:click.native="doLogout"
                
              >
              <!-- to="/Main/Welcome" -->
                <v-list-item-icon>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-navigation-drawer>
        </v-card>
        <MainView/>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import provider from "@/api/provider.js";
import MainView from "@/components/main/MainView";
import { mapGetters, mapActions } from "vuex";
import { version } from "./../../package.json";

export default {
  name: "Main",
  components: { MainView },
  computed: mapGetters(["getUsername", "getError"]),
  data() {
    return {
      locales: [
        {
          flag: '🇨🇿',
          friendly_name: 'Čeština',
          code: 'cs',
        },
        {
          flag: '🇺🇸',
          friendly_name: 'English',
          code: 'en',
        },
      ],
      userName: window.localStorage.user,
      drawer: true,
      menuHide: this.$vuetify.breakpoint.mdAndDown,
      showDisable: false,
      items: [
        {
          title: "menu.dashboard",
          icon: "dashboard",
          route: "/",
          roles: ["service", "operator", "maintenance", "finance"],
        },
        {
          title: "menu.cards",
          icon: "payment",
          route: "/Main/Cards/null",
          roles: ["service", "operator"],
        }, //
        {
          title: "menu.finance",
          icon: "euro",
          route: "/Main/FinancialReports",
          roles: ["finance"],
        }, //mdi-square-inc-cash mdi-cash-multiple mdi-currency-eur mdi-cash-multiple
        {
          title: "menu.installations",
          icon: "mdi-apps",
          route: "/Main/Installations/null/Device/null",
          roles: ["service"],
        },
        {
          title: "menu.devices",
          icon: "developer_board",
          route: "/Main/Devices/null",
          roles: ["maintenance"],
        },
        {
          title: "menu.notifications",
          icon: "notifications",
          disabled: false,
          route: "/Main/Notifications",
          roles: ["notifications"],
        }, //mdi-sitemap mediation account_tree
      ],
      right: null,
    };
  },

  methods: {
    ...mapActions(["selectCard"]),

    selectLocale(item) {
      this.$i18n.locale = item;
    },

    disableShow(to) {
      this.showDisable = to;
      if (!to) this.menuHide = !this.menuHide;
    },

    doLogout() {
      provider.logout();
      location.href = localStorage.getItem("keycloak-logout-url");
    },

    getVersion() {
      return version;
    },

    filterMenuItemsByRoles() {
      let filtered = [];
      let userRoles = JSON.parse(localStorage["keycloak.roles"]);
      for (const item of this.items) {
        let skip = false;
        for (const role of item.roles) {
          for (const userRole of userRoles) {
            if (userRole === role) {
              filtered.push(item);
              skip = true;
              break;
            }
          }
          if (skip) {
            break;
          }
        }
      }
      window.console.log("************");
      window.console.log(filtered);
      return filtered;
    },
  },

  handleHide() {},

  watch: {
    $route(to) {
      this.selectCard({});
    },
    menuHide(to) {
      if (this.showDisable) {
        this.menuHide = true;
      }
    },
  },
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
