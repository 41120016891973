<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on">
        {{ $t("device.hub_new_button") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="8" class="pa-0">
            <span class="headline"> {{ $t("device.device_new") }} </span>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right max-width="400" close-delay="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="green lighten-1"
                    class="float-right pr-3">
                  <v-icon right medium>mdi-information-variant</v-icon>
                </v-btn>
              </template>
              <div
                  class="pa-0"
                  v-html="$t('device.info_device_new')"/>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12">
              <v-select
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.type"
                  :label="$t('device.device_type')"
                  :items="[
                                {
                                  value: 'turnstile',
                                  text: $t('device.device_type_turnstile'),
                                },
                                {
                                  value: 'cash_register',
                                  text: $t('device.device_type_cash_register'),
                                },
                                {
                                  value: 'cash_register_with_turnstile',
                                  text: $t(
                                    'device.device_type_cash_register_with_turnstile'
                                  ),
                                },
                                {
                                  value: 'device_hub',
                                  text: $t(
                                    'device.device_type_device_hub'
                                  ),
                                },
                                {
                                  value: 'paybox',
                                  text: $t(
                                    'device.device_type_paybox'
                                  ),
                                },
                                {
                                  value: 'kiosk',
                                  text: $t(
                                    'device.device_type_kiosk'
                                  ),
                                },
                                {
                                  value: 'gp4bc',
                                  text: $t(
                                    'device.device_type_gp4bc'
                                  ),
                                },
                                /*{
                                  value: 'barrier',
                                  text: $t('device.device_type_barrier'),
                                },*/
                              ]"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="editedItem.name"
                  :label="$t('device.device_name')">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="['turnstile', 'cash_register', 'cash_register_with_turnstile', 'gp4bc'].includes(editedItem.type)">
            <v-col cols="12">
              <v-text-field
                  type="number"
                  v-model="editedItem.hap"
                  :label="$t('device.device_hap')">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          {{ $t("general.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Provider from "@/api/provider";
import Config from "@/config/config";

export default {
  name: "DeviceCreate",
  props: ["installation"],
  data() {
    return {
      dialog: null,
      defaultItem: {
        _id: "",
        device_hub_id: "",
        ip: "",
        port: "",
        name: "",
        hap: undefined,
        bollards: undefined,
        type: "turnstile",
        is_connected: false,
        last_communication: "",
      },
      editedItem: {
        _id: "",
        device_hub_id: "",
        ip: "",
        port: "",
        name: "",
        hap: undefined,
        bollards: undefined,
        type: "turnstile",
        is_connected: false,
        last_communication: "",
      },
    }
  },
  methods: {
    getGroups() {
      let groups = JSON.parse(localStorage["keycloak.groups"])
      this.editedItem.group = groups[0]
      return groups;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      });
    },

    save() {
      if(this.editedItem.type === "gp4bc")
        this.editedItem.bollards = Array(12).fill(0)

      this.editedItem.installation_id = this.installation._id;
      Provider.postData(Config.endpointDevice, this.editedItem, {})
          .then((response) => {
            this.installation.devices.push(response)
            this.editedItem = Object.assign({}, this.defaultItem)
            this.close()
          })
          .catch((error) => {
            //chyba
            window.console.log(error.message)
            window.console.log(error.response)
          });
    },
  }
}
</script>

<style scoped>

</style>