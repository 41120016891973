<template>
  <v-col cols="12" class="pa-0 pl-4 pr-4">
    <v-row no-gutters>
      <v-col cols="2" v-for="(bollard, i) in bollards">
        <v-text-field :label="(i + 1).toString()" class="pa-0 pr-2 pt-0 ma-0" v-model="bollards[i]"></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "ConfigGP4BC",
  props: ["device"],
  data: function()  {
    return {
      bollards: this.device.bollards
    }
  },
  watch: {
    bollards(bollards, old) {
      this.$emit("change", bollards)
    }
  }
}
</script>

<style scoped>

</style>