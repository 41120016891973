<template>
  <v-row>
    <v-col cols="6" class="pa-4 pl-6"
      >{{ $t("reports.report_item_" + label) }}
    </v-col>
    <v-col v-if="label === 'withdrawal'" cols="6" class="pa-4 pl-6"
      >{{ $t("reports.report_item_" + value) }}
    </v-col>
    <v-col v-else-if="label === 'msg_event'" cols="6" class="pa-4 pl-6"
      >{{ $t("reports.report_item_event_" + value) }}
    </v-col>
    <v-col v-else cols="6" class="pa-4 pl-6">{{ value }} </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: "",
    value: "",
  },
  data() {
    return {};
  },
};
</script>