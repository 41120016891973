<template>
  <div>
    <EditableTextParameter v-for="param in params" :device_id="device._id" :key="param.name" class="no-gutters" :param="param" :device_type="device.type"/>
  </div>
</template>

<script>
import EditableTextParameter from "@/components/main/devices/control/parameters/EditableTextParameter";
export default {
  name: "GenericDevice",
  components: {EditableTextParameter},
  props: ["device"],
  computed: {
    params()  {
      return this.device.parameters.filter(param =>
          this.$t("device.parameters." + this.device.type + "." + param.name) !== "device.parameters." + this.device.type + "." + param.name
      )
    }
  }
}
</script>

<style scoped>

</style>