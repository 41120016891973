<template>
  <v-select :label="$t('device.device_state_title')"
            v-model="selected"
            multiple
            dense
            :items="['online', 'issues', 'offline']"
            @input="select()">
    <template v-slot:selection="{ item, index }">
      <span class="grey--text text-caption" v-if="index === 0">
        ({{ selected.length }})
      </span>
    </template>

    <template v-slot:item="item">
      <DeviceStatusIcon :device="{ is_connected: true, status: false}" v-if="item.item === 'issues'"></DeviceStatusIcon>
      <DeviceStatusIcon :device="{ is_connected: true, status: true}" v-if="item.item === 'online'"></DeviceStatusIcon>
      <DeviceStatusIcon :device="{ is_connected: false, status: true}" v-if="item.item === 'offline'"></DeviceStatusIcon>
    </template>
  </v-select>
</template>

<script>
import DeviceStatusIcon from "@/components/main/devices/DeviceStatusIcon";

export default {
  name: "StateFilter",
  components: {DeviceStatusIcon},
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    select: async function () {
      this.$emit("input", this.selected)
      await this.$nextTick();
    }
  }
}
</script>

<style scoped>

</style>