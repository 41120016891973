<template>
  <router-view name="mainView"></router-view>
</template>

<script>
export default {
  name: "MainView",

  data: () => ({}),
};
</script>
