<template>
  <div>
    <EditableTextParameter v-for="param in editableTextParams" :device_id="device._id" :key="param.name" class="no-gutters" :param="param" device_type="paybox"/>
    <EnumParameter
        :state="device.parameters.find(x => x.name === 'card_validate_mode')"
        :name="'card_validate_mode'"
        :device_id="device._id"
        :device_type="device.type"
        :items="cardValidationItems">
    </EnumParameter>
    <EnumParameter
        :state="device.parameters.find(x => x.name === 'state')"
        :name="'state'"
        :device_id="device._id"
        :device_type="device.type"
        :items="stateItems">
    </EnumParameter>

    <v-row>
      <v-col cols="4" class="text-subtitle-1">
        {{ $t("device.parameters.paybox.coins_supported") }}
      </v-col>
      <v-col cols="6">
        <div v-for="coin in coins_supported.value">
          {{ coin.value }} {{ coin.currency }} ({{ coin.id}})
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditableTextParameter from "@/components/main/devices/control/parameters/EditableTextParameter";
import EnumParameter from "@/components/main/devices/control/parameters/EnumParameter";
export default {
  name: "GP4AM_parameters",
  props: ["device"],
  components: {EditableTextParameter, EnumParameter},
  data(){
    return {
      editableTextParameterNames: ["payment", "time", "cash_box_near", "cash_box_near_limit", "cash_box_full",
        "exchange", "coins_enable", "service_cards", "card_validate_ask_proto", "card_validate_ask_url",
        "card_validate_ask_api_key"],

      stateItems: [
        {
          text: this.$t("device.parameters.paybox.state_n"),
          value: "N"
        },{
          text: this.$t("device.parameters.paybox.state_o"),
          value: "O"
        },{
          text: this.$t("device.parameters.paybox.state_c"),
          value: "C"
        }
      ],

      cardValidationItems: [
        {
          text: this.$t("device.parameters.paybox.validation_sync"),
          value: "sync"
        },{
          text: this.$t("device.parameters.paybox.validation_ask"),
          value: "ask"
        }
      ],
    }
  },
  computed: {
    editableTextParams() {
      if(this.device.parameters == null || this.device.parameters.size < 1)
        return []
      return this.device.parameters.filter(param => this.editableTextParameterNames.includes(param.name))
    },
    coins_supported() {
      if(this.device.parameters == null)
        return []
      return this.device.parameters.find(param => param.name === "coins_supported")
    }
  }
}
</script>

<style scoped>

</style>