export default {
    login: {
        title: "Přihlášení MARS",
        username: "Uživatelské jméno",
        password: "Heslo",
        required: "Povinné pole",
        enter_password: "Zadejte heslo",
        enter_password_hint: "Heslo musí obsahovat alespoň 8 znaků",
        enter_username: "Zadejte uživatelské jméno",
        login: "Přihlásit se",
        wrong_credentials: "Jméno nebo heslo není správné",
    },
    menu: {
        menu: "Menu",
        dashboard: "Dashboard",
        user: "Uživatel",
        cards: "Správa karet",
        finance: "Finanční uzávěrky",
        installations: "Instalace",
        notifications: "Oznámení",
        devices: "Zařízení",
        logout: "Odhlásit se",
    },
    cards: {
        cards: "Správa karet",
        detail: "Podrobnosti karty",
        add_new: "Vytvořit novou kartu",
        number: "Číslo karty",
        username: "Jméno uživatele",
        access: "Přístupy",
        created: "Vytvořeno",
        updated: "Upraveno",
        day_max: "Limit průchodů",
        limit_interval: "Časový interval",
        limit_day: "Den",
        limit_month: "Měsíc",
        limit_undefined: "-",
        limit_: "-",
        error_limit_interval: "Pole `Limit použití` a `Časový interval` musí být obě vyplněná nebo prázdná.",
        filter_installation: "Zvolte instalace",
        pass_count: "Počet použití",
        pass_read: "Načíst",
        pass_time_from: "Datum od",
        pass_time_to: "Datum do",
        info_cards: "<h2 class='pa-2 pb-3'>Správa karet</h2>" +
            "<p>Tato část obrazovky zobrazuje karty, zvolených nebo všech instalací, pokud není žádná instalace v poli <b><i>Zvolte instalace</i></b> vybrána.</p>" +
            "<p>Dále je možné vytvořit novou kartu stiskem tlačítka <b><i>Vytvořit novou kartu</i></b> a vyplněním formuláře.</p>" +
            "<p>Výbeřem řádku z tabulky se vpravo zobrazí detaily vybrané karty.</p>" +
            "<p>Pokud chcete smazat kartu stiskněte v tabulce karet ikonu koše <i class='mdi mdi-delete'></i>.</p>" +
            "<p>V tabulce lze vyhledávat záznamy pomocí vložení hledaného textu do pole <b><i>Hledat</i></b> <i class='mdi mdi-magnify'></i>, při vkládání textu jsou ihned záznamy v tabulce filtrovány.</p>" +
            "<p>Ve spodní části tabulky je možné volit počet záznamů na stránku tabulky a listovat mezi stránkami záznamů pomocí šipek <b><i><i class='mdi mdi-chevron-left'></i> <i class='mdi mdi-chevron-right'></i></i></b>.</p>",

        info_card_new: "<h2 class='pa-2 pb-3'>Vytvoření karty</h2>" +
            "<p>Zde prosím zadejte <i>číslo karty</i> a <i>jméno uživatele</i>. Je možné vyplnit <i>limit použití</i> a <i>časový interval</i>, na který se limit vztahuje. Prosím zadejte také <i>pravidla přístupů</i> na instalace nebo zařízení. Následně potvrďte tlačítkem <b><i>uložit</i></b>.</p>",

        error_card_number: "Pole `Číslo karty` musí být vyplněno číselnou hodnotou.",
        read_pass_count_btn: "Zobrazit počet použití za období",
        pass_cancel: "Načíst vše",
        pass_cancel_tooltip: "Načtení všech průjezdů zobrazených karet za celé období od 1.1.2021 do aktuálního času.",
        pass_read_tooltip: "Načtení průjezdů zobrazených karet za definované období v Datum od a Datum do.",
        info_card_detail: "<h2 class='pa-2 pb-3'>Podrobnosti karty</h2>" +
            "<p>Pro změnu údajů karty opravte požadované položky a nové hodnoty uložte stisknutím tlačítka <b><i>Uložit</i></b> <i class='mdi mdi-content-save'></i>.</p>" +
            "<p>Pravidla přístupů určují, na které instalaci a na kterém zařízení je karta platná. Počet použití karty lze omezit v poli <i>Limit použití</i> na období definované v poli <i>Časový interval</i>.</p>" +
            "<p>Pravidla přístupů pro karty je možné přidat tak, že přístup vybereme z polí <i>instalace</i> nebo <i>zařízení</i> a přidáme ho k pravidlům přístupů stisknutím tlačítka <b><i>Přidat přístup</i></b>.</p>" +
            "<p>Přístup můžeme odebrat stisknutím ikony koše <i class='mdi mdi-delete'></i> na řádku s pravidlem přístupu.</p>" +
            "<p>V dolní části karty je možné načíst počet použití karty za určité období. Období vyberte v položkách <i>Datum od</i> a <i>Datum do</i>, počet použití je pak načten po stisku tlačítka <b><i>Načíst</i></b>. </p>",

    },
    accesses: {
        accesses: "Pravidla přístupů",
        hub: "Instalace",
        device: "Zařízení",
        allow: "Povolit přístup",
        add: "Přidat přístup",
        access_type: "Typ přístupu",
        access_to: "Cíl přístupu",
    },
    device: {
        hubs: "Seznam instalací",
        hub_detail: "Podrobnosti instalace",
        hub_name: "Název instalace",
        hub_new: "Vytvořit novou instalaci",
        hub_api_key: "API klíč",
        hub_id: "ID",
        hub_new_button: "Vytvořit",
        devices: "Zařízení",
        device_detail: "Podrobnosti zařízení",
        device_new: "Vytvořit nové zařízení",
        device_hap: "HAP adresa",
        device_name: "Název zařízení",
        device_type: "Typ zařízení",
        device_sw_version: "Verze",
        device_last_contact: "Poslední komunikace",
        device_restart: "Restartovat",
        device_factory_reset: "Obnovení továrního nastavení",
        device_factory_reset_check_header: "Opravdu chcete obnovit tovární nastavení?",
        device_factory_reset_check_text: "Tato akce smaže ze serveru i zařízení všechna uživatelská nastavení i historii všech průchodů, plateb a finančních uzávěrek",
        device_factory_reset_success: "Obnovení továrního nastavení bylo úspěšné",
        device_factory_reset_error: "Obnovení továrního nastavení selhalo",
        device_factory_reset_button: "Smazat data",
        device_restart_check: "Opravdu chcete zařízení restartovat?",
        device_rename: "Přejmenovat",
        device_rename_success: "Zařízení bylo úspěšně přejmenováno",
        device_rename_error: "Přejmenování selhalo",
        device_state_title: "Stav zařízení",
        //cashbox status
        device_out_of_order: "Mimo provoz",
        device_paper_near: "Dochází papír",
        device_door_open: "Otevřené dveře",
        device_tubes_low: "Dochazí mince v tubách mincovníku",
        device_tubes_empty: "Prázdné tuby mincovníku",
        device_cashbox_full: "Plná pokladna",
        device_banknotes_dispenser_error: "Chyba vydavače bankovek",
        device_coins_dispenser_error: "Chyba vydavače mincí",
        device_coin_cashbox_open: "Pokladna mincí otevřena",
        device_banknote_cashbox_open: "Pokladna bankovek otevřena",
        //turnstile status
        device_paper_low: "Dochází papír",
        device_printer_error: "Chyba tiskárny",
        device_barrier_error: "Chyba závory",
        device_connected: "Online",
        device_disconnected: "Offline",
        device_none: "Stav neznámý",

        //servis
        device_info_texts: "Informace pro obsluhu",

        device_mode_working: "V provozu",
        device_mode_service: "Servis",
        device_mode_fire_alarm: "Požární poplach",
        device_reserved: "_reserved",
        device_internal_error: "Interní chyba",

        device_banknotes_reader_error: "Chyba čtečky bankovech",
        device_banknote_dispenser_low: "Dochází bankovky ve vyplaceči",
        device_banknote_dispenser_empty: "Prázdný vyplaceč bankovek",
        device_parking_state: "_parking_state",
        device_barrier_open: "Závora otevřena",
        device_barrier_lose: "_barrier_lose",
        device_detector_presence: "Přítomnost na detektoru",
        device_detector_close_1: "_detector_close_1",
        device_detector_close_2: "_detector_close_2",
        device_card_sensor: "_card_sensor",
        device_blocking_card_issue: "_blocking_card_issue",
        device_freeze_time: "_freeze_time",
        device_dummy_mode: "_dummy_mode",
        device_permanent_open: "Trvalé otevření",
        device_cashbox_near: "Téměř plná pokladna",

        info_hub_list: "<h2 class='pa-2 pb-3'>Seznam instalací</h2>" +
            "<p>Seznam obsahuje instalace provozovatele, včetně indikátoru aktuálního stavu.</p>" +
            "<h3>Barva indikátoru znamená:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Zelená - instalace je v pořádku.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Červená - instalace není on-line.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Žlutá - instalace má problém.</li>" +
            "<p>V seznamu lze vyhledávat záznamy pomocí vložení hledaného textu do pole <i>Hledat</i>, při vkládání textu jsou ihned záznamy v tabulce filtrovány.</p>" +
            "<p>Ve spodní části tabulky je možné volit počet záznamů na stránku tabulky a listovat mezi stránkami záznamů pomocí tlačítek <b><i><i class='mdi mdi-chevron-left'></i> a <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
        info_hub: "<h2 class='pa-2 pb-3'>Podrobnosti instalace</h2>" +
            "<p>Seznam obsahuje zařízení patřící do instalace, včetně indikátoru aktuálního stavu.</p>" +
            "<h3>Barva indikátoru znamená:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Zelená - zařízení je v pořádku.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Červená - zařízení není on-line.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Žlutá - zařízení má problém.</li>" +
            "<p>Po najetí myší na indikátor stavu se zobrazí okno s jeho výpisem.</p>" +
            "<p>V seznamu lze vyhledávat záznamy pomocí vložení hledaného textu do pole Hledat, při vkládání textu jsou ihned záznamy v tabulce filtrovány.</p>" +
            "<p>Ve spodní části tabulky je možné volit počet záznamů na stránku tabulky a listovat mezi stránkami záznamů pomocí tlačítek <b><i><i class='mdi mdi-chevron-left'></i> a <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
        info_device: "<h2 class='pa-2 pb-3'>Podrobnosti zařízení</h2>" +
            "<p>Tato karta obsahuje detail pro jednotlivá zařízení.</p>" +
            "<p>Pro změnu názvu zařízení opravte název zařízení a stiskněte tlačítko <b><i>Přejmenovat</i></b>.</p>" +
            "<p>Ve spodní části je zobrazen stav s jeho popisky.</p>" +
            "<h3>Barva indikátoru stavu znamená:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Zelená - zařízení je v pořádku.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Červená - zařízení není on-line.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Žlutá - zařízení má problém.</li>",
        info_hub_new: "<h2 class='pa-2 pb-3'>Vytvoření nové instalace</h2>" +
            "<p>Zde prosím zadejte <i>název instalace</i>. Následně potvrďte tlačítkem <b><i>uložit</i></b>.</p>",
        info_device_new: "<h2 class='pa-2 pb-3'>Vytvoření nového zařízení</h2>" +
            "<p>Zde prosím zadejte <i>HAP adresu, název a typ</i> zařízení. <i>Typ zařízení</i> zvolte z rozbalovací nabídky. Následně potvrďte tlačítkem <b><i>uložit</i></b>.</p>",
        device_type_turnstile: "Turniket (GPE4T)",
        device_type_cash_register: "Pokladna (GPE4M)",
        device_type_cash_register_with_turnstile: "Pokladna s turniketem (GP4AT)",
        device_type_barrier: "Závora (GPBarrier)",
        device_type_paybox: "Paybox (GP4AM)",
        device_type_kiosk: "Kiosek",
        device_type_gp4bc: "Výsuvné sloupky (GP4BC)",

        device_tab_summary: "Přehled",
        device_tab_settings: "Nastavení",
        device_tab_logs: "Log",

        parameters: {
            paybox: {
                payment: "Cena (v primární měně)",
                time: "Čas",
                cash_box_near: "Varování zaplnění pokladny",
                cash_box_near_limit: "Maximální zaplnění pokladny",
                cash_box_full: "Ochranný limit zaplnění pokladny",
                exchange: "Kurz sekundární měny",
                coins_enable: "Povolené mince",
                service_cards: "Servisní karty",
                coins_supported: "Podporované mince",
                state: "Provozní mód",
                state_n: "Běžný provoz",
                state_o: "Mimo provoz, trvale otevřeno",
                state_c: "Mimo provoz, trvale zavřeno",
                card_validate_mode: "Mód validace karet",
                validation_ask: "Dotaz při načtení karty",
                validation_sync: "Synchronizace karet",
                card_validate_ask_proto: "Dotaz karty - protokol",
                card_validate_ask_url: "Doraz karty - url",
                card_validate_ask_api_key: "Dotaz karty - apikey"
            },
            gp4bc: {
                setOperationState: "Stav",
                state_normal: "Běžný provoz",
                state_permanent_open: "Trvale otevřeno",
                number_of_bollards: "Počet sloupků",
                time_before_arrival: "Timeout pro příjezd",
                time_after_departure: "Timeout pro odjezd",
            }
        },

        states: {
            paybox: {
                total_cash: "Celkový výdělek (hotovost)",
                total_cashless: "Celkový výdělek (bezhotovostní)",
                cashbox_coins: "Mince v kase",
                cashbox_coins_total: "Celkový počet mincí",
                cashbox_last_empty: "Poslední výběr pokladny",
                cashbox_state: "Stav pokladny",
                cashbox_state_total_cash: "Celkem (hotovost)",
                cashbox_state_total_cashless: "Celkem (bezhotovostní)",
                first_payment: "Datum první platby"
            },

            gpe4m_gp4at: {
                cashbox_state: "Souhrn hotovosti",
                nominal_type: "Typ",
                nominal_value: "Hodnota",
                nominal_currency: "Měna",
                nominal_tube_stat: "Tuby (ks)",
                nominal_cashbox_stat: "Pokladna (ks)",
                nominal_type_coin: "Mince",
                nominal_type_banknote: "Bankovka",
            },

            gp4bc: {
                operation_mode: "Provozní mód",
                out_of_order: "Mimo provoz (sloupky otevřeny)",
                mode_normal: "Běžný provoz",
                bollard_number: "Číslo sloupku",
                bollard_space_number: "Číslo parkovacího místa",
                bollard_state: "Stav sloupku",
                bollard_occupancy: "Obsazenost parkovacího místa",
                bollard_open : "Otevřeno",
                bollard_closed : "Uzavřeno",
                bollard_moving : "Sloupek v pohybu",
                bollard_occupied: "Obsazeno",
                bollard_free: "Volné",
                bollard_not_connected: "Sloupek nepřipojen",
            },

            kiosk: {
                dispenser_records: "Zásoba nominálů pro recyklaci (vrácení)",
                cashbox_records: "Obsah pokladny",
                record_index: "Index nominálu",
                record_value: "Hodnota nominálu",
                record_count: "Počet",
                record_suma: "Celková hodnota",
                suma: "Celkový součet",

                MH_COIN_HOPPER: "Hopper",
                MH_COIN_DISPENSER: "Mincovník",
                MH_COIN_RECYCLER: "Recyklovačka mincí",
                MH_BANKNOTE_READER_MEI: "Bankočtečka MEI",
                MH_BANKNOTE_READER_ICT: "Bankočtečka ICT",
                MH_BANKNOTE_RECYCLER_MEI: "Bankorecyklovačka MEI",
                MH_BANKNOTE_RECYCLER_JCM: "Bankorecyklovačka JCM",
                MH_BANKNOTE_DISPENSER: "Vracečka bankovek",
            }
        },

        param_saved: "Parametr uložen",
        device_type_device_hub: "Device hub",
        parent: "Rodičovské zařízení",
    },
    general: {
        search: "Hledat",
        cancel: "Zrušit",
        ok: "OK",
        save: "Uložit",
        delete_sanity_check: "Opravdu chcete tuto položku odstranit?",
        welcome_text: "Vítejte ve službě MARS. Pokračujte zvolením položky v menu.",
        table_rows_per_page_text: "Řádků na stránku",
        table_rows_per_page_all_text: "Všechny",
        pagination_page_of: " z ",
        groups: "Skupiny",
        group: "Skupina",
        copied_to_clipboard: "Zkopírováno",
    },
    reports: {
        reports: "Finanční reporty",
        reports_all_label: "Všechny reporty",
        reports_device_interval_label: "Načíst reporty ze zařízení",
        reports_export_label: "Exportovat reporty ze zařízení",
        reports_all_btn: "Načíst všechny reporty",
        reports_device_interval_btn: "Načíst reporty ze zařízení od do",
        reports_export_btn: "Exportovat reporty ze zařízení od do",
        reports_lang:"cs",
        th_device_id: "ID zařízení",
        th_device_type: "Typ zařízení",
        th_hap: "HAP adresa",
        th_card_number: "Číslo karty",
        th_withdrawal_number: "Číslo výběru",
        th_withdrawal_year: "Výběr rok",
        th_station_id: "ID stanice",
        th_msg_event: "Událost",
        th_timestamp: "Datum",
        report_item_device_id: "id zařízení",
        report_item_evt: "událost",
        report_item_card_number: "číslo karty",
        report_item_withdrawal_year: "rok výběru",
        report_item_withdrawal: "typ výběru",
        report_item_msg_event_transformed: "typ reportu",
        report_item_timestamp_transformed: "datum",
        report_item_device_type: "typ zařízení",
        report_item_hap: "hap adresa",
        report_item_withdrawal_number: "číslo výběru",
        report_item_station_id: "id stanice",
        report_item_log_level: "log",
        report_item_number_of_payments_by_card: "počet plateb kartou",
        report_item_hopper_difference: "rozdíl hopperu",
        report_item_payment_card_total: "kartou celkem",
        report_item_recycler_difference: "rozdíl recycleru",
        report_item_refil: "doplnění",
        report_item_empty: "výběr",
        time_from: "Čas od",
        time_to: "Čas do",
        date_from: "Datum od",
        date_to: "Datum do",
        report_item_event_withdrawal_2: "Výběr / doplnění",
        report_item_event_withdrawal_GPE: "Výběr pokladny",
        report_item_device_id_transformed: "instalace / zařízení",
        th_device_id_transformed: "Instalace / Zařízení",
        info_reports: "<h2 class='pa-2 pb-3'>Finanční reporty</h2>" +
            "<p>Pro načtení všech reportů stiskněte tlačítko <b><i>Načíst všechny reporty</i></b>.</p>" +
            "<p>Pro načtení reportů z vybraného zařízení nejprve vyberte požadované zařízení ze seznamu a poté stiskněte tlačítko <b><i>Načíst reporty ze zařízení</i></b>.</p>" +
            "<p>Pro načtení reportů za určité období nejprve vyberte datum a čas od a do a poté stiskněte tlačítko <b><i>Načíst reporty za období</i></b>.</p>" +
            "<p>Seznam požadovaných reportů zobrazuje jednotlivé reporty dle požadovaného výběru. Po kliknutí na řádek tabulky je zobrazen detail reportu s podrobnějšími informacemi a výčetkou, která obsahuje informace o stavu hotovosti.</p>" +
            "<h3>Výčetka obsahuje kromě jiných položky:</h3>" +
            "<li>Recyklátory – stav mincí a bankovek, které zůstávají v zařízení za účelem vracení </li>" +
            "<li>Pokladny - stav mincí a bankovek, které byly při v pokladnách při jejich výběru </li>" +
            "<li>Doplnění – počet mincí a bankovek, které byly doplněny do recyklátorů od posledního výběru pokladen</li>" +
            "<li>Výběr – počet mincí a bankovek, které byly vybrány z  recyklátorů od posledního výběru pokladen </li>" +
            "<p>V seznamu lze vyhledávat záznamy pomocí vložení hledaného textu do pole <i>Hledat</i>, při vkládání textu jsou ihned záznamy v tabulce filtrovány.</p>" +
            "<p>Ve spodní části tabulky je možné volit počet záznamů na stránku tabulky a listovat mezi stránkami záznamů pomocí tlačítek <b><i><i class='mdi mdi-chevron-left'></i> a <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
    },
    report_detail: {
        detail: "Detail reportu",
        th_type: "Typ",
        th_nominal: "Nominál",
        th_currency: "Měna",
        th_tube_stat: "Tuby [ks]",
        th_cashbox_stat: "Pokladna [ks]",
        th_refill_stat: "Doplnění [ks]",
        th_empty_stat: "Výběr [ks]",
        coin: "mince",
        banknote: "bankovka",
        undefined: "",
    },
    errors: {
        error_title: "Chyba",
        errorUnauthorized_message: "Neautorizovaný přístup, prosím přihlaste se.",
        error_date_from_after_to: "Datum od je větší než datum do, prosím upravte hodnoty, tak aby datum od byl dříve než datum do.",
        error_financial_report_device: "Prosím vyberte zařízení pro, které chcete zobrazit finanční reporty.",
        error_financial_report_export: "Tento prohlížeč nepodporuje stahování souborů.",
        cards_empty_record: "Nelze přidat prázdné pravidlo.",
        cards_record_exists: "Toto pravidlo již existuje.",
        error_group: "Prosím vyberte skupinu.",
    },

    snackbar: {
        close: "Zavřít"
    },

    dashboard: {
        device_summary_title: "Moje zařízení",
        device_summary_count: "celkem",
        device_summary_online: "Připojená zařízení",
        device_summary_offline: "Nedostupná zařízení",
        device_summary_warning: "Zařízení vyžadující pozornost",
    },

    notifications: {
        filterType: "Typ",
        filterValues: "Hodnota",
        channel: "Kanál",
        recipient: "Příjemce",
        createNewBtn: "Založit nové oznámení",
        newTitle: "Nové oznámení",
        editTitle: "Oznámení",
        deleteBtn: "Smazat",
        chooseValues: "Vyberte hodnoty ze seznamu"
    }
}