<template>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="col-md-5 col-lg-4">
          <AllDevicesSummary></AllDevicesSummary>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import AllDevicesSummary from "@/components/main/dashboard/AllDevicesSummary";
export default {
  name: "",
  components: {AllDevicesSummary},
  data() {
    return {};
  },
  methods: {
    do() {
      window.console.log("do");
    },
  },
};
</script>
