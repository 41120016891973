<template>
  <v-row no-gutters>
    <v-col cols="4" class="text-subtitle-1">
      {{ $t("device.parameters." + this.device_type + "." + this.name) }}
    </v-col>
    <v-col cols="6">
      <v-select
          style="padding-top: 0"
          v-model="selected"
          :items="items"
          item-text="text"
          item-value="value">
      </v-select>
    </v-col>
    <v-col cols="2">
      <v-btn icon @click="storeParameter(name, selected)">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-col>
    <Snackbar :value="snackbar" :text="snackbar_text" :type="snackbar_type" @closed="snackbar = false" />
  </v-row>
</template>

<script>
import Snackbar from "@/components/main/Snackbar";
import Provider from "@/api/provider";
import util from "util";
import Config from "@/config/config";
export default {
  name: "GP4BC_operationState",
  components: {Snackbar},
  props: ["state", "name", "device_id", "device_type", "items"],

  data() {
    return {
      selected: this.state,
      snackbar: false,
      snackbar_type: "",
      snackbar_text: ""
    }
  },

  methods: {
    storeParameter(name, value) {
      Provider.putData(util.format(
          Config.endpointDeviceOperationState,
          this.device_id,
          name,
          encodeURIComponent(value)
      ), {}, {})
          .then((response) => {
            this.snackbar_text = this.$t("device.param_saved")
            this.snackbar_type = "success"
            this.snackbar = true
          })
          .catch((error) => {
            this.snackbar_text = error
            this.snackbar_type = "error"
            this.snackbar = true
            console.log(error)
          });
    }
  }
}
</script>

<style scoped>

</style>