<template>

  <v-select :label="$t('general.groups')"
            v-model="selected"
            multiple
            dense
            :items="getGroups"
            @input="select()">
    <template v-slot:selection="{ item, index }">
      <div v-if="selected.length === 1">
        {{ item }}
      </div>
      <span v-else-if="index === 1" class="grey--text text-caption">
        ({{ selected.length }})
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "GroupFilter",
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    getGroups() {
      return JSON.parse(localStorage["keycloak.groups"])
    },
  },
  methods: {
    select: async function () {
      this.$emit("input", this.selected)
      await this.$nextTick();
    }
  }
}
</script>

<style scoped>

</style>
