<template>
  <v-tooltip left max-width="400" close-delay="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon>
        <v-icon
            v-bind="attrs"
            v-on="on"
            small
            class="pa-0 pt-3 pb-3"
            :color="getColorConnected(device)">
          {{ getCircleIcon(device) }}
        </v-icon>
      </v-btn>
    </template>
    <div class="pa-0">
      <DeviceStatusChips :device="device"/>
    </div>
  </v-tooltip>
</template>

<script>

import DeviceStatusChips from "@/components/main/devices/DeviceStatusChips";
export default {
  name: "DeviceStatusIcon",
  components: {DeviceStatusChips},
  props: ["device"],
  methods: {
    getColorConnected(item) {
      if(item.type === "kiosk")
        item.status = Object.values(item.status_details).filter(detail => detail.status_info === 'error').length < 1

      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.hasOwnProperty("status") && item.status === false) {
            return "orange lighten-3";
          } else {
            return "green";
          }
        } else {
          return "red";
        }
      } else {
        return "grey";
      }
    },

    getCircleIcon(item) {
      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.hasOwnProperty("status") && item.status === false) {
            return "mdi-alert";
          } else {
            return "circle";
          }
        } else {
          return "circle";
        }
      } else {
        return "circle";
      }
    },
  }
}
</script>

<style scoped>

</style>