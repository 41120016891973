<template>
  <div style="border-right: 1px solid #dddddd; padding-right: 1em">
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
          class="pa-0"
        >{{ $t("device.hubs") }} </v-col>
        <v-col class="pa-0">
          <v-tooltip
            right
            max-width="400"
            close-delay="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="green lighten-1"
                class="float-right pr-3"
              >
                <v-icon
                  right
                  medium
                >mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <div
              class="pa-0"
              v-html="$t('device.info_hub_list')"
            ></div>
          </v-tooltip>
        </v-col><!--  -->
      </v-row>
    </v-card-title>

    <v-data-table
      dense
      outlined
      :footer-props="{ itemsPerPageText: '' }"
      :headers="headersLocalized"
      :items="sortedInstallations"
      :search="search"
      single-select
      mobile-breakpoint="0"
      item-key="_id"
      @click:row="onClickItem"
      class="elevation-0 row-pointer"
      :height="windowSize.y - 272"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('general.search')"
            single-line
            hide-details
          >
          </v-text-field>
          <v-spacer></v-spacer>

          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("device.hub_new_button") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col
                    cols="8"
                    class="pa-0"
                  >
                    <span class="headline">{{ $t("device.hub_new") }}</span>
                  </v-col>
                  <v-col class="pa-0">
                    <v-tooltip
                      right
                      max-width="400"
                      close-delay="200"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="green lighten-1"
                          class="float-right pr-3"
                        >
                          <v-icon
                            right
                            medium
                          >mdi-information-variant</v-icon>
                        </v-btn>
                      </template>
                      <div
                        class="pa-0"
                        v-html="$t('device.info_hub_new')"
                      ></div>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.name"
                      :label="$t('device.hub_name')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="4"
                    class="pt-4 pl-4 body-1"
                  >{{$t('general.group')}}</v-col>
                  <v-col
                    cols="8"
                    class="pr-4 pl-0"
                  >
                    <v-select
                      v-model="editedItem.group"
                      :items="getGroups()"
                      v-on:input=""
                      outlined
                      hide-details
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >{{
                  $t("general.save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="headline">
                {{ $t("general.delete_sanity_check") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDelete"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <!--<v-container class="ma-0 pa-0">-->
        <v-row class="ma-0 pa-0">
          <v-col
            cols="10"
            class="pa-0 pt-1 pb-1 pr-0"
            style="margin-top: auto; margin-bottom: auto; text-align: left !important"
          >
            {{ item.name }}
          </v-col>
          <v-col
            cols="2"
            class="pa-0 pl-2 pt-2"
          >
            <v-btn
              v-on:click="deleteItem(item)"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--</v-container>-->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Config from "@/config/config";
import Provider from "@/api/provider";

const util = require("util");
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

export default {
  name: "",

  components: {},

  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      search: "",
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          textKey: "device.hub_name",
          text: this.$t("device.hub_name"),
          align: "center",
          value: "name",
          class: "grey lighten-2",
        },
      ],
      editedItem: {
        _id: "",
        name: "",
        api_key: "",
        is_connected: false,
        last_communication: "",
        devices: [],
        group: "",
      },
      defaultItem: {
        _id: "",
        name: "",
        api_key: "",
        is_connected: false,
        last_communication: "",
        devices: [],
        group: "",
      },
    };
  },

  computed: {
    ...mapGetters(["getAllInstallations"]),

    sortedInstallations() {
      return this.getAllInstallations.sort((a, b) => a.name.localeCompare(b.name))
    },

    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    //this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  },

  methods: {
    getGroups() {
      let groups = JSON.parse(localStorage["keycloak.groups"]);
      return groups;
    },

    initialize() {
      Provider.getData(Config.endpointInstallationList, {})
        .then((response) => {
          this.setAllInstallations(response);
        })
        .catch((error) => {
          //error
        });
    },

    editItem(item) {
      this.editedIndex = this.getAllInstallations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.getAllInstallations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm: async function () {
      await Provider.deleteData(
        util.format(Config.endpointInstallations, this.editedItem._id),
        {}
      )
        .then((response) => {
          const index = this.getAllInstallations.findIndex(
            (t) => t._id === this.editedItem._id
          );
          this.getAllInstallations.splice(index, 1);
          this.closeDelete();
        })
        .catch((error) => {
          //TODO chyba
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.group === undefined || this.editedItem.group === "") {
        this.setError(this.$t("errors.error_group"));
        return;
      }
      Provider.postData(Config.endpointInstallationList, this.editedItem, {})
        .then((response) => {
          this.getAllInstallations.push(response);
          this.close();
        })
        .catch((error) => {
          //chyba TODO
        });
    },

    onClickItem: async function (item, row) {
      if(row.isSelected)
        return
      row.select(true)
      await router.push({name: "Installations", params: {selectedInstallation: item._id, selectedDevice: "null" }})
    },

    getColorConnected(item) {
      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.devices !== undefined) {
            for (const device of item.devices) {
              if (device.hasOwnProperty("status") && device.is_connected === true && device.status === false) {
                return "orange lighten-3";
              }
            }
            return "green";
          } else {
            return "green";
          }
        } else {
          return "red";
        }
      } else {
        return "grey";
      }
    },

    getIconConnected(item) {
      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.devices !== undefined) {
            for (const device of item.devices) {
              if (device.hasOwnProperty("status") && device.is_connected === true && device.status === false) {
                return "mdi-alert";
              }
            }
            return "circle";
          } else {
            return "circle";
          }
        } else {
          return "circle";
        }
      } else {
        return "circle";
      }
    },

    getSelectedClass(item) {
      if (item._id == this.getSelectedInstallation._id) {
        return "brown lighten-4";
      } else {
        return "white";
      }
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

::v-deep tr.v-data-table__selected {
  background: #eeeeee !important;
}
</style>