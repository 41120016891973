<template>
  <div class="pa-2" :style="css">
      <v-row>
          <v-card-title>
            <DeviceTypeIcon :type="selectedDevice.type"/>
            <div class="ml-2">{{ selectedDevice.name }}</div>
          </v-card-title>
      </v-row>
      <v-row>
        <v-tabs v-model="tabs" grow>
          <v-tab key="state">{{ $t("device.device_tab_summary") }}</v-tab>
          <v-tab key="param">{{ $t("device.device_tab_settings") }}</v-tab>
          <v-tab key="logs" disabled>{{ $t("device.device_tab_logs") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" style="width: 100%">
          <v-tab-item grow key="state"><DeviceStates :device="selectedDevice"></DeviceStates></v-tab-item>
          <v-tab-item key="param"><DeviceParameters :device="selectedDevice"></DeviceParameters></v-tab-item>
          <v-tab-item key="logs"><DeviceLogs :device="selectedDevice"></DeviceLogs></v-tab-item>
        </v-tabs-items>
      </v-row>
  </div>
</template>

<script>
import DeviceTypeIcon from "@/components/main/devices/DeviceTypeIcon";
import DeviceStates from "@/components/main/devices/control/states/DeviceStates";
import DeviceParameters from "@/components/main/devices/control/parameters/DeviceParameters";
import DeviceLogs from "@/components/main/devices/control/DeviceLogs";
import {mapGetters} from "vuex";

export default {
  name: "DeviceDetail",
  components: {DeviceLogs, DeviceParameters, DeviceStates, DeviceTypeIcon},
  props: ["selectedDevice"],
  data() {
    return {
      tabs: Object
    }
  },
  computed: {
    css() {
      return {
        'max-height': (this.$windowHeight - 96) + "px",
        'overflow-y': 'scroll'
      }
    }
  }
}
</script>

<style scoped>

</style>