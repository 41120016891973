
const state = {
	access_token: {},
	expires_in: {},
	refresh_token: {},
	token_type: {},
	time_stamp: {},
	username: {},
};

const getters = {
	getAccessToken(state) {
		return state.access_token;
	},
	getExpiresIn(state) {
		return state.expires_in;
	},
	getRefreshToken(state) {
		return state.refresh_token;
	},
	getTokenType(state) {
		return state.token_type;
	},
	getTimeStamp(state) {
		return state.time_stamp;
	},
	getUsername(state) {
		return state.username;
	},
};
const mutations = {
	mutateSetLogin: (state, result) => {
		state.access_token = result.access_token;
		state.expires_in = result.expires_in;
		state.refresh_token = result.refresh_token;
		state.token_type = result.token_type;
		state.time_stamp = Date.now();
	},
	mutateSetUsername: (state, result) => {
		state.username = result;
	},


};
const actions = {
	async setLogin({ commit }, result) {
		commit('mutateSetLogin', result);
	},
	async setUsername({ commit }, result) {
		commit('mutateSetUsername', result);
	},
};

export default {
	state,
	getters,
	mutations,
	actions
}