<template>
  <div
    v-show="Object.keys(card).length !== 0"
    class="pa-3 overflow-y-auto"
    :max-height="windowSize.y - 90">
    <!--v-if="Object.keys(card).length !== 0"-->
    <v-card-title>
      <v-row>
        <v-col
          cols="6"
          class="pa-0"
        >{{ $t("cards.detail") }}
          <v-icon
            class="pl-4"
            medium
          >mdi-credit-card</v-icon>
        </v-col>
        <v-col class="pa-0">
          <v-tooltip
            left
            max-width="400"
            close-delay="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="green lighten-1"
                class="float-right pr-3"
              >
                <v-icon
                  right
                  medium
                >mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <div
              class="pa-0"
              v-html="$t('cards.info_card_detail')"
            ></div>
          </v-tooltip>
        </v-col>
        <!-- cards.info_card_detail -->
      </v-row>
    </v-card-title>
    <v-row>
      <v-col
        cols="4"
        class="pl-6"
      >{{ $t("cards.number") }}</v-col>
      <v-col
        cols="8"
        class="pa-0 pr-2 ma-0"
      >
        <v-text-field
          class="pa-0 pr-2 pt-2 ma-0"
          v-model="card.card_number"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        class="pl-6"
      >{{ $t("cards.username") }}</v-col>
      <v-col
        cols="8"
        class="caption pa-0 pr-2"
      >
        <v-text-field
          class="pa-0 pr-2 pt-2 ma-0"
          v-model="card.username"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        class="pl-6"
      >{{ $t("cards.day_max") }}</v-col>
      <v-col
        cols="3"
        class="caption pa-0 pr-2"
      >
        <v-text-field
          class="pa-0 pr-2 pt-2 ma-0"
          v-model="card.max_use_limit"
          dense
        ></v-text-field>
      </v-col>

      <v-col
        cols="5"
        class="caption pa-2 pr-4 pr-2"
      >
        <v-select
          v-model="card.max_use_interval"
          :hint="`sdfsd`"
          :items="[
            { value: 'day', text: $t('cards.limit_day') },
            { value: 'month', text: $t('cards.limit_month') },
            {
              value: '',
              text: $t('cards.limit_undefined'),
            },
          ]"
          :label="$t('cards.limit_interval')"
          v-on:input="selectLimitInterval"
          return-object
          outlined
          hide-details
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        class="pl-6"
      >{{$t('general.group')}}</v-col>
      <v-col
        cols="8"
        class="pr-4 pl-0"
      >
        <v-select
          v-model="card.group"
          :items="getGroups()"
          v-on:input=""
          outlined
          hide-details
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <AccessDialog :card="card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0 pb-0 pr-3 pl-3"
      >
        <v-btn
          v-on:click="updateCard"
          color="primary"
          block
        >
          {{ $t("general.save") }}
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          class="pa-2 pt-0 elevation-0"
          outlined
        >
          <v-card-title class="pt-0">
            <v-row class="pa-0 pt-2">
              <v-col
                cols="6"
                class="pt-1 pb-1"
              >{{
                $t("cards.pass_count")
              }}</v-col>
              <v-col
                cols="6"
                class="pt-1 pb-1"
              >{{ passCount }}</v-col>
            </v-row>
          </v-card-title>
          <v-row>
            <v-col cols="4">
              <v-menu
                v-model="menuDateFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFrom"
                    :label="$t('cards.pass_time_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  @input="menuDateFrom = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-menu
                v-model="menuDateTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateTo"
                    :label="$t('cards.pass_time_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateTo"
                  @input="menuDateTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-btn
                v-on:click="getPasses"
                color="primary"
                block
              >{{
                $t("cards.pass_read")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Config from "@/config/config";
import Provider from "@/api/provider";
const util = require("util");
import { mapGetters, mapActions } from "vuex";
import AccessDialog from "@/components/main/cards/Accesses";

export default {
  name: "",
  components: { AccessDialog },
  mounted() {
    //this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  },
  created() {},
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      benched: 0,
      card: {
        /*cardNumber: "",
        user: "",
        dailyLimit: 0,
        from: "",
        to: "",
        access: [],*/
      },

      dateFrom: new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
        .toISOString()
        .substr(0, 10),
      //dateFrom: new Date().toISOString().substr(0, 10),
      menuDateFrom: false,

      dateTo: new Date().toISOString().substr(0, 10),
      menuDateTo: false,
      passCount: "-",
    };
  },
  methods: {
    ...mapActions([
      "selectCard",
      "updateCardInAllList",
      "selectCardAndUpdateDeviceAccess",
      "setError",
    ]),

    getGroups() {
      return JSON.parse(localStorage["keycloak.groups"]);
    },

    getPasses() {
      Provider.getData(
        util.format(
          Config.endpointPassesCard,
          this.card._id,
          this.dateFrom,
          this.dateTo
        ),
        {}
      )
        .then((response) => {
          this.passCount = response.count_passes;
          window.console.log(response);
        })
        .catch((error) => {
          //TODO chyba
          window.console.log("chyba");
          window.console.log(error);
        });
    },

    do() {
      window.console.log("do");
    },

    selectLimitInterval(item) {
      this.card.max_use_interval = item.value;
    },

    makeCopyAndRemoveItem() {
      let copyOfCard = Object.assign({}, this.card);
      let newAccesses = [];
      for (let tmp of this.card.access) {
        let newAccess = Object.assign({}, tmp);
        delete newAccess.item;
        newAccesses.push(newAccess);
      }
      copyOfCard.access = newAccesses;
      return copyOfCard;
    },

    filterInt(value) {
      if (/^[-+]?(\d+|Infinity)$/.test(value)) {
        return Number(value);
      } else {
        return NaN;
      }
    },

    updateCard() {
      window.console.log(this.card);
      if (this.card.max_use_interval === "") {
        this.card.max_use_limit = "";
      }
      if (this.card.max_use_limit === "") {
        this.card.max_use_interval = "";
      }
      if (
        (this.card.max_use_interval === undefined &&
          this.card.max_use_limit !== undefined) ||
        (this.card.max_use_interval !== undefined &&
          this.card.max_use_limit === undefined)
      ) {
        this.setError(this.$t("cards.error_limit_interval"));
        return;
      }

      if (this.card.card_number === undefined || this.card.card_number === "") {
        this.setError(this.$t("cards.error_card_number"));
        return;
      }

      window.console.log("update card");
      const cardCopy = this.makeCopyAndRemoveItem();
      let tmpCountPasses = cardCopy.count_passes;
      delete cardCopy.count_passes;
      window.console.log(cardCopy);
      Provider.putData(
        util.format(Config.endpointCardsPathVar, cardCopy._id),
        cardCopy
      )
        .then((response) => {
          window.console.log("response of updated card");
          window.console.log(response);
          response.count_passes = tmpCountPasses;
          this.selectCardAndUpdateDeviceAccess({
            card: response,
            hubs: this.getAllInstallations,
            devices: this.getAllDevices,
          });
          window.console.log("updated in memory data with devices in access");
          window.console.log(this.getSelectedCard);
          this.updateCardInAllList(this.getSelectedCard);
        })
        .catch((error) => {
          //TODO chyba
          window.console.log("chyba");
          window.console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["getSelectedCard", "getAllInstallations", "getAllDevices"]),
  },
  watch: {
    getSelectedCard(to, from) {
      this.passCount = "-";
      window.console.log("watch getselected");
      window.console.log(to);
      this.card = Object.assign({}, to);
      if (Object.keys(to).length !== 0) {
        let newArray = [];
        for (let tmp of to.access) {
          newArray.push(Object.assign({}, tmp));
        }
        this.card.access = newArray;
      }
      window.console.log(this.card);
    },
  },
};
</script>