import { mapGetters, mapActions } from "vuex";

const state = {
	selectedCard: null,
	allCards: [],
};

const getters = {
	getSelectedCard(state) {
		return state.selectedCard;
	},
	getAllCards(state) {
		return state.allCards;
	},
};
const mutations = {
	mutateSetCard: (state, result) => {
		state.selectedCard = result;
	},
	mutateSetCardAndUpdateDeviceAccess: (state, result) => {
		state.selectedCard = result.card;
		for (let tmp of state.selectedCard.access) {
			const device_HUB_Item = findItem(tmp, result.hubs, result.devices);
			tmp["item"] = device_HUB_Item;
		}
	},
	mutateSetCardInAllList: (state, result) => {
		const index = state.allCards.findIndex(t => t._id === result._id);
		state.allCards.splice(index, 1, result);
	},
	mutateSetAllCards: (state, result) => {
		state.allCards = result;

	},
	mutateDeleteCardFromAllCards: (state, result) => {
		const index = state.allCards.findIndex(t => t._id === result);
		state.allCards.splice(index, 1);
	},
	mutateSetDeviceOfAccessInAllCards: (state, result) => {
		if (state !== undefined && state.allCards !== undefined) {
			for (let card of state.allCards) {
				if (card.access !== undefined) {
					for (let tmp of card.access) {
						const device_HUB_Item = findItem(tmp, result.hubs, result.devices);
						tmp["item"] = device_HUB_Item;
					}
				}
			}
		}
	},
};
const actions = {
	async selectCard({ commit }, result) {
		commit('mutateSetCard', result);
	},
	async selectCardAndUpdateDeviceAccess({ commit }, result) {
		commit('mutateSetCardAndUpdateDeviceAccess', result);
	},
	async updateCardInAllList({ commit }, result) {
		commit('mutateSetCardInAllList', result);
	},
	async setAllCards({ commit }, result) {
		commit('mutateSetAllCards', result);
	},
	async deleteCardFromAllCards({ commit }, result) {
		commit('mutateDeleteCardFromAllCards', result);
	},
	async setDeviceOfAccessInAllCards({ commit }, result) {
		commit('mutateSetDeviceOfAccessInAllCards', result);
	},
};
/*this.selectCard(response);
		  this.updateCardInAllList(response);*/

function findItem(anItem, getAllInstallations, getAllDevices) {
	if (anItem !== undefined) {
		if (anItem.access_type === "device_hub") {
			const item = getAllInstallations.find(
				(t) => t._id === anItem.access_to_id
			);
			//window.console.log("polozka");
			//window.console.log(item);
			if (item !== undefined) {
				return item;
			} else {
				//window.console.log("neni");
				//return { name: "" };
				return {};
			}
		} else {
			const item = getAllDevices.find(
				(t) => t._id === anItem.access_to_id
			);
			//window.console.log("polozka");
			//window.console.log(item);
			if (item !== undefined) {
				return item;
			} else {
				//window.console.log("neni");
				//return { name: "" };
				return {};
			}
		}
	} else {
		//window.console.log("neni");
		//return Object.assign({}, { name: "" });
		return {};
	}
};

export default {
	state,
	getters,
	mutations,
	actions,
}