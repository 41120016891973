<template>
  <div style="border-right: 1px solid #dddddd; padding-right: 1em">
    <v-row>
      <v-card-title>
        <v-col cols="11" class="pa-0"
          >{{ $t("device.hub_detail") }}
          <v-icon class="pl-4" medium>mdi-apps</v-icon>
        </v-col>
        <v-col cols="1" class="pa-0">
          <v-tooltip left max-width="400" close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="green lighten-1"
                class="float-right pr-3">
                <v-icon right medium>mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <div class="pa-0" v-html="$t('device.info_hub')"></div>
          </v-tooltip>
        </v-col>
      </v-card-title>
    </v-row>
    <v-row>
      <v-col cols="3" class="pl-6">{{ $t("device.hub_name") }}</v-col>
      <v-col cols="8" class="pa-0 pr-2 ma-0">
        <v-text-field
            class="pa-0 pr-2 pt-2 ma-0"
            v-model="name"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3" class="pt-4 pl-6 body-1">{{ $t("general.group") }}</v-col>
      <v-col cols="8" class="pr-4 pl-0">
        <v-select
            v-model="group"
            :items="getGroups()"
            outlined
            hide-details
            dense/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="pl-6">{{ $t("device.hub_id") }}</v-col>
      <v-col cols="8" class="">
        {{ selectedInstallation._id }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2"/>
      <v-col cols="8" class="pr-5 pl-5">
        <v-btn v-on:click="updateInstallation" color="primary" block>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          {{ $t("general.save") }}
          <v-spacer></v-spacer>
          <v-icon>save</v-icon>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="12">
        <DeviceListEdit :devices="selectedInstallation.devices"
                        :installation="selectedInstallation"
                        @input="deviceSelected"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Config from "@/config/config"
import Provider from "@/api/provider"

const util = require("util")
import { mapActions } from "vuex"
import InstallationDeleteDialog from "@/components/main/devices/management/DeviceDeleteDialog";
import DeviceListEdit from "@/components/main/devices/management/DeviceListEdit";
import router from "@/router";

export default {
  components: {DeviceListEdit, InstallationDeleteDialog},
  props: [ "selectedInstallation", "devices" ],

  data() {
    return {
      name: "",
      group: "",
    };
  },

 watch: {
    selectedInstallation(to, from) {
      this.group = to.group
      this.name = to.name
    },
  },
  
  mounted() {
    this.name = this.selectedInstallation.name
    this.group = this.selectedInstallation.group
  },

  methods: {
    ...mapActions([
      "updateInstallationinAllList",
      "setError",
    ]),

    getGroups() {
      return JSON.parse(localStorage["keycloak.groups"])
    },

    updateInstallation() {
      Provider.putData(
        util.format(Config.endpointInstallations, this.selectedInstallation._id),
          {
            name: this.name,
            group: this.group
          },
        {}
      ).then((response) => {
          this.selectInstallation(response)
          this.name = response.name
          this.group = response.group
          this.updateInstallationinAllList(response)
      })
    },

    async deviceSelected(data) {
      if(data._id === this.$route.params.selectedDevice)
        return
      await router.push({name: "Installations", params: {selectedInstallation: data.installation_id, selectedDevice: data._id }})
    }
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

::v-deep tr.v-data-table__selected {
  background: #eeeeee !important;
}
</style>