<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="cash in device.cash_status">

      <v-expansion-panel-header>
          <div class="text-h5">{{ $t("device.states.kiosk." + cash.deviceType) }}</div>
          <div style="text-align: right; color: #AAAAAA" class="mr-2">{{ cash.deviceType }}</div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-row class="mt-4" v-if="cash.dispenserRecords">
          <v-col cols="12" class="text-h6">{{ $t("device.states.kiosk.dispenser_records") }}</v-col>
        </v-row>
        <v-row v-if="cash.dispenserRecords">
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_index") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_value") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_count") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_suma") }}</v-col>
        </v-row>
        <v-row v-for="(record, i) in cash.dispenserRecords" :class="{ gray: (i % 2) === 1}">
          <v-col cols="3" class="center">{{ record.index }}</v-col>
          <v-col cols="3" class="center">{{ record.value }}</v-col>
          <v-col cols="3" class="center">{{ record.count }}</v-col>
          <v-col cols="3" class="center">{{ record.suma }}</v-col>
        </v-row>
        <v-row class="mt-4" v-if="cash.dispenserRecords">
          <v-col cols="9" class="font-weight-medium" style="text-align: right">{{ $t("device.states.kiosk.suma") }}</v-col>
          <v-col cols="3" style="text-align: center">{{ cash.dispenserSuma }}</v-col>
        </v-row>

        <v-row v-if="cash.dispenserRecords"><v-col cols="12"><v-divider/></v-col></v-row>

        <v-row v-if="cash.cashboxRecords">
          <v-col cols="12" class="text-h6">{{ $t("device.states.kiosk.cashbox_records") }}</v-col>
        </v-row>
        <v-row v-if="cash.cashboxRecords">
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_index") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_value") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_count") }}</v-col>
          <v-col cols="3" class="center">{{ $t("device.states.kiosk.record_suma") }}</v-col>
        </v-row>
        <v-row v-for="(record, i) in cash.cashboxRecords" :class="{ gray: (i % 2) === 1}">
          <v-col cols="3" class="center">{{ record.index }}</v-col>
          <v-col cols="3" class="center">{{ record.value }}</v-col>
          <v-col cols="3" class="center">{{ record.count }}</v-col>
          <v-col cols="3" class="center">{{ record.suma }}</v-col>
        </v-row>
        <v-row class="mt-4" v-if="cash.cashboxRecords">
          <v-col cols="9" class="font-weight-medium" style="text-align: right">{{ $t("device.states.kiosk.suma") }}</v-col>
          <v-col cols="3" style="text-align: center">{{ cash.cashboxSuma }}</v-col>
        </v-row>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "KioskStates",
  props: ["device"],
}
</script>

<style scoped>
.gray {
  background-color: #FAFAFA;
}

.center {
  text-align: center;
}
</style>