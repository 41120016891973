<template>
  <div>
  <v-tooltip left max-width="400" close-delay="200" v-if="type === 'turnstile'">
    <template v-slot:activator="{ on, attrs }" >
      <v-icon  class="pa-0" color="grey" medium v-bind="attrs" v-on="on">mdi-turnstile</v-icon>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_turnstile") }}
    </span>
  </v-tooltip>


  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'cash_register'">
    <template v-slot:activator="{ on, attrs }" >
      <v-icon class="pa-0" color="grey" medium v-bind="attrs" v-on="on">mdi-point-of-sale</v-icon>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_cash_register") }}
    </span>
  </v-tooltip>


  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'device_hub'" >
    <template v-slot:activator="{ on, attrs }" >
      <v-icon class="pa-0" color="grey" medium v-bind="attrs" v-on="on">device_hub</v-icon>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_device_hub") }}
    </span>
  </v-tooltip>


  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'barrier'">
    <template v-slot:activator="{ on, attrs }" >
      <v-icon class="pa-0" color="grey" medium v-bind="attrs" v-on="on">mdi-boom-gate</v-icon>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_barrier") }}
    </span>
  </v-tooltip>


  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'paybox'">
    <template v-slot:activator="{ on, attrs }" >
      <v-icon class="pa-0" color="grey" medium v-bind="attrs" v-on="on">mdi-archive</v-icon>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_paybox") }}
    </span>
  </v-tooltip>


  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'cash_register_with_turnstile'">
    <template v-slot:activator="{ on, attrs }" >
      <span v-bind="attrs" v-on="on">
        <v-icon class="pa-0" color="grey" medium>mdi-turnstile</v-icon><v-icon class="pa-0" color="grey" medium>mdi-point-of-sale</v-icon>
      </span>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_cash_register_with_turnstile") }}
    </span>
  </v-tooltip>

  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'kiosk'">
    <template v-slot:activator="{ on, attrs }" >
    <span v-bind="attrs" v-on="on">
      <v-icon class="pa-0" color="grey" medium>mdi-network-pos</v-icon>
    </span>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_kiosk") }}
    </span>
  </v-tooltip>

  <v-tooltip left max-width="400" close-delay="200" v-else-if="type === 'gp4bc'">
    <template v-slot:activator="{ on, attrs }" >
    <span v-bind="attrs" v-on="on">
      <v-icon class="pa-0" color="grey" medium>settings_remote</v-icon>
    </span>
    </template>

    <span class="pa-0">
      {{ $t("device.device_type_gp4bc") }}
    </span>
  </v-tooltip>

  </div>
</template>

<script>
export default {
  name: "DeviceTypeIcon",
  props: ["type"]
}
</script>

<style scoped>

</style>