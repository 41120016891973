<template>

  <v-select :label="$t('device.device_info_texts')"
            v-model="selected"
            multiple
            dense
            item-text="text"
            item-value="text"
            :items="sortedUserTexts"
            @input="select()">
    <template v-slot:selection="{ item, index }">
      <div v-if="selected.length === 1">
        {{ item.text }}
      </div>
      <span v-else-if="index === 1" class="grey--text text-caption">
        ({{ selected.length }})
      </span>
    </template>
  </v-select>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserTextFilter",
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapGetters(["getAllUserTexts"]),
    sortedUserTexts() {
      return this.getAllUserTexts.sort((a, b) => a.text.localeCompare(b.text))
    }
  },
  methods: {
    select: async function () {
      this.$emit("input", this.selected)
      await this.$nextTick();
    }
  }
}
</script>

<style scoped>

</style>
