<template lang="">
    <v-row class="align-center row-pointer py-2" no-gutters
        :class="{'active': item._id === selectedId}" @click="$emit('onClickItem',item)">
        <v-col cols="1">{{getFilterTypeValue()}}</v-col>
        <v-col cols="7">{{getValue(item.filterType)}}</v-col>
        <v-col cols="1">{{item.channel}}</v-col>
        <v-col cols="3">{{item.recipient}}</v-col>
    </v-row>
</template>
<script>
import {mapGetters} from "vuex"

export default {
    props: {
        item: Object,
        selectedId: undefined
    },

    computed: {
        ...mapGetters(["getAllDevices","getAllInstallations"]),

    },

    methods: {
      getValue(type) {
        if (type === 'group') {
          return this.item.filterValues;
        } else if (type === 'installation') {
          let list = []
          for (const tmp of this.item.filterValues) {
            const result = this.getAllInstallations.find(e => {
              return e._id === tmp
            })
            if (result)
              list.push(result.name)            
          }
          return list
        } else if (type === 'device') {
          let list = []
          for (const tmp of this.item.filterValues) {
            const result = this.getAllDevices.find(e => {
              return e._id === tmp
            })
            if (result)
              list.push(result.name)
          }
          return list
        }
      },

      getFilterTypes() {
            return [
                {name:'group', value:this.$t('general.group')},
                {name:'device', value:this.$t('accesses.device')},
                {name:'installation', value:this.$t('accesses.hub')},]
        },

        getFilterTypeValue() {
          const result = this.getFilterTypes().find(e => {
            return e.name === this.item.filterType
          })
          return result.value;
        }
    }
    
}
</script>
<style scoped>
  .row-pointer  {
    cursor: pointer;
  }

  .active {
    background-color: #dddddd;
  }

  .overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
</style>