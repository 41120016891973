<template>
  <v-card class="elevation-0">
    <v-card-title class="pt-0">{{ $t("device.devices") }}</v-card-title>
    <v-divider class=""></v-divider>
    <v-data-table
        v-model="selected"
        show-expand
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        outlined
        mobile-breakpoint="0"
        dense
        :footer-props="{ itemsPerPageText: '' }"
        :headers="headersLocalized"
        :items="filterParentDevices(devices)"
        :search="search"
        single-select
        item-key="_id"
        class="elevation-0 row-pointer"
        @click:row="onClickItem">

      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn icon @click="expand(true);ignoreOnClick=true;" v-if="item.type === 'device_hub' && !isExpanded"><v-icon>mdi-chevron-down</v-icon></v-btn>
        <v-btn icon @click="expand(false);ignoreOnClick=true;" v-if="item.type === 'device_hub' && isExpanded"><v-icon>mdi-chevron-up</v-icon></v-btn>
      </template>


      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="green lighten-5 pa-0">
          <HubDeviceList v-if="item != null"
            :device="item"
            :installation="installation"
            :devices="devices"
            @input="deviceSelected"
            @deleted="$emit('input', undefined);"
            :selectedDeviceId="selectedDeviceId"/>
        </td>  
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('general.search')"
              single-line
              hide-details/>
          <v-spacer/>
          <DeviceCreateDialog :installation="installation"></DeviceCreateDialog>
        </v-toolbar>
      </template>

      <template v-slot:item.is_connected="{ item }">
        <DeviceStatusIcon :device="item"/>
      </template>

      <template v-slot:item.infoText="{ item }">
        <DeviceInfoIcon :device="item" />
      </template>

      <template v-slot:item.type="{ item }">
        <DeviceTypeIcon :type="item.type"/>
      </template>

      <template v-slot:item.actions="{ item }">
        <DeviceDeleteDialog
            :device="item"
            :installation="installation"
            @deleted="$emit('input', undefined)"></DeviceDeleteDialog
            >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DeviceCreateDialog from "@/components/main/devices/management/DeviceCreateDialog"
import DeviceStatusIcon from "@/components/main/devices/DeviceStatusIcon"
import DeviceInfoIcon from "@/components/main/devices/DeviceInfoIcon"
import DeviceDeleteDialog from "@/components/main/devices/management/DeviceDeleteDialog"
import HubDeviceList from "@/components/main/devices/management/HubDeviceList";
import DeviceTypeIcon from "@/components/main/devices/DeviceTypeIcon";

export default {
  name: "DeviceListEdit",
  components: {DeviceTypeIcon, DeviceDeleteDialog, DeviceStatusIcon, DeviceInfoIcon, DeviceCreateDialog, HubDeviceList},
  props: ["devices", "installation"],
  data() {
    return {
      selected: [],
      ignoreOnClick: false,
      expanded: [],
      singleExpand: false,
      selectedDeviceId: undefined,
      headers: [
        { text: "", 
          value: "data-table-expand",
          class: "grey lighten-2 pa-0",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "is_connected",
          class: "grey lighten-2 pa-0",
          width: "2px",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "infoText",
          class: "grey lighten-2 pa-0",
          width: "2px",
          sortable: false,
        },
        {
          textKey: "device.device_hap",
          text: this.$t("device.device_hap"),
          align: "center",
          value: "hap",
          class: "grey lighten-2 pa-0",
        },
        {
          textKey: "device.device_name",
          text: this.$t("device.device_name"),
          align: "center",
          value: "name",
          class: "grey lighten-2 pa-0",
        },
        {
          textKey: "device.device_type",
          text: this.$t("device.device_type"),
          align: "center",
          value: "type",
          class: "grey lighten-2 pa-0",
        },
        {
          text: "",
          align: "center",
          value: "actions",
          class: "grey lighten-2 pa-0",
          sortable: false,
        },
      ],
      search: "",
    }
  },

  computed: {
    headersLocalized() {
      let ret = [...this.headers];
      for (let i = 0; i < this.headers.length; ++i) {
        if (ret[i].textKey) {
          ret[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return ret;
    },
  },

  methods: {
    onClickItem: async function (item, row) {
      if (!this.ignoreOnClick) {
        this.selectedDeviceId = item._id;
        row.select(true);
        this.$emit("input", item)
        await this.$nextTick();
      }
      this.ignoreOnClick = false;
    },

    filterParentDevices(devices) {
      let parents = [];
      parents = devices.filter(device => {
        return devices.findIndex(parent => device.parent_id === parent._id) === -1;
      });
      return parents;
    },

    deviceSelected(data) {
      this.selected = [];
      this.selectedDeviceId = data._id;
      this.$emit("input", data)
    },

  },
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

</style>