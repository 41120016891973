<template>
  <v-row no-gutters>
    <v-col class="pb-0 pl-1 col-md-3 col-sm-12"
           v-if="$vuetify.breakpoint.mdAndUp || selectedInstallation == null">
      <InstallationList v-model="selectedInstallation"/>
    </v-col>
    <v-col class="pb-0 pl-1 col-md-5 col-sm-12" style="width: 60%"
           v-if="($vuetify.breakpoint.mdAndUp && selectedInstallation != null) || (selectedInstallation != null && selectedDevice == null)">
      <InstallationDetail v-model="selectedDevice" :selectedInstallation="selectedInstallation" />
    </v-col>
    <v-col class="pb-0 pl-1 col-md-4 col-sm-12"
           v-if="$vuetify.breakpoint.mdAndUp || selectedDevice != null">
      <DeviceDetailEdit v-if="selectedDevice != null" :selected-device="selectedDevice" :devices="selectedInstallation.devices" @parentChanged="parentChanged" />
    </v-col>
  </v-row>
</template>

<script>
import InstallationList from "@/components/main/installations/InstallationList";
import InstallationDetail from "@/components/main/installations/InstallationDetail";
import DeviceDetailEdit from "@/components/main/devices/management/DeviceDetailEdit";
import {mapGetters} from "vuex"

export default {
  name: "",
  components: { InstallationList, InstallationDetail, DeviceDetailEdit },
  data() {
    return {
    };
  },
  methods: {
    do() {
      window.console.log("do");
    },
     parentChanged(device) {
      window.console.log("parentChanged"+JSON.stringify(device));
    },
  },
  computed: {
    ...mapGetters(["getAllDevices", "getInstallationById", "getDeviceById"]),
    selectedInstallation: function () {
      if (this.$route.params.selectedInstallation === "null")
        return null
      return this.getInstallationById(this.$route.params.selectedInstallation)
    },
    selectedDevice: function () {
      if (this.$route.params.selectedDevice === "null")
        return null
      return this.getDeviceById(this.$route.params.selectedDevice)
    },
  }
};
</script>
