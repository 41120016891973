<template>
  <div class="ma-2" >
    <v-row class="mt-2 no-gutters">
      <v-col cols="4 pa-2" class="font-weight-medium">IP</v-col>
      <v-col cols="8 pa-2">{{ device.ip }}</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="4 pa-2" class="font-weight-medium">Port</v-col>
      <v-col cols="8 pa-2">{{ device.port }}</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="4 pa-2" class="font-weight-medium">{{ $t("device.device_last_contact") }}</v-col>
      <v-col cols="8 pa-2">{{ (new Date (Date.parse(device.last_communication))).toLocaleString(this.$i18n.locale) }}</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="4 pa-2" class="font-weight-medium">{{ $t("device.device_sw_version") }}</v-col>
      <v-col cols="8 pa-2">{{ device.fw_version }}</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="4 pa-2" class="font-weight-medium">{{ $t("device.device_state_title") }}</v-col>
      <v-col cols="8 pa-2"><DeviceStatusChips :device="device"/></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>

    <v-row v-if="device.type === 'paybox' && device.cash_status != null" class="pt-2 no-gutters">
      <GP4AM_states :device="device"></GP4AM_states>
    </v-row>

    <v-row v-if="(device.type === 'cash_register' || device.type === 'cash_register_with_turnstile') && device.money_records != null" class="pt-2 no-gutters">
      <GPE4M_GP4AT_money_records :device="device"></GPE4M_GP4AT_money_records>
    </v-row>

    <v-row v-if="(device.type === 'gp4bc') && device.status_details != null && device.status_details.bollards != null" class="pt-2 no-gutters">
      <GP4BC_states :device="device"></GP4BC_states>
    </v-row>

    <v-row v-if="device.type === 'kiosk'" class="pt-2 no-gutters">
      <KioskStates :device="device" />
    </v-row>
  </div>
</template>

<script>
import GP4AM_states from "@/components/main/devices/control/states/GP4AM_states";
import DeviceStatusChips from "@/components/main/devices/DeviceStatusChips";
import {mapGetters} from "vuex";
import GPE4M_GP4AT_money_records from "@/components/main/devices/control/states/GPE4M_GP4AT_money_records";
import GP4BC_states from "@/components/main/devices/control/states/GP4BC_states";
import KioskStates from "@/components/main/devices/control/states/KioskStates";
export default {
  name: "DeviceStates",
  components: {KioskStates, GP4BC_states, GPE4M_GP4AT_money_records, DeviceStatusChips, GP4AM_states},
  props: ["device"],
}
</script>

<style scoped>
.right {
}
</style>