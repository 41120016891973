import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'

import cs from "../localization/cs.js"
import en from "../localization/en.js"
import Keycloak from "keycloak-js";
import config from "@/config/config.js";
import axios from "axios";
import { VueWindowSizePlugin } from 'vue-window-size/option-api';

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueWindowSizePlugin)

const i18n = new VueI18n({
  locale: 'cs',
  messages: {
    en: en,
    cs: cs
  },
})

let keycloak = Keycloak(config.keycloakInitOptions)
let token = "";

axios.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ` + token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

keycloak
    .init({ onLoad: config.keycloakInitOptions.onLoad, checkLoginIframe: false })
    .then((auth) => {
      if (!auth) {
        keycloak.login({ redirectUri: runtime_config.keycloak_redirect });
      } else {
        saveCredentials();

        new Vue({
          router,
          store,
          vuetify,
          i18n,
          render: h => h(App, {props: {keycloak: keycloak}})
        }).$mount('#app')
      }
  
      setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
          if (refreshed)
            saveCredentials();
        }).catch((e) => {
          window.console.log('Failed to refresh token');
          window.console.log(e);
        });
      }, 6000)
  })

function saveCredentials() {
  token = keycloak.token;
  localStorage.setItem('keycloak.groups', JSON.stringify(keycloak.idTokenParsed.groups))
  localStorage.setItem('keycloak.roles', JSON.stringify(keycloak.realmAccess.roles))
  console.log(keycloak.idTokenParsed)
  localStorage.setItem('user', keycloak.idTokenParsed.given_name + " " + keycloak.idTokenParsed.family_name)


  localStorage.setItem(
    "keycloak-logout-url",
    keycloak.createLogoutUrl(config.keycloakInitOptions.silentCheckSsoRedirectUri)
  )
}
