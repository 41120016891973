<template>
  <v-row class="mr-1">
    <v-col class="col-md-4" style="border-right: 1px solid #dddddd" v-if="$vuetify.breakpoint.mdAndUp || selectedDevice == null">
      <DeviceList :allow-edit="false"></DeviceList>
    </v-col>
    <v-col class="col-md-8" v-if="selectedDevice != null">
      <DeviceDetail :selected-device="selectedDevice"/>
    </v-col>
  </v-row>
</template>

<script>
import DeviceList from "@/components/main/devices/control/DeviceList"
import {mapGetters, mapActions} from "vuex"
import DeviceDetail from "@/components/main/devices/control/DeviceDetail"
import Provider from "@/api/provider";
import Config from "@/config/config";

export default {
  name: "Devices",
  components: {DeviceDetail, DeviceList},
  mounted() {
    this.loadDevices()
    this.refresher = setInterval(this.loadDevices, runtime_config.device_refresh_interval_ms)
  },
  unmounted() {
    if(this.refresher !== null) {
      clearInterval(this.refresher)
      this.refresher = null
    }
  },
  data() {
    return {
      refresher: null
    }
  },
  methods: {
    ...mapActions(["setAllDevices"]),
    loadDevices() {
      Provider.getData(Config.endpointDevice, {})
          .then(async (response) => {
            await this.setAllDevices(response)
          })
          .catch((error) => {
            //error
          })
    }
  },
  computed: {
    ...mapGetters(["getAllDevices", "getDeviceById"]),
    selectedDevice: function() {
      if(this.$route.params.selectedDevice === "null")
        return null
      return this.getDeviceById(this.$route.params.selectedDevice)
    }
  }
}
</script>

<style scoped>

</style>