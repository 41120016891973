<template>
  <v-row no-gutters>
    <v-col cols="12 pa-2" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.cashbox_state") }}</v-col>

    <v-col cols="12 pa-2">
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="2" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.nominal_type") }}</v-col>
        <v-col cols="3" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.nominal_value") }}</v-col>
        <v-col cols="2" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.nominal_currency") }}</v-col>
        <v-col cols="2" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.nominal_tube_stat") }}</v-col>
        <v-col cols="2" class="font-weight-medium">{{ $t("device.states.gpe4m_gp4at.nominal_cashbox_stat") }}</v-col>
      </v-row>
      <v-divider class="pb-3 ml-10 mr-10"></v-divider>

      <v-row v-for="record in device.money_records">
        <v-col cols="1"></v-col>
        <v-col cols="2 pt-1 pb-1">
          <v-tooltip top v-if="record.type === 'Banknote'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" type="outline">mdi-cash-multiple</v-icon>
            </template>
            <span>{{ $t("device.states.gpe4m_gp4at.nominal_type_banknote") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="record.type === 'Coin'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
            </template>
            <span>{{ $t("device.states.gpe4m_gp4at.nominal_type_coin") }}</span>
          </v-tooltip>

        </v-col>
        <v-col cols="3 pt-1 pb-1">{{ record.nominal }}</v-col>
        <v-col cols="2 pt-1 pb-1">{{ record.currency }}</v-col>
        <v-col cols="2 pt-1 pb-1">{{ record.tube_stat }}</v-col>
        <v-col cols="2 pt-1 pb-1">{{ record.cashbox_stat }}</v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GPE4M_GP4AT_money_records",
  props: ["device"],
}
</script>

<style scoped>

</style>