<template>
    <v-card class=" elevation-0 pr-4">
          <v-row no-gutters style="border-bottom: solid #dddddd 1px">
            <v-col cols="1" class="font-weight-medium row-pointer" @click="clickSort('filterType')">
              {{ $t("notifications.filterType") }}<v-icon right v-if="sortBy === 'filterType' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'filterType' && !descending">mdi-arrow-up</v-icon>
            </v-col>
            <v-col cols="7" class="font-weight-medium row-pointer" @click="clickSort('filterValues')">
              {{ $t("notifications.filterValues") }}<v-icon right v-if="sortBy === 'filterValues' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'filterValues' && !descending">mdi-arrow-up</v-icon>
            </v-col>
            <v-col cols="1" class="font-weight-medium row-pointer" @click="clickSort('channel')">
              {{ $t("notifications.channel") }}<v-icon right v-if="sortBy === 'channel' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'channel' && !descending">mdi-arrow-up</v-icon>
            </v-col>
            <v-col cols="3" class="font-weight-medium row-pointer" @click="clickSort('recipient')">
              {{ $t("notifications.recipient") }}<v-icon right v-if="sortBy === 'recipient' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'recipient' && !descending">mdi-arrow-up</v-icon>
            </v-col>
          </v-row>
          <v-virtual-scroll :items="value" :item-height="37" :height="height" class="mt-3">
            <template v-slot:default="{ item }">
              <Notification :item="item" :selectedId="selectedId" @onClickItem="onClickItem"></Notification>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
          
    </v-card>
</template>

<script>
import Notification from "@/components/main/notifications/Notification.vue";

export default {
  name: "NotificationsList",
  components: {Notification},
  props: {
    value: Array,
    selectedId: String
  },

  data() {
    return {
      sortBy: "filterType",
      descending: false,
      selected: undefined,
    }
  },

  created() {

  },

  computed: {
    height() {
      return this.$windowHeight -180
    },

  },

  methods: {
    onClickItem: async function (item) {
      this.selected = item
      this.$emit("input", JSON.parse(JSON.stringify(item)))
    },

    clickSort: function(field) {
      if(this.sortBy === field)
        this.descending = !this.descending
      else
        this.sortBy = field
    },
  }

}
</script>

<style scoped>
  .row-pointer  {
    cursor: pointer;
  }

  .active {
    background-color: #dddddd;
  }

  .overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
</style>