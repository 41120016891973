<template>
<v-row>
  <v-col cols="4" class="text-subtitle-1">
    {{ getParamName() }}
  </v-col>
  <v-col cols="6">
    <v-text-field :label="param.name"
                  v-model="param.value"
                  dense
                  readonly
                  v-if="param.read_only === true"/>
    <v-text-field :label="param.name"
                  v-model="param.value"
                  dense
                  v-else/>
  </v-col>
  <v-col cols="2">
    <v-btn icon v-if="param.read_only !== true" @click="storeParameter(param.name, param.value)">
      <v-icon>mdi-arrow-right-thick</v-icon>
    </v-btn>
  </v-col>
  <Snackbar :value="snackbar" :text="snackbar_text" :type="snackbar_type" @closed="snackbar = false" />
</v-row>
</template>

<script>
import Provider from "@/api/provider";
import util from "util";
import Config from "@/config/config";
import Snackbar from "@/components/main/Snackbar";

export default {
  name: "EditableTextParameter",
  props: ["param", "device_id", "device_type"],
  components: {Snackbar},
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      snackbar_type: "",
    }
  },
  methods: {
    storeParameter(name, value) {
      Provider.putData(util.format(
          Config.endpointDeviceParam,
          this.device_id,
          name,
          encodeURIComponent(value)
      ), {}, {})
          .then((response) => {
            this.snackbar_text = this.$t("device.param_saved")
            this.snackbar_type = "success"
            this.snackbar = true
          })
          .catch((error) => {
            this.snackbar_text = error
            this.snackbar_type = "error"
            this.snackbar = true
            console.log(error)
          });
    },

    getParamName() {
      if (this.$t("device.parameters." + this.device_type + "." + this.param.name) === ("device.parameters.paybox." + this.param.name))
        return this.param.name
      else
        return this.$t("device.parameters." + this.device_type + "." + this.param.name)
    }
  },
}
</script>

<style scoped>

</style>