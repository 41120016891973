<template>
  <!--<v-card class="pa-3 overflow-y-auto" :max-height="windowSize.y - 90">-->
  <div style="border-right: 1px solid #dddddd; padding-right: 1em" class="pa-3 pt-2 pl-2 overflow-y-auto">
    <v-card-title>
      <v-row class='pb-4'>
        <v-col
            cols="6"
            class="pa-0"
        >{{
            $t("cards.cards")
          }}
          <v-icon
              class="pl-4"
              medium
          >mdi-credit-card-multiple
          </v-icon>
        </v-col>
        <v-col class="pa-0">
          <v-tooltip
              right
              max-width="400"
              close-delay="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="green lighten-1"
                  class="float-right pr-3"
              >
                <v-icon
                    right
                    medium
                >mdi-information-variant
                </v-icon>
              </v-btn>
            </template>
            <div
                class="pa-0"
                v-html="$t('cards.info_cards')"
            ></div>
          </v-tooltip>
        </v-col>
      </v-row>

      <!--{{ windowSize.x }} x {{ windowSize.y }}-->
    </v-card-title>

    <!--<v-responsive class="overflow-y-auto" :max-height="windowSize.y - 90">-->
    <v-data-table
        dense
        outlined
        :footer-props="{
        itemsPerPageText: '',
      }"
        :headers="headersLocalized"
        :items="getFilteredCards"
        :search="search"
        class="elevation-0 row-pointer"
        item-key="_id"
        single-select
        @click:row="onClickItem"
        :fixed-header="true"
        :height="windowSize.y - 426"
    >
      <template v-slot:top>
        <v-row class="pa-0 pb-4">
          <v-col class="pt-0 pb-0">
            <v-card
                class="pa-2 pt-0 pb-0 elevation-0"
                outlined
            >
              <v-card-title class=" pr-0">
                <v-row class="pa-0 pt-2">
                  <v-col
                      cols="8"
                      class="pt-1 pb-1"
                  >{{
                      $t("cards.read_pass_count_btn")
                    }}
                  </v-col>

                  <v-col
                      cols="4"
                      class="pa-0 pl-0 pr-2"
                  >

                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on:click="cancelPasses"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            block
                        >{{ $t("cards.pass_cancel") }}
                        </v-btn>
                      </template>
                      <span>{{
                          $t("cards.pass_cancel_tooltip")
                        }}</span>
                    </v-tooltip>

                  </v-col>
                </v-row>
              </v-card-title>
              <v-row class="">
                <v-col
                    cols="4"
                    class=""
                >
                  <v-menu
                      v-model="menuDateFrom"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="dateFrom"
                          :label="$t('cards.pass_time_from')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateFrom"
                        @input="menuDateFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                    cols="4"
                    class=""
                >
                  <v-menu
                      v-model="menuDateTo"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="dateTo"
                          :label="$t('cards.pass_time_to')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateTo"
                        @input="menuDateTo = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="4"
                    class="pr-2 pl-2"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-on:click="getPasses"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          block
                      >
                        {{ $t("cards.pass_read") }}
                      </v-btn>
                    </template>
                    <span>{{
                        $t("cards.pass_read_tooltip")
                      }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-toolbar
            class="pt-2"
            flat
            dense
        >
          <v-select
              dense
              v-model="cardAccessHubFilter"
              :items="getAllInstallations"
              item-text="name"
              item-value="_id"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('cards.filter_installation')"
              multiple
          ></v-select>
        </v-toolbar>
        <v-toolbar
            flat
            dense
        >
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('general.search')"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog
              v-model="dialog"
              max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="setEdited"
              >
                {{ $t("cards.add_new") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col
                      cols="8"
                      class="pa-0"
                  >
                    <span class="headline">{{ $t("cards.add_new") }}</span>
                  </v-col>
                  <v-col class="pa-0">
                    <v-tooltip
                        right
                        max-width="400"
                        close-delay="200"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="green lighten-1"
                            class="float-right pr-3"
                        >
                          <v-icon
                              right
                              medium
                          >mdi-information-variant
                          </v-icon>
                        </v-btn>
                      </template>
                      <div
                          class="pa-0"
                          v-html="$t('cards.info_card_new')"
                      ></div>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-container class="pa-0">
                  <v-row class="pa-0">
                    <v-col
                        cols="6"
                        class="pa-0 pl-4 pr-4"
                    >
                      <v-text-field
                          v-model="editedItem.card_number"
                          :label="$t('cards.number')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="6"
                        class="pa-0 pl-4 pr-4"
                    >
                      <v-text-field
                          v-model="editedItem.username"
                          :label="$t('cards.username')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col
                        cols="4"
                        class="pa-0 pl-4 pr-4"
                    >
                      <v-select
                          dense
                          v-model="editedItem.group"
                          :hint="`aaaas`"
                          :items="getGroups()"
                          :label="$t('general.group')"
                          outlined
                          hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col
                        cols="4"
                        class="pa-0 pl-4 pr-4"
                    >
                      <v-text-field
                          class="pt-0"
                          v-model="editedItem.max_use_limit"
                          :label="$t('cards.day_max')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="4"
                        class="pa-0 pl-4 pr-4"
                    >
                      <v-select
                          dense
                          v-model="editedItem.max_use_interval"
                          :hint="`sdfsd`"
                          :items="[
                          { value: 'day', text: $t('cards.limit_day') },
                          { value: 'month', text: $t('cards.limit_month') },
                          {
                            value: '',
                            text: $t('cards.limit_undefined'),
                          },
                        ]"
                          :label="$t('cards.limit_interval')"
                          v-on:input="selectLimitInterval"
                          return-object
                          outlined
                          hide-details
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col
                        cols="12"
                        class="pr-3 pt-0 pb-0 pl-0 mb-0"
                    >
                      <AccessDialog :card="editedItem"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="dialogDelete"
              max-width="500px"
          >
            <v-card>
              <v-card-title class="headline">
                {{ $t("general.delete_sanity_check") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                >{{
                    $t("general.cancel")
                  }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.access="{ item }">
        <!--<v-container class="ma-0 pa-0">-->
        <div
            v-for="oneAccess in item.access"
            :key="oneAccess.id_to_access"
            class="ma-2 pa-0"
        >

          <v-row v-if="oneAccess.hasOwnProperty('item')">
            <!-- v-if="oneAccess.hasOwnProperty('item')" -->
            <v-col
                cols="1"
                class="pa-0 pr-4 pl-4"
            >

              <v-icon
                  small
                  v-if="
                  oneAccess.access_type === 'device' &&
                  oneAccess.item.type === 'turnstile'
                "
              >mdi-turnstile
              </v-icon>
              <v-icon
                  small
                  v-if="
                  oneAccess.access_type === 'device' &&
                  oneAccess.item.type === 'cash_register'
                "
              >mdi-point-of-sale
              </v-icon>
              <v-icon
                  small
                  v-if="
                  oneAccess.access_type === 'device' &&
                  oneAccess.item.type === 'barrier'
                "
              >mdi-boom-gate
              </v-icon>

              <v-row
                  v-if="oneAccess.access_type === 'device' &&
                    oneAccess.item.type === 'cash_register_with_turnstile'"
                  class="pa-0 pt-3 pl-1">
                <v-col class="pa-0 pr-1" cols="6">
                  <v-icon class="pa-0" color="grey" small>mdi-point-of-sale</v-icon>
                </v-col>
                <v-col class="pa-0 pl-1" cols="6">
                  <v-icon class="pa-0" color="grey" small>mdi-turnstile</v-icon>
                </v-col>
              </v-row>

              <v-icon
                  small
                  v-if="
                  oneAccess.access_type === 'device' &&
                  oneAccess.item.type === 'paybox'
                "
              >mdi-archive
              </v-icon>

              <v-icon
                  small
                  v-if="
                  oneAccess.access_type === 'device' &&
                  oneAccess.item.type === 'device_hub'
                "
              >device_hub
              </v-icon>
              <v-icon
                  small
                  v-if="oneAccess.access_type === 'installation'"
              ><!--{{
                oneAccess.access_type
                
              }}-->
                mdi-apps
              </v-icon>
            </v-col>
            <v-col v-if="oneAccess.access_type === 'installation'"
                   cols="8"
                   class="pa-0"
            >
              {{ findItem(oneAccess).name }}
            </v-col>
            <v-col v-else
                   cols="8"
                   class="pa-0"
            >
              {{ oneAccess.item.name }}
            </v-col>
            <v-col
                cols="1"
                class="pa-0"
            >
              <v-icon
                  small
                  color="green"
                  v-if="oneAccess.allow"
              >checked
              </v-icon>
              <v-icon
                  small
                  color="red"
                  v-else
              >clear
              </v-icon>
            </v-col>
          </v-row>
        </div>
        <!--</v-container>-->
      </template>

      <template v-slot:item.max_use_interval="{ item }">
        {{ $t(`cards.limit_` + item.max_use_interval) }}
      </template>

      <template v-slot:item.updated_at="{ item }">
        {{ formatDate(item.updated_at) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            v-on:click="deleteItem(item)"
            icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!--</v-responsive>-->
  </div>
</template>

<script>
import Config from "@/config/config";
import Provider from "@/api/provider";

const util = require("util");
import {mapGetters, mapActions} from "vuex";
import AccessDialog from "@/components/main/cards/Accesses";

const moment = require("moment");

export default {
  name: "",

  components: {AccessDialog},

  data() {
    return {
      //dateFrom: new Date(new Date().getTime() - (1000 * 60 * 60 * 24)).toISOString().substr(0, 10),
      //dateFrom: new Date().toISOString().substr(0, 10),
      dateFrom: "2021-01-01",
      menuDateFrom: false,
      dateTo: new Date().toISOString().substr(0, 10),
      menuDateTo: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      benched: 0,
      search: "",
      dialog: false,
      dialogDelete: false,
      cardAccessHubFilter: [],
      headers: [
        {
          text: "",
          align: "center",
          value: "actions",
          class: "grey lighten-2",
          sortable: false,
          filterable: false,
        },
        {
          textKey: "cards.username",
          text: this.$t("cards.username"),
          align: "center",
          value: "username",
          class: "grey lighten-2",
        },
        {
          textKey: "cards.access",
          text: this.$t("cards.access"),
          align: "center",
          value: "access",
          class: "grey lighten-2",
          width: "250px",
          filterable: false,
        },
        {
          textKey: "cards.day_max",
          text: this.$t("cards.day_max"),
          align: "center",
          value: "max_use_limit",
          class: "grey lighten-2",
          filterable: false,
        },
        {
          textKey: "cards.limit_interval",
          text: this.$t("cards.limit_interval"),
          align: "center",
          value: "max_use_interval",
          class: "grey lighten-2",
          filterable: false,
        },
        /*{
          text: this.$t('cards.created'),
          align: "center",
          value: "created_at",
          class: "grey lighten-2",
          filterable: false,
        },*/
        {
          textKey: "cards.pass_count",
          text: this.$t("cards.pass_count"),
          align: "center",
          value: "count_passes",
          class: "grey lighten-2",
          filterable: false,
        },
        {
          textKey: "cards.updated",
          text: this.$t("cards.updated"),
          align: "center",
          value: "updated_at",
          class: "grey lighten-2",
          filterable: false,
        },
        {
          textKey: "cards.number",
          text: this.$t("cards.number"),
          align: "center",
          value: "card_number",
          class: "grey lighten-2",
        },
      ],
      editedItem: {
        access: [],
        card_number: "",
        created_at: "",
        max_use_limit: undefined,
        max_use_interval: undefined,
        updated_at: "",
        username: "",
        _id: "",
        group: "",
      },
      defaultItem: {
        access: [],
        card_number: "",
        created_at: "",
        max_use_limit: undefined,
        max_use_interval: undefined,
        updated_at: "",
        username: "",
        _id: "",
        group: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "getSelectedCard",
      "getAllCards",
      "getAllInstallations",
      "getAllDevices",
    ]),


    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },


    getFilteredCards() {
      let cards = this.getAllCards;
      cards = this.filterCardsByAccess(cards);
      //place other filters here
      return cards;
    },
  },

  watch: {
    editedItem(to) {
      //window.console.log("watch edited 1");
    },
    dialog(val) {
      val || this.close();
      //window.console.log("dialog");
      //window.console.log(this.editedItem);
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.dateFrom = "2021-01-01";
    this.dateTo = new Date().toISOString().substr(0, 10);
    this.initialize(this.dateFrom, this.dateTo);
  },

  mounted() {
    //this.windowSize = {x: window.innerWidth, y: window.innerHeight};
  },

  methods: {
    ...mapActions([
      "selectCard",
      "setAllCards",
      "setDeviceOfAccessInAllCards",
      "setAllInstallations",
      "setAllDevices",
      "selectCardAndUpdateDeviceAccess",
      "updateCardInAllList",
      "setError",
    ]),

    getPasses() {
      if (new Date(this.dateFrom) <= new Date(this.dateTo)) {
        this.initialize(this.dateFrom, this.dateTo);
      } else {
        this.setError(this.$t("errors.error_date_from_after_to"));
      }
    },

    cancelPasses() {
      this.dateFrom = "2021-01-01";
      this.dateTo = new Date().toISOString().substr(0, 10);
      this.initialize(this.dateFrom, this.dateTo);
    },

    formatDate(date) {
      let m = moment(date);
      return m.format("DD.MM.YYYY hh:mm");
    },

    filterCardsByAccess(cards) {
      if (
          this.cardAccessHubFilter === null ||
          this.cardAccessHubFilter.length < 1
      )
        return cards;

      return cards.filter((card) => {
        if (card.access !== undefined) {
          for (let access of card.access) {
            for (let installation_id of this.cardAccessHubFilter) {
              if (
                  access.access_type === "installation" &&
                  access.access_to_id === installation_id
              )
                return true;
              if (
                  access.access_type === "device" &&
                  access.item.installation_id === installation_id
              )
                return true;
            }
          }
        }
        return false;
      });
    },

    selectLimitInterval(item) {
      this.editedItem.max_use_interval = item.value;
    },

    getGroups() {
      return JSON.parse(localStorage["keycloak.groups"]);
    },

    async initialize(from, to) {
      window.console.log("inicializuju______");
      this.groups = JSON.parse(localStorage["keycloak.groups"]);
      window.console.log(JSON.parse(localStorage["keycloak.groups"]));
      if (from !== undefined && to !== undefined) {
        let groups = this.selectedGroups;
        let response = await Provider.getData(
            util.format(Config.endpointCardsPathVar2, from, to),
            {}
        ).catch((error) => {
          //error
        });
        this.setAllCards(response);
        this.setDeviceOfAccessInAllCards({
          hubs: this.getAllInstallations,
          devices: this.getAllDevices,
        });
        this.setEdited();
      } else {
        let response = await Provider.getData(Config.endpointInstallationList, {})
            /*.then((response) => {
              this.setAllInstallations(response);
            })*/
            .catch((error) => {
              //error
            });
        this.setAllInstallations(response);

        response = await Provider.getData(Config.endpointDevice, {})
            /*.then((response) => {
              this.setAllDevices(response);
            })*/
            .catch((error) => {
              //error
            });
        this.setAllDevices(response);

        response = await Provider.getData(Config.endpointCards, {})
            /*.then((response) => {
              this.setAllCards(response);
              this.setDeviceOfAccessInAllCards({
                hubs: this.getAllInstallations,
                devices: this.getAllDevices,
              });
            })*/
            .catch((error) => {
              //error
            });
        this.setAllCards(response);
        this.setDeviceOfAccessInAllCards({
          hubs: this.getAllInstallations,
          devices: this.getAllDevices,
        });
        this.setEdited();
      }
    },

    deleteItem(item) {
      this.editedIndex = this.getAllCards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      Provider.deleteData(
          util.format(Config.endpointCardsPathVar, this.editedItem._id),
          {}
      )
          .then((response) => {
            const index = this.getAllCards.findIndex(
                (t) => t._id === this.editedItem._id
            );
            this.getAllCards.splice(index, 1);
            this.closeDelete();
            this.selectCard({});
          })
          .catch((error) => {
            //TODO chyba
          });
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    makeCopyAndRemoveItem(card) {
      let copyOfCard = Object.assign({}, card);
      let newAccesses = [];
      for (let tmp of card.access) {
        let newAccess = Object.assign({}, tmp);
        delete newAccess.item;
        newAccesses.push(newAccess);
      }
      copyOfCard.access = newAccesses;
      return copyOfCard;
    },

    save() {
      if (this.editedItem.group === undefined || this.editedItem.group === "") {
        console.log(this.$t("errors.error_group"));
        return;
      }
      window.console.log(this.editedItem);
      if (this.editedItem.max_use_interval === "") {
        this.editedItem.max_use_interval = undefined;
      }
      if (this.editedItem.max_use_limit === "") {
        this.editedItem.max_use_limit = undefined;
      }
      if (
          (this.editedItem.max_use_interval === undefined &&
              this.editedItem.max_use_limit !== undefined) ||
          (this.editedItem.max_use_interval !== undefined &&
              this.editedItem.max_use_limit === undefined)
      ) {
        console.log(this.$t("cards.error_limit_interval"));
        return;
      }
      if (
          this.editedItem.card_number === undefined ||
          this.editedItem.card_number === ""
      ) {
        console.log(this.$t("cards.error_card_number"));
        return;
      }

      const cardCopy = this.makeCopyAndRemoveItem(this.editedItem);

      Provider.postData(Config.endpointCards, cardCopy, {})
          .then((response) => {
            this.close();
            this.getAllCards.push(response);
            this.selectCardAndUpdateDeviceAccess({
              card: response,
              hubs: this.getAllInstallations,
              devices: this.getAllDevices,
            });
            this.updateCardInAllList(this.getSelectedCard);
            window.console.log("response");
          })
          .catch((error) => {
            window.console.log(error);
            window.console.log("error");
            //chyba TODO
          });
      this.close();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onClickItem: async function (item, row) {
      row.select(true);
      this.selectCard(item);
      await this.$nextTick();
    },

    findItem(anItem) {
      if (anItem !== undefined) {
        if (anItem.access_type === "installation") {
          const item = this.getAllInstallations.find(
              (t) => t._id === anItem.access_to_id
          );
          //window.console.log("polozka");
          //window.console.log(item);
          if (item !== undefined) {
            return item;
          } else {
            //window.console.log("neni");
            //return { name: "" };
            return {};
          }
        } else {
          const item = this.getAllDevices.find(
              (t) => t._id === anItem.access_to_id
          );
          //window.console.log("polozka");
          //window.console.log(item);
          if (item !== undefined) {
            return item;
          } else {
            //window.console.log("neni");
            //return { name: "" };
            return {};
          }
        }
      } else {
        //window.console.log("neni");
        //return Object.assign({}, { name: "" });
        return {};
      }
    },

    getSelectedClass(item) {
      if (item._id == this.getSelectedCard._id) {
        return "brown lighten-4";
      } else {
        return "white";
      }
    },

    setEdited: async function () {
      window.console.log("edited");
      await this.$nextTick();
      await this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
      });
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

::v-deep tr.v-data-table__selected {
  background: #eeeeee !important;
}
</style>