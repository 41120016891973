<template>
  <div>
    <v-chip
        v-for="state in getInfoText(device)"
        :key="state"
        class="`ma-1 red`"
        label>
      {{ state }}
    </v-chip>
  </div>
</template>

<script>

export default {
  name: "DeviceInfoChips",
  props: ["device"],
  methods: {
    getInfoText(item) {
          return [item.infoText];
    
    },
  }
}
</script>

<style scoped>

</style>