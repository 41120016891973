const state = {
	allInstallations: [],
	allDevices: [],
};

const getters = {
	getAllInstallations(state) {
		return state.allInstallations;
	},
	getAllDevices(state) {
		return state.allDevices;
	},
	getInstallationById: state => id => {
		return state.allInstallations.find(installation => installation._id === id)
	},
	getDeviceById: state => id => {
		return state.allDevices.find(device => device._id === id)
	},
};
const mutations = {
	mutateSetInstallationinAllList: (state, result) => {
		const index = state.allInstallations.findIndex(t => t._id === result._id);
		state.allInstallations.splice(index, 1, result);
	},
	mutateSetAllInstallations: (state, result) => {
		state.allInstallations = result;
	},
	mutateSetAllDevices: (state, result) => {
		state.allDevices = result;
	},
	mutateDeleteInstallationFromAllInstallations: (state, result) => {
		const index = state.allInstallations.findIndex(t => t._id === result);
		state.allInstallations.splice(index, 1);
	},
};
const actions = {
	async updateInstallationinAllList({ commit }, result) {
		commit('mutateSetInstallationinAllList', result);
	},
	async setAllInstallations({ commit }, result) {
		commit('mutateSetAllInstallations', result);
	},
	async setAllDevices({ commit }, result) {
		commit('mutateSetAllDevices', result);
	},
	async deleteInstallationFromAllInstallations({ commit }, result) {
		commit('mutateDeleteInstallationFromAllInstallations', result);
	},
};

export default {
	state,
	getters,
	mutations,
	actions
}