var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("device.hub_new_button"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("device.device_new"))+" ")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"right":"","max-width":"400","close-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right pr-3",attrs:{"icon":"","color":"green lighten-1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","medium":""}},[_vm._v("mdi-information-variant")])],1)]}}])},[_c('div',{staticClass:"pa-0",domProps:{"innerHTML":_vm._s(_vm.$t('device.info_device_new'))}})])],1)],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t('device.device_type'),"items":[
                              {
                                value: 'turnstile',
                                text: _vm.$t('device.device_type_turnstile'),
                              },
                              {
                                value: 'cash_register',
                                text: _vm.$t('device.device_type_cash_register'),
                              },
                              {
                                value: 'cash_register_with_turnstile',
                                text: _vm.$t(
                                  'device.device_type_cash_register_with_turnstile'
                                ),
                              },
                              {
                                value: 'device_hub',
                                text: _vm.$t(
                                  'device.device_type_device_hub'
                                ),
                              },
                              {
                                value: 'paybox',
                                text: _vm.$t(
                                  'device.device_type_paybox'
                                ),
                              },
                              {
                                value: 'kiosk',
                                text: _vm.$t(
                                  'device.device_type_kiosk'
                                ),
                              },
                              {
                                value: 'gp4bc',
                                text: _vm.$t(
                                  'device.device_type_gp4bc'
                                ),
                              },
                              /*{
                                value: 'barrier',
                                text: $t('device.device_type_barrier'),
                              },*/
                            ]},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('device.device_name')},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1),(['turnstile', 'cash_register', 'cash_register_with_turnstile', 'gp4bc'].includes(_vm.editedItem.type))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('device.device_hap')},model:{value:(_vm.editedItem.hap),callback:function ($$v) {_vm.$set(_vm.editedItem, "hap", $$v)},expression:"editedItem.hap"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }