const state = {
	allUserTexts: [],
};

const getters = {
	getAllUserTexts(state) {
		return state.allUserTexts;
	},
	getUserTextById: state => id => {
		return state.allUserTexts.find(installation => installation._id === id)
	},
};

const mutations = {
		mutateSetAllUserTexts: (state, result) => {
		state.allUserTexts = result;
	},
};

const actions = {
	async setAllUserTexts({ commit }, result) {
		commit('mutateSetAllUserTexts', result);
	},
};

export default {
	state,
	getters,
	mutations,
	actions
}
