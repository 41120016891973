
export default {
	endpointInstallationList: runtime_config.backend_url + '/installation',
	endpointInstallations: runtime_config.backend_url + '/installation/%s',
	endpointDeviceResetApiKey: runtime_config.backend_url + '/device/%s/reset_key',
	endpointDeviceFactoryReset: runtime_config.backend_url + '/device/%s/restore_factory_setting',
	endpointDevice: runtime_config.backend_url + '/device',
	endpointDevicePathVar: runtime_config.backend_url + '/device/%s',
	endpointDeviceRestart: runtime_config.backend_url + '/device/%s/restart',
	endpointDeviceParam: runtime_config.backend_url + '/device/%s/param/%s/%s',
	endpointDeviceOperationState: runtime_config.backend_url + '/device/%s/open_state/%s/%s',
	endpointDeviceLogs: runtime_config.backend_url + '/device/%s/logs',

	endpointCards: runtime_config.backend_url + '/card',
	endpointCardsPathVar: runtime_config.backend_url + '/card/%s',
	endpointCardsPathVar2: runtime_config.backend_url + '/card/%s/%s',

	endpointTexts: runtime_config.backend_url + '/texts',

	endpointFinance: runtime_config.backend_url + '/finance',
    endpointFinanceIntervalDeviceVar: runtime_config.backend_url + '/finance/interval/%s/%s/device/%s',
    endpointFinanceExport: runtime_config.backend_url + '/finance/export/%s/interval/%s/%s/device/%s',

	endpointPassesCard: runtime_config.backend_url + '/max/card/%s/%s/%s',

	endpointNotifications: runtime_config.notification_url + '/subscription',
	endpointNotificationsVar: runtime_config.notification_url + '/subscription/%s',
	
	generatePaginationOfText(page, ofText) {
		let pageText;
		//window.console.log("generatePaginationOfText() =>");
		//window.console.log(page);
		if (page.page * page.itemsPerPage > page.itemsLength) {
			pageText =
				(page.page - 1) * page.itemsPerPage +
				1 +
				"-" +
				page.itemsLength +
				ofText +
				page.itemsLength;
		} else {
			pageText =
				(page.page - 1) * page.itemsPerPage +
				1 +
				"-" +
				page.page * page.itemsPerPage +
				ofText +
				page.itemsLength;
		}
		return pageText;
	},

	hide_states: ['mode_working','internal_error','reserved','permanent_open','dummy_mode','freeze_time',
	'blocking_card_issue','barrier_lose','card_sensor','detector_close_2','detector_close_1','detector_presence',
	'barrier_open','parking_state'],

	keycloakInitOptions: {
		url: runtime_config.keycloak_url || 'https://kc.parkcloud.cz/auth',
		realm: runtime_config.keycloak_realm || 'mars',
		clientId: runtime_config.keycloak_clientId || 'mars-frontend',
		onLoad: 'login-required',
		//onLoad: 'check-sso',
		silentCheckSsoRedirectUri: runtime_config.keycloak_redirect || 'http://localhost:8080/#/Main/Welcome',
		//bearerOnly: true
	}

}
