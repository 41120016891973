const state = {
    error: {},
};

const getters = {
    getError(state) {
        return state.error;
    },
};
const mutations = {
    mutateSetError: (state, result) => {
        state.error = result;
    },
};
const actions = {
    async setError({ commit }, result) {
        commit('mutateSetError', result);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}