<template>
  <v-row no-gutters>
    <v-col cols="4" class="font-weight-medium pa-2">
      {{ $t("device.states.gp4bc.operation_mode") }}
    </v-col>
    <v-col cols="8" class="pa-2">
      <div v-if="device.status_details.out_of_order" style="color: orange">{{ $t("device.states.gp4bc.out_of_order") }}</div>
      <div v-else>{{ $t("device.states.gp4bc.mode_normal") }}</div>
    </v-col>
    <v-col cols="12" class="pa-2">
      <v-row no-gutters>
        <v-col cols="2" class="font-weight-medium" style="text-align: center">{{ $t("device.states.gp4bc.bollard_number") }}</v-col>
        <v-col cols="3" class="font-weight-medium" style="text-align: center">{{ $t("device.states.gp4bc.bollard_space_number") }}</v-col>
        <v-col cols="2" class="font-weight-medium" style="text-align: center">{{ $t("device.states.gp4bc.bollard_state") }}</v-col>
        <v-col cols="4" class="font-weight-medium" style="text-align: center">{{ $t("device.states.gp4bc.bollard_occupancy") }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12"  class="pl-2 pr-2">
      <v-row v-for="(bollard, i) in device.status_details.bollards" :key="i" no-gutters :class="{ 'gray-row': i % 2 === 0 }" class="pt-1 pb-1">
        <v-col cols="2" class="pl-1" style="text-align: center">{{ i + 1 }}</v-col>
        <v-col cols="3" style="text-align: center" v-if="i < bollardCount">{{ device.bollards[i] }}</v-col>

        <v-col cols="2" v-if="i < bollardCount">
          <div v-if="bollard.end_switch_down == 1" style="color: green; text-align: center">
            {{ $t("device.states.gp4bc.bollard_open") }}
          </div>
          <div v-else-if="bollard.end_switch_up == 1" style="color: red; text-align: center">
            {{ $t("device.states.gp4bc.bollard_closed") }}
          </div>
          <div v-else style="color: orange; text-align: center">
            {{ $t("device.states.gp4bc.bollard_moving") }}
          </div>
        </v-col>

        <v-col cols="4" v-if="i < bollardCount">
          <div v-if="bollard.presence === '1'" style="color: red; text-align: center">{{ $t("device.states.gp4bc.bollard_occupied") }}</div>
          <div v-else style="color: green; text-align: center">{{ $t("device.states.gp4bc.bollard_free") }}</div>
        </v-col>

        <!--v-col cols="1" class="pr-1" v-if="i < bollardCount">
          <v-btn v-if="bollard.end_switch_up === '1'" small>Otevřít</v-btn>
          <v-btn v-if="bollard.end_switch_down === '1'" small>Zavřít</v-btn>
        </v-col-->

        <v-col cols="9" style="color: orange; text-align: center" v-else>{{ $t("device.states.gp4bc.bollard_not_connected") }}</v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GP4BC_states",
  props: ["device"],
  computed: {
    bollardCount: function () {
      const param = this.device.parameters.find(param => param.name === "number_of_bollards")
      if(param)
        return parseInt(param.value)
      return 0
    }
  }
}
</script>

<style scoped>
  .gray-row {
    background-color: #f4f4f4;
  }
</style>