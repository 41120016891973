<template>
  <v-select
    :label="$t('device.device_type')"
    v-model="selected"
    multiple
    dense
    :items="[
      {
        value: 'turnstile',
        text: $t('device.device_type_turnstile'),
      },
      {
        value: 'cash_register',
        text: $t('device.device_type_cash_register'),
      },
      {
        value: 'cash_register_with_turnstile',
        text: $t('device.device_type_cash_register_with_turnstile'),
      },
      {
        value: 'device_hub',
        text: $t('device.device_type_device_hub'),
      },
      {
        value: 'paybox',
        text: $t('device.device_type_paybox'),
      },
      {
        value: 'gp4bc',
        text: $t('device.device_type_gp4bc'),
      },
      {
        value: 'kiosk',
        text: $t('device.device_type_kiosk'),
      },
      /*{
                                  value: 'barrier',
                                  text: $t('device.device_type_barrier'),
                                },*/
    ]"
    @input="select()"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="selected.length === 1">
        <span>{{ item.text }}</span>
      </v-chip>
      <span v-else-if="index === 1" class="grey--text text-caption">
        ({{ selected.length }})
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "TypeFilter",
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    select: async function () {
      window.console.log(this.selected);
      this.$emit("input", this.selected);
      await this.$nextTick();
    },
  },
};
</script>

<style scoped>
</style>