export default {
    login: {
        title: "MARS Login",
        username: "Username",
        password: "Password",
        required: "Required field",
        enter_password: "Enter your password ",
        enter_password_hint: "Your password must contain at least 8 characters",
        enter_username: "Enter your username",
        login: "Log In",
        wrong_credentials: "Username or password is not correct",
    },
    menu: {
        menu: "Menu",
        dashboard: "Dashboard",
        user: "User",
        cards: "Card management",
        finance: "Financial statements",
        installations: "Installations",
        notifications: "Notifications",
        devices: "Devices",
        logout: "Logout",
    },
    cards: {
        cards: "Card management",
        detail: "Card details",
        add_new: "Create a new card",
        number: "Card number",
        username: "Username",
        access: "Access",
        created: "Created",
        updated: "Edited",
        day_max: "Pass limit",
        limit_interval: "Time interval",
        limit_day: "Day",
        limit_month: "Month",
        limit_undefined: "-",
        limit_: "-",
        error_limit_interval: "The fields `Pass limits` and `Pass limits` have to be both filled or empty.",
        filter_installation: "Choose installation",
        pass_count: "Number of passes",
        pass_read: "Load",
        pass_time_from: "Date from",
        pass_time_to: "Date to",
        info_cards: "<h2 class='pa-2 pb-3'>Card management</h2>" +
            "<p>This part of the screen displays cards from selected installations. It displays cards from all installations if there is no chosen installation in the box <b><i>Choose installation</i></b>.</p>" +
            "<p>A new card can be created by pressing the button <b><i>Create a new card</i></b> and by filling the form.</p>" +
            "<p>Card details are displayed by selecting a row from the table.</p>" +
            "<p>A card is deleted by pressing the bin icon in the table <i class='mdi mdi-delete'></i>.</p>" +
            "<p>Records can be searched by entering of searched text into the field <b><i>Search</i></b> <i class='mdi mdi-magnify'></i></p>" +
            "<p>At the bottom of the table, you can select the number of records per page of the table and scroll through the pages of records using the arrows <b><i><i class='mdi mdi-chevron-left'></i> <i class='mdi mdi-chevron-right'></i></i></b>.</p>",

        info_card_new: "<h2 class='pa-2 pb-3'>Create a new card.</h2>" +
            "<p>Please enter <i>card number</i> and <i>username</i>. You can define <i>pass limit</i> and corresponding <i>time interval</i>. Please enter also <i>access rules</i> to devices or installations. Then please confirm with the button <b><i>save</i></b>.</p>",

        error_card_number: "The filed `Card number` must be filled with a numeric value.",
        read_pass_count_btn: "Display the number of passes per period",
        pass_cancel: "Load all",
        pass_cancel_tooltip: "Loading of all passes of displayed cards from 1.1.2021 till today.",
        pass_read_tooltip: "Loading of all passes of displayed cards in the period defined from <i>Date from</i> a <i>Date to</i>.",
        info_card_detail: "<h2 class='pa-2 pb-3'>Card details</h2>" +
            "<p>To change the card details, correct the required items and save the new values by pressing the button <b><i>SAVE</i></b> <i class='mdi mdi-content-save'></i>.</p>" +
            "<p>The access rules determine on which installation or on which device the card is valid. Number of card uses is limited by value in the field <i>Pass limits</i> for the period defined by <i>Time interval</i>.</p>" +
            "<p>Card access to other places can be added by choosing fields <i>installation</i> and/or <i>device</i> and by pressing the button <b><i>Add access</i></b>.</p>" +
            "<p>Access can be cancelled by pressing the bin icon <i class='mdi mdi-delete'></i> on the corresponding row.</p>" +
            "<p>At the bottom of the table you can choose a time period <i>Date from</i> and <i>Date to</i>. After pressing the button <b><i>Load</i></b> you can see the number of the card passess in the corresponding period.</p>",

    },
    accesses: {
        accesses: "Access rules",
        hub: "Installation",
        device: "Device",
        allow: "Allow access",
        add: "Add access",
        access_type: "Type of access",
        access_to: "Place of access",
    },
    device: {
        hubs: "Installation list",
        hub_detail: "Installation details",
        hub_name: "Installation name",
        hub_new: "Create a new installation",
        hub_api_key: "API key",
        hub_id: "ID",
        hub_new_button: "Create",
        devices: "Devices",
        device_detail: "Device details",
        device_new: "Create a new device",
        device_hap: "HAP address",
        device_name: "Device name",
        device_type: "Device type",
        device_sw_version: "Version",
        device_last_contact: "Last contact",
        device_restart: "Restart",
        device_factory_reset: "Reset to factory defaults",
        device_factory_reset_check_header: "Do you really want to restore factory defaults?",
        device_factory_reset_check_text: "This action will permanently delete all user settings and history of payments, passes and financial reports from both server and device",
        device_factory_reset_success: "Device factory reset was successful",
        device_factory_reset_error: "Device factory reset failed",
        device_factory_reset_button: "Delete data",
        device_restart_check: "Are you sure you want to restart the device?",
        device_rename: "Rename",
        device_rename_success: "Device was successfully renamed",
        device_rename_error: "Device rename failed",
        device_state_title: "Device state",
        //cashbox status
        device_out_of_order: "Out of order",
        device_paper_near: "Running out of paper",
        device_door_open: "Door open",
        device_tubes_low: "Low coin tubes",
        device_tubes_empty: "Empty coin tubes",
        device_cashbox_full: "Full cashbox",
        device_banknotes_dispenser_error: "Banknotes dispenser error",
        device_coins_dispenser_error: "Coins dispenser error",
        device_coin_cashbox_open: "Opened coin cashbox",
        device_banknote_cashbox_open: "Opened banknote cashbox",
        //turnstile status
        device_paper_low: "Running out of paper",
        device_printer_error: "Printer error",
        device_barrier_error: "Barrier error",
        device_connected: "Online",
        device_disconnected: "Offline",
        device_none: "Unknown state",

        //servis
        device_info_texts: "User info",

        device_mode_working: "Working mode",
        device_mode_service: "Service mode",
        device_mode_fire_alarm: "Fire alarm mode",
        device_reserved: "_reserved",
        device_internal_error: "Internal error",

        device_banknotes_reader_error: "Banknotes reader error",
        device_banknote_dispenser_low: "Banknotes dispenser running out of banknotes ",
        device_banknote_dispenser_empty: "Empty banknotes dispenser",
        device_parking_state: "_parking_state",
        device_barrier_open: "Barrier open",
        device_barrier_lose: "Barrier lose",
        device_detector_presence: "Detector presence",
        device_detector_close_1: "_detector_close_1",
        device_detector_close_2: "_detector_close_2",
        device_card_sensor: "_card_sensor",
        device_blocking_card_issue: "_blocking_card_issue",
        device_freeze_time: "_freeze_time",
        device_dummy_mode: "_dummy_mode",
        device_permanent_open: "Permanent open",
        device_cashbox_near: "Cashbox nearly full",

        info_hub_list: "<h2 class='pa-2 pb-3'>List of installation</h2>" +
            "<p>The list displays the operator's installations and online/offline/error indicators. </p>" +
            "<h3>The indicator color means:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Green - installation is online and without errors.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Red - installation is offline.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Yellow - installation has an error state.</li>" +
            "<p>By entering the <i>Search</i> field you can search in the list of installations.</p>" +
            "<p>At the bottom of the table, you can select the number of records per page of the table and scroll through the pages of records using the arrows <b><i><i class='mdi mdi-chevron-left'></i> <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
            info_hub: "<h2 class='pa-2 pb-3'>Installation details</h2>" +
            "<p>The list displays the installations devices and online/offline/error indicators.</p>" +
            "<h3>The indicator color means:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Green - device is online and without errors.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Red - device is offline.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Yellow - device has an error state.</li>" +
            "<p>After hovering the mouse over the status indicator icon the listing window with details is displayed.</p>" +
            "<p>By entering the <i>Search</i> field you can search in the list of devices.</p>" +
            "<p>At the bottom of the table, you can select the number of records per page of the table and scroll through the pages of records using the arrows <b><i><i class='mdi mdi-chevron-left'></i> <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
        info_device: "<h2 class='pa-2 pb-3'>Device details</h2>" +
            "<p>This box displays details for individual devices.</p>" +
            "<p>To change device name please correct the device name and press the button <b><i>Rename</i></b>.</p>" +
            "<p>The device status details are displayed at the bottom of the box.</p>" +
            "<h3>The indicator color means:</h3>" +
            "<li><span style='color: #4CAF50;'><i class='mdi mdi-circle'></i></span> Green - device is online and without errors.</li>" +
            "<li><span style='color: #F44336;'><i class='mdi mdi-circle'></i></span> Red - device is offline.</li>" +
            "<li><span style='color: #FFCC80;'><i class='mdi mdi-alert'></i></span> Yellow - device has an error state.</li>",
        info_hub_new: "<h2 class='pa-2 pb-3'>Create a new installation</h2>" +
            "<p>Please enter <i>installation name</i>. Then please confirm with the button <b><i>SAVE</i></b>.</p>",
        info_device_new: "<h2 class='pa-2 pb-3'>Create a new device</h2>" +
            "<p>Please enter the <i>HAP address, name and type</i> of the device. Please choose the <i>device type</i> from the drop-down menu. Then please confirm with the button <b><i>SAVE</i></b>.</p>",
        device_type_turnstile: "Turnstile (GPE4T)",
        device_type_cash_register: "Cash Register (GPE4M)",
        device_type_cash_register_with_turnstile: "Turnstile with Cash Register (GP4AT)",
        device_type_barrier: "Barrier (GPBarrier)",
        device_type_paybox: "Paybox (GP4AM)",
        device_type_kiosk: "Kiosk",
        device_type_gp4bc: "Bollards (GP4BC)",

        device_tab_summary: "Summary",
        device_tab_settings: "Settings",
        device_tab_logs: "Logs",

        parameters: {
            paybox: {
                payment: "Price (primary currency)",
                time: "Time",
                cash_box_near: "Cash box capacity warning",
                cash_box_near_limit: "Cashbox allowed capacity",
                cash_box_full: "Cashbox maximum secure capacity",
                exchange: "Secondary currency exchange",
                coins_enable: "Accepted coins",
                service_cards: "Service cards",
                coins_supported: "Supported coins",
                state: "Operation mode",
                state_n: "Normal",
                state_o: "Out of order, permanent open",
                state_c: "Out of order, permanent close",
                card_validate_mode: "Card validation mode",
                validation_ask: "Ask when card is read",
                validation_sync: "Card synchronization",
                card_validate_ask_proto: "Card ask - protocol",
                card_validate_ask_url: "Card ask - url",
                card_validate_ask_api_key: "Card ask - apikey"
            },
            gp4bc: {
                setOperationState: "Operation state",
                state_normal: "Normal",
                state_permanent_open: "Permanent open",
                number_of_bollards: "Bollard count",
                time_before_arrival: "Arrival timeout",
                time_after_departure: "Exit timeout",
            }
        },

        states: {
            paybox: {
                total_cash: "Total earnings (cash)",
                total_cashless: "Total earnings (card)",
                cashbox_coins: "Coin counts",
                cashbox_coins_total: "Total coin count",
                cashbox_last_empty: "Last cashbox withdrawal",
                cashbox_state: "Cashbox state",
                cashbox_state_total_cash: "Total (cash)",
                cashbox_state_total_cashless: "Total (card)",
                first_payment: "First payment date"
            },

            gpe4m_gp4at: {
                cashbox_state: "Cash status",
                nominal_type: "Type",
                nominal_value: "Value",
                nominal_currency: "Currency",
                nominal_tube_stat: "Tubes (pc.)",
                nominal_cashbox_stat: "Cashbox (pc.)",
                nominal_type_coin: "Coin",
                nominal_type_banknote: "Banknote/Bill",
            },

            gp4bc: {
                operation_mode: "Operation mode",
                out_of_order: "Out of order (bollards open)",
                mode_normal: "Normal",
                bollard_number: "Bollard No.",
                bollard_space_number: "Parking number No.",
                bollard_state: "Bollard state",
                bollard_occupancy: "Parking space occupancy",
                bollard_open: "Open",
                bollard_closed: "Closed",
                bollard_moving: "Bollard moving",
                bollard_occupied: "Occupied",
                bollard_free: "Free",
                bollard_not_connected: "Bollard not connected",
            },

            kiosk: {
                dispenser_records: "Nominal count for recycling (change return)",
                cashbox_records: "Cashbox state",
                record_index: "Nominal index",
                record_value: "Nominal value",
                record_count: "Count",
                record_suma: "Value sum",
                suma: "Total value sum",

                MH_COIN_HOPPER: "Hopper",
                MH_COIN_DISPENSER: "Coin dispenser",
                MH_COIN_RECYCLER: "Coin recycler",
                MH_BANKNOTE_READER_MEI: "Banknote reader MEI",
                MH_BANKNOTE_READER_ICT: "Banknote reader  ICT",
                MH_BANKNOTE_RECYCLER_MEI: "Banknote recycler MEI",
                MH_BANKNOTE_RECYCLER_JCM: "Banknote recycler JCM",
                MH_BANKNOTE_DISPENSER: "Banknote dispenser",
            }
        },

        param_saved: "Parameter saved",
        device_type_device_hub: "Device hub",
        parent: "Parent device",
    },
    general: {
        search: "Search",
        cancel: "Cancel",
        ok: "OK",
        save: "Save",
        delete_sanity_check: "Are you sure you want to delete this item?",
        welcome_text: "Welcome to MARS. Select a menu item to continue.",
        table_rows_per_page_text: "Rows per page",
        table_rows_per_page_all_text: "All",
        pagination_page_of: " from ",
        groups: "Groups",
        group: "Group",
        copied_to_clipboard: "Copied",
    },
    reports: {
        reports: "Financial reports",
        reports_all_label: "All reports",
        reports_all_btn: "Load all reports",
        reports_device_interval_label: "Reports from device",
        reports_export_label: "Export reports from device",
        reports_device_interval_btn: "Load reports from device from to",
        reports_export_btn: "Export reports from device from to",
        reports_lang:"en",
        th_device_id: "Device ID",
        th_device_type: "Device type",
        th_hap: "HAP address",
        th_card_number: "Card number",
        th_withdrawal_number: "Withdrawal number",
        th_withdrawal_year: "Withdrawal year",
        th_station_id: "Station ID",
        th_msg_event: "Event",
        th_timestamp: "Date",
        report_item_device_id: "device id",
        report_item_evt: "event",
        report_item_card_number: "card number",
        report_item_withdrawal_year: "withdrawal year",
        report_item_withdrawal: "withdrawal type",
        report_item_msg_event_transformed: "report type",
        report_item_timestamp_transformed: "date",
        report_item_device_type: "device type",
        report_item_hap: "hap address",
        report_item_withdrawal_number: "withdrawal number",
        report_item_station_id: "station id",
        report_item_log_level: "log",
        report_item_number_of_payments_by_card: "number of card payments",
        report_item_hopper_difference: "hopper difference",
        report_item_payment_card_total: "card payments total",
        report_item_recycler_difference: "recycler difference",
        report_item_refil: "refil",
        report_item_empty: "empty",
        time_from: "Time from",
        time_to: "Time to",
        date_from: "Date from",
        date_to: "Date to",
        report_item_event_withdrawal_2: "Withdrawal / refill",
        report_item_event_withdrawal_GPE: "Cashbox withdrawal",
        report_item_device_id_transformed: "Installation / device",
        th_device_id_transformed: "Installation / device",
        info_reports: "<h2 class='pa-2 pb-3'>Financial reports</h2>" +
            "<p>To load all the reports please press the button <b><i>Load all reports</i></b>.</p>" +
            "<p>To load the reports from a required device please choose the device from the list and then press the button <b><i>Load reports from the device</i></b>.</p>" +
            "<p>To load the reports for a required time period please choose the time and date from and to and then press the button <b><i>Load reports for the time period</i></b>.</p>" +
            "<p>The required report list displays individual reports according the request. By clicking on a table row, the report details are displayed including the cash balance.</p>" +
            "<h3>Records include also:</h3>" +
            "<li>Tubes – number of coins and banknotes in device to be used for cash returning</li>" +
            "<li>Cash box - number of coins and banknotes in cashbox during their withdrawal</li>" +
            "<li>Refill – number of coins and banknotes in device which was refilled to recyclers from the last withdrawal</li>" +
            "<li>Withdrawal – number of coins and banknotes which were withdrawn from recyclers since last cashbox withdrawal </li>" +
            "<p>By entering the <i>Search</i> field you can search in the list of reports.</p>" +
            "<p>At the bottom of the table, you can select the number of records per page of the table and scroll through the pages of records using the arrows <b><i><i class='mdi mdi-chevron-left'></i> <i class='mdi mdi-chevron-right'></i></i></b>.</p>",
    },
    report_detail: {
        detail: "Report details",
        th_type: "Type",
        th_nominal: "Nominal",
        th_currency: "Currency",
        th_tube_stat: "Tubes [pc]",
        th_cashbox_stat: "Cash box [pc]",
        th_refill_stat: "Refill [pc]",
        th_empty_stat: "Withdrawal [pc]",
        coin: "Coins",
        banknote: "Banknotes",
        undefined: "",
    },
    errors: {
        error_title: "Error",
        errorUnauthorized_message: "Unauthorized access, please log in.",
        error_date_from_after_to: "Date from is later than date to, please adjust the values so that date from is earlier than date to.",
        error_financial_report_device: "Please select the device for which you want to display financial reports.",
        error_financial_report_export: "This browser does not to support file downloading.",
        cards_empty_record: "The empty access rule cannot be added.",
        cards_record_exists: "This access rule already exists.",
        error_group: "Please choose a group.",
    },

    snackbar: {
        close: "Close"
    },

    dashboard: {
        device_summary_title: "My devices",
        device_summary_count: "Total",
        device_summary_online: "Conencted devices",
        device_summary_offline: "Disconnected devices",
        device_summary_warning: "Devices with warning",
    },

    notifications: {
        filterType: "Type",
        filterValues: "Value",
        channel: "Channel",
        recipient: "Recipient",
        createNewBtn: "Create new notification",
        newTitle: "New notification",
        editTitle: "Notification",
        deleteBtn: "Delete",
        chooseValues: "Choose values from list"
    }
}