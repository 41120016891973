<template>
  <div :class="`pa-0`">
    <v-card-text class="pt-0 pb-0 pl-1 pr-1">
      <div>
        <v-data-table
          dense
          outlined
          :footer-props="{
            itemsPerPageText: '',
          }"
          :headers="headersLocalized"
          :items="card.access"
          :search="search"
          class="elevation-0 row-pointer pt-0"
          @click:row="onClickItem"
        >
          <template v-slot:top>
            <v-container class="pa-0 pl-2 pr-2 pb-2 pt-0">
              <v-container class="pa-0 pt-4 pb-0">
                <v-row>
                  <v-col class="pa-0">
                      <v-card-title class="pt-2 pb-6">{{
                        $t("accesses.accesses")
                      }}</v-card-title>
                      <v-row>
                        <v-col cols="6" class="pa-0 pl-6 pr-4">
                          <v-row class="pb-2">
                            <v-col cols="12" class="pa-0 pt-0 pl-2">
                              <v-select
                                v-model="selectInstallation"
                                :hint="`${selectInstallation.name}, ${selectInstallation._id}`"
                                :items="getAllInstallations"
                                item-text="name"
                                :label="$t('accesses.hub')"
                                return-object
                                v-on:input="comboselectInstallation"
                                outlined
                                hide-details
                                dense
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-6 pr-8">
                          <v-row class="pb-2">
                            <v-col cols="12" class="pa-0">
                              <v-select
                                v-model="selectDevice"
                                :hint="`${selectDevice.name}, ${selectDevice._id}`"
                                :items="filterDevices(['turnstile','cash_register_with_turnstile','paybox'])"
                                item-text="name"
                                item-value="_id"
                                :label="$t('accesses.device')"
                                persistent-hint
                                return-object
                                v-on:input="comboSelectDevice"
                                hide-details
                                outlined
                                dense
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="5" class="pa-0 pt-4 pl-6 pr-6">
                          <v-checkbox
                            v-model="allow"
                            :label="$t('accesses.allow')"
                            style="margin: auto"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6" class="pa-0 pt-3 pl-3 pr-5">
                          <v-btn
                            v-on:click="addAccessItem"
                            color="primary"
                            dark
                            class="mb-0"
                            block
                          >
                            {{ $t("accesses.add") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <v-dialog v-model="dialogError" max-width="500px">
                <v-card>
                  <v-card-text class="headline text-center pt-6">
                    <v-icon medium class="pr-6" color="yellow darken-2"
                      >mdi-alert
                    </v-icon>
                    {{ dialogErrorMessage }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="errorDialogConfim"
                      >OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row class="pt-0 pb-4 pl-4 pr-4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('general.search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.actions="{ item }">
            <!--<v-icon class="pa-0" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>-->
            <v-btn @click="deleteItem(item)" icon class="pa-0">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.item.name="{ item }">
            <span v-if="item.access_type === 'installation'">
              {{ findItem(item).name }}
            </span>
            <span v-if="item.item === undefined">/</span>
            <span v-else>{{ item.item.name }} /</span>
            <span class="caption font-weight-medium text--disabled">{{
              item.access_to_id
            }}</span>
          </template>

          <template v-slot:item.access_type="{ item }">
            <span v-if="item.access_type === 'device'">
              <v-icon v-if="item.item.type === 'device_hub'" class="pl-0">{{ item.item.type }}</v-icon>
              <v-icon v-if="item.item.type === 'paybox'" class="pl-0">mdi-archive</v-icon>
              <v-icon v-if="item.item.type === 'turnstile'" class="pl-0">mdi-turnstile</v-icon>
              <v-icon v-if="item.item.type === 'cash_register'" class="pl-0">mdi-point-of-sale</v-icon>
              <v-icon v-if="item.item.type === 'barrier'" class="pl-0">mdi-boom-gate</v-icon>
              <span 
                  v-if="item.item.type === 'cash_register_with_turnstile'"
                  class="pa-0 pt-0 pl-0">
                <v-icon class="pa-0" color="grey">mdi-point-of-sale</v-icon>
                <v-icon class="pa-0" color="grey">mdi-turnstile</v-icon>
              </span>
              {{ $t("accesses.device") }}
            </span>
            <span v-else>
              <v-icon class="pl-0"><!--{{ item.access_type }}-->mdi-apps</v-icon>
              {{ $t("accesses.hub") }}
            </span>
          </template>

          <template v-slot:item.allow="{ item }">
            <v-checkbox v-model="item.allow" class="pl-6 pt-0"></v-checkbox>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Config from "@/config/config";
import Provider from "@/api/provider";

const util = require("util");

export default {
  props: {
    card: {},
  },
  data() {
    return {
      selectInstallation: [],
      selectDevice: [],
      allow: true,
      items: [
        { header: "Select an option or create one" },
        {
          text: "Foo",
          color: "blue",
        },
        {
          text: "Bar",
          color: "red",
        },
      ],

      search: "",
      dialog: false,
      dialogDelete: false,
      dialogError: false,
      dialogErrorMessage: "",
      editedItem: {
        access: [],
        card_number: 0,
        created_at: "",
        from: "0",
        max_use_limit: 0,
        to: "0",
        updated_at: "",
        username: "userName",
        _id: "",
      },
      headers: [
        {
          textKey: "accesses.access_to",
          text: this.$t("accesses.access_to"),
          align: "center",
          value: "item.name",
          class: "grey lighten-2",
        },
        {
          textKey: "accesses.access_type",
          text: this.$t("accesses.access_type"),
          align: "center",
          value: "access_type",
          class: "grey lighten-2",
        },
        {
          textKey: "accesses.allow",
          text: this.$t("accesses.allow"),
          align: "center",
          value: "allow",
          class: "grey lighten-2",
        },
        {
          text: "",
          align: "center",
          value: "actions",
          class: "grey lighten-2",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getAllDevices", "getAllInstallations", "getSelectedCard"]),

    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },
  },
  created() {},
  watch: {
    dialog(to) {
      this.readData();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let newArray = [];
      for (let tmp of this.card.access) {
        newArray.push(Object.assign({}, tmp));
      }
      this.card.access = newArray;
      this.dialog = false;
    },

    filterDevices(types) {
      let newDevices = [];
      for (const device of this.getAllDevices) {
        if (types.includes(device.type)) {
          newDevices.push(device);
        }
      }
      return newDevices;
    },

    deleteItem(item) {
      const index = this.card.access.findIndex(
        (t) => t.access_to_id === item.access_to_id
      );
      this.card.access.splice(index, 1);
      this.save();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onClickItem(item) {
    },

    comboSelect() {
      window.console.log("combo select");
    },

    addAccessItem(item) {
      if (Object.keys(this.selectDevice).length !== 0) {
        if (!this.existsDevice()) {
          window.console.log("davam device");
          window.console.log(this.selectDevice);
          this.card.access.push({
            access_to_id: this.selectDevice._id,
            access_type: "device",
            allow: this.allow,
            item: this.selectDevice,
          });
        } else {
          this.dialogError = true;
          this.dialogErrorMessage = this.$t("errors.cards_record_exists");
          //zobrazit dialog s chybou
        }
      } else if (Object.keys(this.selectInstallation).length !== 0) {
        if (!this.existsHUB()) {
          window.console.log("davam instalaci");
          window.console.log(this.selectInstallation);
          this.card.access.push({
            access_to_id: this.selectInstallation._id,
            access_type: "installation",
            allow: this.allow,
            item: this.selectInstallation,
          });
        } else {
          this.dialogError = true;
          this.dialogErrorMessage = this.$t("errors.cards_record_exists");
          //zobrazit dialog s chybou
        }
      } else {
        this.dialogError = true;
        this.dialogErrorMessage = this.$t("errors.cards_empty_record");
      }
      window.console.log("vypis");
      window.console.log(this.card.access);
      this.save();
    },

    errorDialogConfim() {
      this.dialogError = false;
    },

    existsHUB() {
      return (
        this.card.access.find((t) => t.access_to_id === this.selectInstallation._id) !==
        undefined
      );
    },

    existsDevice() {
      return (
        this.card.access.find(
          (t) => t.access_to_id === this.selectDevice._id
        ) !== undefined
      );
    },

    comboselectInstallation() {
      this.selectDevice = [];
    },

    comboSelectDevice() {
      this.selectInstallation = [];
    },
    findItem(anItem) {
      if (anItem !== undefined) {
        if (anItem.access_type === "installation") {
          const item = this.getAllInstallations.find(
            (t) => t._id === anItem.access_to_id
          );
          //window.console.log("polozka");
          //window.console.log(item);
          if (item !== undefined) {
            return item;
          } else {
            window.console.log("neni");
            //return { name: "" };
            return {};
          }
        } else {
          const item = this.getAllDevices.find(
            (t) => t._id === anItem.access_to_id
          )
          if (item !== undefined) {
            return item;
          } else {
            return {};
          }
        }
      } else {
        return {};
      }
    },
  },
};
</script>