<template>
  <v-snackbar
      v-model="snackbar"
      :color="color">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          :color="closeColor"
          text
          v-bind="attrs"
          @click="snackbar = false">
        {{ $t("snackbar.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  props: ["value", "text", "type"],
  data() {
    return {
      snackbar: this.value
    }
  },
  computed: {
    color() {
      switch (this.type) {
        case "error":
          return "red"
        case "success":
          return "grey darken-3"
        default:
          return "grey darken-3"
      }
    },
    closeColor() {
      switch (this.type) {
        case "error":
          return "white"
        case "success":
          return "white"
        default:
          return "pink"
      }
    }
  },
  watch: {
    value() {
      this.snackbar = this.value
    },
    snackbar() {
      if (this.snackbar === false)
        this.$emit("closed")
    }
  }
}
</script>

<style scoped>

</style>