import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Welcome from '@/views/main/Dashboard.vue'
import Cards from '@/views/main/Cards.vue'
import Installations from '@/views/main/Installations.vue'
import FinancialReports from '@/views/main/FinancialReports.vue'
import Devices from "@/views/main/Devices";
import Notifications from "@/views/main/Notifications";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    components: { appView: Main },
    props: true,
    children: [
      {
        path: '/',
        name: 'WelcomeRoot',
        components: { mainView: Welcome },
        props: true
      },
      {
        path: '/Main',
        name: 'WelcomeRootMain',
        components: { mainView: Welcome },
        props: true
      },
      {
        path: '/Main/Welcome',
        name: 'Welcome',
        components: { mainView: Welcome },
        props: true
      },
      {
        path: '/Main/Cards/:selectedCard',
        name: 'Cards',
        components: { mainView: Cards },
        props: true
      },
      {
        path: '/Main/Installations/:selectedInstallation/Device/:selectedDevice',
        name: 'Installations',
        components: { mainView: Installations },
        props: true
      },
      {
        path: '/Main/FinancialReports',
        name: 'FinancialReports',
        components: { mainView: FinancialReports },
        props: true
      },
      {
        path: '/Main/Devices/:selectedDevice',
        name: 'Devices',
        components: { mainView: Devices },
        props: true
      },
      {
        path: '/Main/Notifications',
        name: 'Notifications',
        components: { mainView: Notifications },
        props: true
      },
    ],
  }
]

const router = new VueRouter({
  routes
})

export default router
