<template>
  <div>
    <EditableTextParameter
        v-for="param in textParams"
        :device_id="device._id"
        :key="param.name"
        class="no-gutters"
        :param="param"
        device_type="gp4bc"
    />
    <g-p4-b-c_operation-state
        :state="state"
        :name="'setOperationState'"
        :device_id="device._id"
        :device_type="'gp4bc'"
        :items="stateItems"
    />
  </div>
</template>

<script>
import EnumParameter from "@/components/main/devices/control/parameters/EnumParameter";
import GP4BC_operationState from "@/components/main/devices/control/parameters/GP4BC/GP4BC_operationState";
import EditableTextParameter from "@/components/main/devices/control/parameters/EditableTextParameter";

export default {
  name: "GP4BC_parameters",
  components: {EditableTextParameter, GP4BC_operationState, EnumParameter},
  props: ["device"],
  data() {
    return {
      stateItems: [
        {
          text: this.$t("device.parameters.gp4bc.state_permanent_open"),
          value: 1
        }, {
          text: this.$t("device.parameters.gp4bc.state_normal"),
          value: 0
        },
      ],
      textParameterNames: ["time_after_departure", "time_before_arrival", "number_of_bollards"]
    }
  },
  computed: {
    state() {
      if(!this.device.status_details || !this.device.status_details.out_of_order || this.device.status_details.out_of_order === false)
        return 0
      return 1
    },
    textParams() {
      if(this.device.parameters == null || this.device.parameters.size < 1)
        return []
      return this.device.parameters
          .filter(param => this.textParameterNames.includes(param.name))
          .map (param => {
            param.read_only = true;
            return param
          })
    }
  }
}
</script>

<style scoped>

</style>