var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{attrs:{"label":_vm.$t('device.device_type'),"multiple":"","dense":"","items":[
    {
      value: 'turnstile',
      text: _vm.$t('device.device_type_turnstile'),
    },
    {
      value: 'cash_register',
      text: _vm.$t('device.device_type_cash_register'),
    },
    {
      value: 'cash_register_with_turnstile',
      text: _vm.$t('device.device_type_cash_register_with_turnstile'),
    },
    {
      value: 'device_hub',
      text: _vm.$t('device.device_type_device_hub'),
    },
    {
      value: 'paybox',
      text: _vm.$t('device.device_type_paybox'),
    },
    {
      value: 'gp4bc',
      text: _vm.$t('device.device_type_gp4bc'),
    },
    {
      value: 'kiosk',
      text: _vm.$t('device.device_type_kiosk'),
    },
    /*{
                                value: 'barrier',
                                text: $t('device.device_type_barrier'),
                              },*/
  ]},on:{"input":function($event){return _vm.select()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(_vm.selected.length === 1)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" ("+_vm._s(_vm.selected.length)+") ")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }