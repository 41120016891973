<template>
  <div>
    <v-chip
        v-for="state in getStatusText(device)"
        :key="state"
        :class="`ma-1 ` + getColorConnected(device)"
        label>
      {{ state }}
    </v-chip>
  </div>
</template>

<script>
import Config from "@/config/config";

export default {
  name: "DeviceStatusChips",
  props: ["device"],
  methods: {
    getColorConnected(item) {
      if(item.type === "kiosk")
        item.status = Object.values(item.status_details).filter(detail => detail.status_info === 'error').length < 1

      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.hasOwnProperty("status") && item.status === false) {
            return "orange lighten-3";
          } else {
            return "green";
          }
        } else {
          return "red";
        }
      } else {
        return "grey";
      }
    },

    getStatusText(item) {
      switch (this.device.type) {
        case 'kiosk':
          return this.getStatusTextKiosk(item)
        default:
          return this.getStatusTextDefault(item)
      }
    },

    getStatusTextKiosk(item) {
      if (!item.is_connected)
        return [this.$t("device.device_disconnected")]

      return Object.values(item.status_details)
          .filter(detail => detail.status_info === 'error' && detail.status_type)
          .map(detail =>  this.$t("device.device_" + detail.status_type))
    },

    getStatusTextDefault(item) {
      if (item.hasOwnProperty("is_connected")) {
        if (item.is_connected === true) {
          if (item.hasOwnProperty("status") && item.status === false) {
            let status = [];
            if (item.hasOwnProperty("status_details")) {
              for (const key of Object.keys(item.status_details)) {
                if (!Config.hide_states.includes(key)) {
                  const detail = item.status_details[key];
                  if (detail.hasOwnProperty("bit_value") && detail.bit_value === 1) {
                    if (detail.hasOwnProperty("status_type") && detail.hasOwnProperty("status_info")) {
                      status.push(
                          this.$t("device.device_" + detail.status_type.toLowerCase())
                      );
                    }
                  }
                }
              }
            }
            return status;
          } else {
            return [this.$t("device.device_connected")];
          }
        } else {
          return [this.$t("device.device_disconnected")];
        }
      } else {
        return [this.$t("device.device_none")];
      }
    }
  }
}
</script>

<style scoped>

</style>