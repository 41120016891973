<template>
  <v-row>
    <v-col cols="12" class="pr-6 pb-0 pl-1">
      <div class="pa-2">
        <v-card-title class="">
          <v-row>
            <v-col cols="6" class="pt-0 pl-0"
            >{{ $t("reports.reports") }}
            </v-col>
            <v-col class="pa-0"
            >
              <v-tooltip left max-width="400" close-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="green lighten-1"
                      class="float-right pr-3"
                  >
                    <v-icon right medium
                    >mdi-information-variant
                    </v-icon
                    >
                  </v-btn
                  >
                </template>
                <div
                    class="pa-0"
                    v-html="$t('reports.info_reports')"
                ></div>
              </v-tooltip
              >
            </v-col>
          </v-row>
        </v-card-title>
        <!--<v-row class="pt-0 pb-0">
              <v-col class="pt-0 pb-0">Od/do nacti data</v-col>
            </v-row>-->
        <v-row class="pt-4"
        >
          <v-col cols="12" class="pa-0 pl-3">
            <v-row
            >
              <v-col cols="3" class="pt-1 pb-1"
              >
                <v-btn block color="primary" @click="readAll">{{
                    $t("reports.reports_all_btn")
                  }}
                </v-btn>
              </v-col
              >
            </v-row>
            <v-row
            >
              <v-col cols="3" class="pt-2 pb-1"
              >
                <v-btn block color="primary" @click="readDeviceFromTo">{{
                    $t("reports.reports_device_interval_btn")
                  }}
                </v-btn>
              </v-col
              >
              <v-col cols="6" class="pt-2 pb-1"
              >
                <v-select
                    v-model="selectDevice"
                    :hint="`${selectDevice.name}, ${selectDevice._id}`"
                    :items="filterDevices(['cash_register', 'paybox', 'cash_register_with_turnstile'])"
                    item-value="_id"
                    item-text="name"
                    :label="$t('accesses.device')"
                    persistent-hint
                    return-object
                    hide-details
                    outlined
                    dense
                    class="pa-0"
                >
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col cols="6" class="pa-0 pl-6 pt-1">
                        {{ item.name }}
                      </v-col>
                      <v-col cols="1" class="pa-0"></v-col>
                      <v-col cols="5" class="pa-0 pt-1">
                        <span
                            class="caption font-weight-medium text--disabled"
                        >{{ item._id }}</span
                        >
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:selection="{ item }">
                    <v-row>
                      <v-col cols="6" class="pa-0 pl-6 pt-1">
                        {{ item.name }}
                      </v-col>
                      <v-col cols="1" class="pa-0"></v-col>
                      <v-col cols="5" class="pa-0 pt-1">
                        <span
                            class="caption font-weight-medium text--disabled"
                        >{{ item._id }}</span
                        >
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col
              >
            </v-row
            >
            <v-row>
              <v-col cols="3" class="pt-1 pb-0"
              >
                <v-btn block color="primary" @click="readExportDeviceFromTo">{{
                    $t("reports.reports_export_btn")
                  }}
                </v-btn>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-menu
                    v-model="menuDateFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFrom"
                        :label="$t('reports.date_from')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateFrom"
                      @input="menuDateFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-menu
                    ref="menuFrom"
                    v-model="menuTimeFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="timeFrom"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="timeFrom"
                        :label="$t('reports.time_from')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="menuTimeFrom"
                      v-model="timeFrom"
                      format="24hr"
                      full-width
                      @click:minute="$refs.menuFrom.save(timeFrom)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="2" class="pb-0">
                <v-menu
                    v-model="menuDateTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateTo"
                        :label="$t('reports.date_to')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateTo"
                      @input="menuDateTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-menu
                    ref="menuTo"
                    v-model="menuTimeTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="timeTo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="timeTo"
                        :label="$t('reports.time_to')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="menuTimeTo"
                      v-model="timeTo"
                      format="24hr"
                      full-width
                      @click:minute="$refs.menuTo.save(timeTo)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col
          >
        </v-row>
        <v-row>
          <span class="pl-10 pt-2 caption">{{ reportsTitle }}</span>
        </v-row>
        <v-row>
          <v-col class="pb-4 pt-0">
            <v-data-table
                dense
                outlined
                :headers="headersLocalized"
                :items="transformData"
                :search="search"
                class="elevation-0 row-pointer pt-0 pb-0"
                @click:row="clickOnReportItem"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="_id"
                show-expand
                :footer-props="{
                itemsPerPageText: $t('general.table_rows_per_page_text'),
                itemsPerPageAllText: $t('general.table_rows_per_page_all_text'),
                pageText: pageText,
              }"
                @pagination="paginate"
                :height="windowSize.y - 460"
                :item-class="itemRowBackground"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('general.search')"
                      single-line
                      hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="green lighten-5">
                  <ReportDetail :report="item"></ReportDetail>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ReportDetail from "@/components/main/financialReports/FinancialReportDetail";
import Config from "@/config/config";
import Provider from "@/api/provider";
import format from "date-fns/format";
import util from "util";
import moment from "moment";

export default {
  name: "",
  components: {ReportDetail},
  computed: {
    ...mapGetters(["getAllDevices", "getAllInstallations"]),
    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },

    transformData() {
      if (this.reports.length > 0) {
        var retval = [...this.reports];

        for (let report of retval) {
          let m = moment(report.timestamp);
          let device = this.getAllDevices.find(
              (element) => element._id === report.device_id
          );
          let installation = undefined;
          if (device !== undefined) {
            installation = this.getAllInstallations.find(
                (element) => element._id === device.installation_id
            );
          }
          report.timestamp_transformed = m.format("DD.MM.YYYY hh:mm");
          report.msg_event_transformed = this.$t(
              "reports.report_item_event_" + report.msg_event
          );
          if (installation !== undefined) {
            report.device_id_transformed = installation.name;
          }
          if (device !== undefined) {
            report.device_id_transformed += " / " + device.name;
          }
        }
        return retval;
      } else {
        return this.reports;
      }
    },
  },
  methods: {
    ...mapActions(["setAllDevices", "setError"]),

    itemRowBackground(item) {
      return "grey lighten-4";
    },

    readAll() {
      Provider.getData(Config.endpointFinance, {})
          .then((response) => {
            //this.reports = this.transformData(response);
            this.reports = response;
          })
          .catch((error) => {
            //error
          });
      this.reportsTitle = this.$t("reports.reports_all_label");
    },

    readDeviceFromTo() {
      if (
          this.selectDevice.hasOwnProperty("_id") &&
          this.selectDevice._id !== ""
      ) {
        let from = this.dateFrom + "T" + this.timeFrom + ":00.000Z";
        let to = this.dateTo + "T" + this.timeTo + ":00.000Z";
        let device_id = this.selectDevice._id;
        Provider.getData(
            util.format(Config.endpointFinanceIntervalDeviceVar, from, to, device_id),
            {}
        )
            .then((response) => {
              if (response === "Test body, OK") {
                this.reports = [];
              } else {
                this.reports = response;
              }
            })
            .catch((error) => {
              //error
            });
        this.reportsTitle =
            this.$t("reports.reports_device_interval_label") + 
            "  " + this.selectDevice.name + " " + from + " - " + to;
      } else {
        this.setError(this.$t("errors.error_financial_report_device"));
      }
    },

    readExportDeviceFromTo() {
        try {
            if (this.selectDevice.hasOwnProperty("_id") && this.selectDevice._id !== "") {
                const from = new Date(this.dateFrom + " " + this.timeFrom)
                const to = new Date(this.dateTo + " " + this.timeTo)
                let language = this.$t("reports.reports_lang");

                const iso_format = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                Provider.getData(
                    util.format(Config.endpointFinanceExport, language , format(from, iso_format), format(to, iso_format), this.selectDevice._id),
                    { responseType: 'blob' })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response],
                            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                        const link = document.createElement('a');
                        link.href = url;
                        const name = this.selectDevice.name + '_' + this.dateFrom.toLocaleString() + '_' + this.dateTo.toLocaleString() +'.xlsx'
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                    //error
                        console.log(error);
                    });
                this.reportsTitle =
                    this.$t("reports.reports_export_label") + 
                    "  " + this.selectDevice.name + " " + language + " " + from + " - " + to;
            } else {
                this.setError(this.$t("errors.error_financial_report_device"));
            }
        } catch (e) {
            this.setError(this.$t("errors.error_financial_report_export"));
        }
    },

    filterDevices(types) {
      const newDevices = this.getAllDevices.filter(device => types.includes(device.type))
      if (newDevices.length === 0) {
        newDevices.push({name: "", _id: ""});
      }
      return newDevices;
    },

    paginate(page) {
      this.pageText = Config.generatePaginationOfText(
          page,
          this.$t("general.pagination_page_of")
      );
    },

    clickOnReportItem(item) {
      this.selectedReport = item;
      let index = this.expanded.findIndex((t) => t._id === item._id);
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },

    initialize() {
      Provider.getData(Config.endpointInstallationList, {})
          .then((response) => {
            this.setAllInstallations(response);
          })
          .catch((error) => {
            //error
          });
      Provider.getData(Config.endpointDevice, {})
          .then((response) => {
            window.console.log(response);
            this.setAllDevices(response);
          })
          .catch((error) => {
            //error
          });

      Provider.getData(Config.endpointFinance, {})
          .then((response) => {
          })
          .catch((error) => {
            //error
          });
    },
  },
  mounted() {
    //this.windowSize = {x: window.innerWidth, y: window.innerHeight};
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      reportsTitle: " ",
      selectDevice: {name: "", _id: ""},
      dateFrom: new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
          .toISOString()
          .substr(0, 10),
      //dateFrom: new Date().toISOString().substr(0, 10),
      menuDateFrom: false,
      menuTimeFrom: false,
      timeFrom: "00:00",

      dateTo: new Date().toISOString().substr(0, 10),
      timeTo: "23:59",
      menuTimeTo: false,
      menuDateTo: false,
      pageText: "",
      expanded: [],
      singleExpand: false,
      hideReportProps: ["_id", "data"],
      hideReportDataProps: ["msg_id", "time_mark", "money_records"],
      search: "",
      selectedReport: {
        data: {
          money_records: [],
        },
      },
      headers: [
        {
          textKey: "reports.th_timestamp",
          text: this.$t("reports.th_timestamp"),
          align: "center",
          value: "timestamp_transformed",
          class: "grey lighten-2",
        },
        {
          textKey: "reports.th_device_id_transformed",
          text: this.$t("reports.th_device_id_transformed"),
          align: "center",
          value: "device_id_transformed",
          class: "grey lighten-2",
        },
        /*{
          text: "msg_type",
          align: "center",
          value: "msg_type",
          class: "grey lighten-2",
        },*/
        /*{
          text: "data.evt",
          align: "center",
          value: "data.evt",
          class: "grey lighten-2",
        },*/
        /*{
          text: this.$t("reports.th_device_type"),
          align: "center",
          value: "device_type",
          class: "grey lighten-2",
        },
        {
          text: this.$t("reports.th_hap"),
          align: "center",
          value: "hap",
          class: "grey lighten-2",
        },*/
        {
          textKey: "reports.th_card_number",
          text: this.$t("reports.th_card_number"),
          align: "center",
          value: "card_number",
          class: "grey lighten-2",
        },

        {
          textKey: "reports.th_msg_event",
          text: this.$t("reports.th_msg_event"),
          align: "center",
          value: "msg_event_transformed",
          class: "grey lighten-2",
        },

        {
          textKey: "reports.th_withdrawal_number",
          text: this.$t("reports.th_withdrawal_number"),
          align: "center",
          value: "withdrawal_number",
          class: "grey lighten-2",
        },
        /*{
          text: this.$t("reports.th_withdrawal_year"),
          align: "center",
          value: "withdrawal_year",
          class: "grey lighten-2",
        },
        {
          text: this.$t("reports.th_station_id"),
          align: "center",
          value: "station_id",
          class: "grey lighten-2",
        },*/
        /*{
          text: "card payments",
          align: "center",
          value: "data.number_of_payments_by_card",
          class: "grey lighten-2",
        },
        {
          text: "payment_card_total",
          align: "center",
          value: "data.payment_card_total",
          class: "grey lighten-2",
        },*/
        /*{
          text: "hopper_diff",
          align: "center",
          value: "data.hopper_difference",
          class: "grey lighten-2",
        },
        {
          text: "recycler_diff",
          align: "center",
          value: "data.recycler_difference",
          class: "grey lighten-2",
        },*/

        {
          textKey: "",
          text: "",
          align: "center",
          value: "data-table-expand",
          class: "grey lighten-2",
        },
      ],
      reports: [],
    };
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>