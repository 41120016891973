<template>
  <v-card>
    <v-card-title>
      {{ $t("dashboard.device_summary_title") }} ({{ $t("dashboard.device_summary_count") + ' ' + deviceCount }})
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title v-text="$t('dashboard.device_summary_online')"/>
          <v-list-item-action class="font-weight-bold" :class="{ 'green--text': onlineCount > 0 }">
              {{ onlineCount }}
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-title v-text="$t('dashboard.device_summary_offline')"/>
          <v-list-item-action class="font-weight-bold"
                              :class="{ 'green--text': offlineCount <= 0,  'red--text': offlineCount >= 0}">
            {{ offlineCount }}
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-title v-text="$t('dashboard.device_summary_warning')"/>
          <v-list-item-action  class="font-weight-bold"
                               :class="{ 'green--text': warningCount <= 0,  'orange--text': warningCount >= 0}">
            {{ warningCount }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AllDevicesSummary",
  computed: {
    ...mapGetters(["getAllDevices"]),

    deviceCount() {
      return Array.isArray(this.getAllDevices) ? this.getAllDevices.length : 0
    },

    onlineCount() {
      return Array.isArray(this.getAllDevices) ? this.getAllDevices.filter(device => device.is_connected).length : 0
    },

    offlineCount() {
      return Array.isArray(this.getAllDevices) ? this.getAllDevices.filter(device => !device.is_connected).length : 0
    },

    warningCount() {
      return Array.isArray(this.getAllDevices) ? this.getAllDevices.filter(device => !device.status && device.is_connected).length : 0
    },
  }
}
</script>

<style scoped>
</style>