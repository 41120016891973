<template>
  <v-row class="pa-0 pb-0 pt-0">
    <v-col class="col-md-7 pb-0 pl-1" v-if="$vuetify.breakpoint.mdAndUp || getSelectedCard == null">
      <ListOfCards />
    </v-col>
    <v-col class="col-md-5" v-if="getSelectedCard != null">
      <v-row>
        <v-col class="pa-0 pt-3 pr-5">
          <CardDetail />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 pr-0 pt-0">
          <CardPasses />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ListOfCards from "@/components/main/cards/ListOfCards";
import CardPasses from "@/components/main/cards/CardPasses";
import CardDetail from "@/components/main/cards/CardDetail";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "",
  components: { ListOfCards, CardPasses, CardDetail },
  data() {
    return {};
  },
  methods: {
    do() {
      window.console.log("");
    },
  },
  computed: mapGetters([
    "getSelectedCard",
    "getAllCards",
    "getAllInstallations",
    "getAllDevices",
  ]),
};
</script>
