const state = {
    coin_names: {
        EU005A: "0.05 EUR",
        EU010A: "0.1 EUR",
        EU020A: "0.2 EUR",
        EU050A: "0.5 EUR",
        EU100A: "1 EUR",
        EU200A: "2 EUR",
        CZ100A: "1 CZK",
        CZ200A: "2 CZK",
        CZ500A: "5 CZK",
        CZ1K0A: "10 CZK",
        CZ2K0A: "20 CZK",
        CZ5K0A: "50 CZK",
    }
}

const getters = {
    getCoinNameForCode: (state) => (code) => {
        return state.coin_names[code]
    }
}

export default {
    state,
    getters,
}