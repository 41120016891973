<template>
    
        <v-col cols="12" v-if="innerValue !== undefined" class="pa-0 pt-0">
            <v-card class="pa-6 pl-4 elevation-0" outlined>
            <v-row>
                <v-col class="text-h4">{{title}}</v-col>
            </v-row>
            <v-row>
                <v-col cols="2" class="font-weight-bold">
                    {{ $t("notifications.filterType") }}
                </v-col>
                <v-col cols="4" class="pa-0">
                    <v-select v-model="innerValue.filterType"
                        @change="innerValue.filterValues = []"
                        :items="getFilterTypes()"
                        item-value="name" item-text="value"
                        menu-props="offset-y"></v-select>
                </v-col>
                <v-col class="pl-8 font-weight-bold" cols="2">{{ $t("notifications.filterValues") }}</v-col>
                <v-col cols="4" class="pa-0">
                    <v-select
                         :editable="false"
                        v-model="innerValue.filterValues"
                        :items="getDataForFilterType(innerValue.filterType)"
                        hide-selected
                        item-value="_id"
                        :label="$t('notifications.chooseValues')"
                        multiple
                        :clearable="true"
                        clear-icon="$clear"
                        menu-props="offset-y"
                    >

                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-bind="attrs"
                                :color="`${item.color} lighten-3`"
                                :input-value="selected"
                                label
                            >
                            <span class="pr-2">
                                {{ getValueOfFilterType(innerValue.filterType, item) }}
                            </span>
                            <v-icon
                                small
                                @click="parent.selectItem(item)"
                            >
                                $delete
                            </v-icon>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ index, item }">
                            {{ getValueOfFilterType(innerValue.filterType, item) }}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2" class="font-weight-bold">
                    {{ $t("notifications.channel") }}
                </v-col>
                <v-col cols="4" class="pa-0">
                    <v-text-field v-model="innerValue.channel" dense disabled>
                    </v-text-field>
                </v-col>
                <v-col class="pl-8 font-weight-bold" cols="2">{{ $t("notifications.recipient") }}</v-col>
                <v-col cols="4" class="pa-0">
                    <v-text-field v-model="innerValue.recipient" dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col><v-btn @click="$emit('close')" block color="primary">{{$t('general.cancel')}}</v-btn></v-col>
                <v-col v-if="deleteBtn"><v-btn @click="deleteNotification" block color="primary">{{$t('notifications.deleteBtn')}}</v-btn></v-col>
                <v-col><v-btn @click="saveNotification" block color="primary">{{$t('general.save')}}</v-btn></v-col>
            </v-row>
            </v-card>
        </v-col>
    
</template>
<script>
import {mapGetters} from "vuex"

export default {
    props: {
        value: Object,
        deleteBtn: true,
        title: ""
    },

    computed: {
        ...mapGetters(["getAllDevices","getAllInstallations"]),
        innerValue() {
            return this.value
        },


    },

    data: () => ({
      
    }),
    
    methods: {
        getGroups() {
            return JSON.parse(localStorage["keycloak.groups"])
        },

        getDataForFilterType(type) {
            if (type === 'group') {
                return this.getGroups();
            } else if (type === 'installation') {
                return this.getInstallations();
            } else if (type === 'device') {
                return this.getAllDevices;
            }
        },

        getValueOfFilterType(type,item) {
            if (type === 'group') {
                return item;
            } else if (type === 'installation') {
                return item.name;
            } else if (type === 'device') {
                return item.name;
            }
        },

        getFilterTypes() {
            return [
                {name:'group', value:this.$t('general.group')},
                {name:'device', value:this.$t('accesses.device')},
                {name:'installation', value:this.$t('accesses.hub')},]
        },

        getInstallations() {
            return this.getAllInstallations
        },


      filter (item, queryText, itemText) {
        if (queryText === null) return false
        if (typeof item !== 'string') {
            itemText = item.name
        }
        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      saveNotification() {
        this.$emit('saveNotification', this.innerValue)
      },

      deleteNotification() {
        this.$emit('deleteNotification', this.innerValue)
      },

    }
}
</script>
<style lang="">
    
</style>