<template>
  <div class="pl-3 pr-3">
    <v-card-title>
      <v-row>
        <v-col
          cols="8"
          class="pa-0">
          {{ $t("device.device_detail") }}
        </v-col>
        <v-col class="pa-0">
          <v-tooltip
            left
            max-width="400"
            close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="green lighten-1"
                class="float-right pr-3">
                <v-icon
                  right
                  medium>
                  mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <div
              class="pa-0"
              v-html="$t('device.info_device')">
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-row>
      <v-col
        cols="3"
        class="pt-4">
        ID
      </v-col>
      <v-col
        cols="9"
        class="caption pt-5 pl-0">
        {{ selectedDevice._id }}
      </v-col>
    </v-row>

    <v-row v-if="directConnectTypes.includes(selectedDevice.type)">
      <v-col cols="3" class="">{{ $t("device.hub_api_key") }}</v-col>
      <v-col cols="9" class="pa-0 pr-2">
        <v-text-field
            class="caption text-wrap ma-0"
            :value="selectedDevice.api_key"
            readonly
            :append-icon="'content_copy'"
            @click:append="copyToClipboard(selectedDevice.api_key)"
        />
        <!-- :append-outer-icon="'refresh'" @click:append-outer="refreshAPIkey" v be uz neni endpoint -->
      </v-col>
    </v-row>

    <v-row v-if="directConnectTypes.includes(selectedDevice.type)">
      <v-col cols="8" class="">{{ $t("device.device_factory_reset") }}</v-col>
      <v-col cols="4" class="pa-0 pr-2">
        <v-dialog
            v-model="dialog"
            max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                block
                v-bind="attrs"
                v-on="on">{{ $t("device.device_factory_reset_button") }}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              {{ $t("device.device_factory_reset_check_header") }}
            </v-card-title>
            <v-card-text>
              {{ $t("device.device_factory_reset_check_text") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false">
                {{ $t("general.cancel") }}
              </v-btn>
              <v-btn
                  color="red darken-1"
                  dark
                  @click="resetFactoryDefaults">
                {{ $t("device.device_factory_reset_button") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="5"
          class="">
        {{ $t("device.device_name") }}
      </v-col>
      <v-col
          cols="7"
          class="pa-0 pt-2">
        <v-text-field
            class="pa-0 pr-2 pt-0 ma-0"
            v-model="deviceName">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="!directConnectTypes.includes(selectedDevice.type)">
      <v-col
          cols="5"
          class="">
        {{ $t("device.device_hap") }}
      </v-col>
      <v-col
          cols="7"
          class="pa-0 pt-2">
        <v-text-field
            type="number"
            class="pa-0 pr-2 pt-0 ma-0"
            v-model="hap"
            :label="$t('device.device_hap')">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="!directConnectTypes.includes(selectedDevice.type)">
      <v-col cols="10" class="pa-0 pl-6 pr-2">
        <v-select
                  dense
                  outlined
                  hide-details
                  v-model="parent"
                  :label="$t('device.parent')"
                  item-text="name"
                  :items="filterDeviceHubs(devices)"
                  return-object
                  />
      </v-col>
      <v-col cols="2" class="pa-0 pl-2 pr-4">
        <v-btn
            block
            color="primary"
            v-on:click="deleteParent">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
      <!-- v-on:input="selectParent" -->
    </v-row>

    <v-row v-if="selectedDevice.type==='gp4bc'">
      <v-col cols="12">
        Sloupky
      </v-col>
      <ConfigGP4BC :device="selectedDevice" @change="bollardsChange"/>
    </v-row>

    <v-row class="mb-5">
    </v-row>

    <v-row>
      <v-col cols="10" class="pa-0 pl-6 pr-2">
        <v-select
                  dense
                  outlined
                  hide-details
                  v-model="infoText"
                  :label="$t('device.device_info_texts')"
                  :items="sortedUserTexts"
                  />
      </v-col>
      <v-col cols="2" class="pa-0 pl-2 pr-4">
        <v-btn
            block
            color="primary"
            v-on:click="deleteInfoText">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn
            block
            color="primary"
            v-on:click="updateDevice">
          <v-icon>save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-divider/>
    </v-row>


    <!--<v-row>
      <v-col class="pr-3">
        <v-btn block color="primary" v-on:click="dialogRestartClick">{{
            $t("device.device_restart")
          }}</v-btn>
        <v-dialog v-model="dialogRestart" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              {{ $t("device.device_restart_check") }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogRestartCancel">
                {{ $t("general.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="dialogRestartOK"
              >{{ $t("general.ok") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>-->

    <v-row>
      <v-col cols="6">
        {{ $t("device.device_type") }}
      </v-col>
      <v-col cols="6" style="text-align: right">
        <DeviceTypeIcon :type="selectedDevice.type"/>
      </v-col>
    </v-row>
    <v-divider style="margin-top: 0.5em; margin-bottom: 0.5em"></v-divider>

    <v-row>
      <v-col cols="6">
        IP
      </v-col>
      <v-col cols="6" style="text-align: right">
        {{ selectedDevice.ip }}
      </v-col>
    </v-row>
    <v-divider style="margin-top: 0.5em; margin-bottom: 0.5em"></v-divider>

    <v-row>
      <v-col cols="6">
        {{ $t("device.device_sw_version") }}
      </v-col>
      <v-col cols="6" style="text-align: right">
        {{ selectedDevice.fw_version }}
      </v-col>
    </v-row>
    <v-divider style="margin-top: 0.5em; margin-bottom: 0.5em"></v-divider>

    <v-row>
      <v-col cols="6">
        {{ $t("device.device_last_contact") }}
      </v-col>
      <v-col cols="6" style="text-align: right">
        {{ (new Date(Date.parse(selectedDevice.last_communication))).toLocaleString(this.$i18n.locale) }}
      </v-col>
    </v-row>
    <v-divider style="margin-top: 0.5em; margin-bottom: 0.5em"></v-divider>

    <v-row>
      <v-col cols="3">
        <div class="pa-2 pl-4">
          <DeviceStatusChips :device="selectedDevice"/>
        </div>
      </v-col>
    </v-row>
    <v-divider style="margin-top: 0.5em; margin-bottom: 0.5em"></v-divider>

    <Snackbar :value="snackbar" :text="snackbar_text" :type="snackbar_type" @closed="snackbar = false" />
  </div>
</template>

<script>
import Config from "@/config/config";
import Provider from "@/api/provider";
import Snackbar from "@/components/main/Snackbar"

const util = require("util");
import { mapGetters, mapActions } from "vuex";
import DeviceStatusChips from "@/components/main/devices/DeviceStatusChips";
import DeviceTypeIcon from "@/components/main/devices/DeviceTypeIcon";
import ConfigGP4BC from "@/components/main/devices/management/deviceSpecific/ConfigGP4BC";

export default {
  name: "DeviceDetailEdit",
  components: {ConfigGP4BC, DeviceTypeIcon, DeviceStatusChips, Snackbar },
  props: ["selectedDevice", "devices"],

  data() {
    return {
      dialogRestart: false,
      deviceName: "",
      group: "",
      hap: "",
      bollards: [],
      parent: {},
      parentName: "",
      directConnectTypes: ["device_hub", "paybox", "kiosk"],
      snackbar: false,
      snackbar_text: "",
      snackbar_type: "",
      infoText: "",
      dialog: false,
    };
  },

  computed: {
  ...mapGetters(["getAllUserTexts"]),
  sortedUserTexts() {
      return this.getAllUserTexts.sort((a, b) => a.text.localeCompare(b.text))
    },
  },

  watch: {
    selectedDevice(to, from) {
      this.hap = to.hap
      this.deviceName = to.name;
      this.group = to.group;
      this.bollards = to.bollards
      this.infoText = to.infoText;
      this.parent = this.devices.filter(device => device._id === to.parent_id);
      if (this.parent.length === 1) {
        this.parent = this.parent[0].name; 
      } else {
         this.parent = {};
      }
    },
  },
  mounted() {
    this.deviceName = this.selectedDevice.name;
    this.group = this.selectedDevice.group;
    this.hap = this.selectedDevice.hap;
    this.bollards = this.selectedDevice.bollards;
    this.infoText = this.selectedDevice.infoText;
    this.parent = this.devices.filter(device => device._id === this.selectedDevice.parent_id);
    if (this.parent.length === 1) {
        this.parent = this.parent[0].name; 
      } else {
         this.parent = {};
      }
  },
  methods: {
    ...mapActions(["udpateDeviceInSelectedHUB", "updateHUBinAllList"]),

    getGroups() {
      return JSON.parse(localStorage["keycloak.groups"]);
    },

    do() {
      window.console.log("do");
    },

    filterDeviceHubs(devices) {
      let parents = [];
      parents = devices.filter(device => device.type === 'device_hub');
      return parents;
    },

    deleteParent() {
      this.parent = undefined
      this.selectedDevice.parent_id = ""
      this.updateDevice()
    },

    deleteInfoText() {
      this.infoText = undefined
      this.selectedDevice.infoText = ""
      this.updateDevice()
    },

    updateDevice() {
      const tmpName = this.selectedDevice.name;
      const tmpGroup = this.selectedDevice.group;
      const tmpBollards = this.selectedDevice.bollards;
      const tmpHap = this.selectedDevice.hap;
      const tmpParent = this.selectedDevice.parent ? this.selectedDevice.parent._id : ""
      const tmpInfoText = this.selectedDevice.infoText;

      this.selectedDevice.name = this.deviceName;
      this.selectedDevice.group = this.group;
      this.selectedDevice.bollards = this.bollards;
      this.selectedDevice.hap = this.hap;
      this.selectedDevice.parent_id = this.parent ? this.parent._id : "";
      this.selectedDevice.infoText = this.infoText ? this.infoText : "";

      Provider.putData(
        util.format(Config.endpointDevicePathVar, this.selectedDevice._id),
        this.selectedDevice,
        {}
      )
        .then((response) => {
          this.$emit("parentChanged", this.selectedDevice);
          this.snackbar_type = "success"
          this.snackbar_text = this.$t("device.device_rename_success")
          this.snackbar = true
        })
        .catch((error) => {
          //chyba
          this.selectedDevice.name = tmpName;
          this.selectedDevice.group = tmpGroup;
          this.selectedDevice.hap = tmpHap;
          this.selectedDevice.bollards = tmpBollards;
          this.selectedDevice.name = tmpName;
          this.selectedDevice.group = tmpGroup;
          this.selectedDevice.parent_id = tmpParent
          this.selectedDevice.infoText = tmpInfoText;

          this.snackbar_type = "error"
          this.snackbar_text = this.$t("device.device_rename_error")
          this.snackbar = true
        });
    },

    dialogRestartOK() {
      Provider.putData(
        util.format(Config.endpointDeviceRestart, this.selectedDevice._id),
        this.selectedDevice,
        {}
      )
        .then((response) => {
          //dialog restart was sent
          this.dialogRestart = false;
        })
        .catch((error) => {
          //chyba
          window.console.log(error.message);
          window.console.log(error.response);
        });
    },

    dialogRestartClick() {
      this.dialogRestart = true;
    },
    dialogRestartCancel() {
      this.dialogRestart = false;
    },

    refreshAPIkey() {
      Provider.putData(
          util.format(
              Config.endpointDeviceResetApiKey,
              this.selectedDevice._id
          ),
          {}
      )
          .then((response) => {
            this.selectedDevice.api_key = response.api_key;
          })
          .catch((error) => {
            //chyba
          });
    },

    bollardsChange(bollards) {
      this.bollards = bollards
    },

    resetFactoryDefaults() {
      this.dialog = false
      Provider.putData(
          util.format(
              Config.endpointDeviceFactoryReset,
              this.selectedDevice._id
          ),
          {}
      )
          .then((response) => {
            this.snackbar_type = "success"
            this.snackbar_text = this.$t("device.device_factory_reset_success")
            this.snackbar = true
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = this.$t("device.device_factory_reset_error")
            this.snackbar = true
          });
    },

    async copyToClipboard(key) {
      try {
        await navigator.clipboard.writeText(key);
        this.snackbar_type = "success"
        this.snackbar_text = this.$t("general.copied_to_clipboard")
        this.snackbar = true
      } catch(e) {
        this.snackbar_type = "error"
        this.snackbar_text = e.message
        this.snackbar = true
      }
    },
  },
};
</script>
