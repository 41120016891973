<template>
  <div class="pa-2 mt-5 mb-5">
    <v-card-title>{{ $t("report_detail.detail") }}</v-card-title>
    <v-row>
      <v-col cols="12" class="">
        <v-card>
          <v-row>
            <v-col
              class="pt-0 pb-0 pl-4"
              cols="6"
              v-bind:key="objectKey"
              v-for="objectKey in getObjectPopsToShow(report, hideReportProps)"
            >
              <ReportItem
                :label="objectKey"
                :value="report[objectKey]"
              ></ReportItem>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          dense
          outlined
          :headers="headersLocalized"
          :items="report.money_records"
          class="elevation-0"
          hide-default-footer
          :items-per-page="100"
        >
          <template v-slot:item.type="{ item }">
            <span v-if="item.type !== undefined">
              {{ $t(`report_detail.` + item.type.toLowerCase()) }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReportItem from "@/components/main/financialReports/ReportItem";
import Config from "@/config/config";

export default {
  name: "",
  props: {
    report: {
      money_records: [],
    },
  },
  components: { ReportItem },
  computed: {
    headersLocalized() {
      var retval = [...this.headers];
      for (var i = 0; i < this.headers.length; ++i) {
        if (retval[i].textKey) {
          retval[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return retval;
    },
  },
  methods: {
    paginate(page) {
      this.pageText = Config.generatePaginationOfText(
        page,
        this.$t("general.pagination_page_of")
      );
    },
    getObjectPopsToShow(item, hidePops) {
      let keys = Object.keys(item);
      hidePops.forEach((element) => {
        const index = keys.findIndex((t) => t === element);
        keys.splice(index, 1);
      });
      return keys;
    },
  },
  data() {
    return {
      pageText: "",
      hideReportProps: [
        "_id",
        "money_records",
        "msg_id",
        "time_mark",
        "time_number",
        "device_id",
        "evt",
        "device_type",
        "hap",
        "log_level",
        "timestamp",
        "msg_event"
      ],
      search: "",
      headers: [
        {
          textKey: "report_detail.th_type",
          text: this.$t("report_detail.th_type"),
          align: "center",
          value: "type",
          class: "grey lighten-2",
        },
        {
          textKey: "report_detail.th_nominal",
          text: this.$t("report_detail.th_nominal"),
          align: "center",
          value: "nominal",
          class: "grey lighten-2",
        },
        {
          textKey: "report_detail.th_currency",
          text: this.$t("report_detail.th_currency"),
          align: "center",
          value: "currency",
          class: "grey lighten-2",
        },
        {
          textKey: "report_detail.th_tube_stat",
          text: this.$t("report_detail.th_tube_stat"),
          align: "center",
          value: "tube_stat",
          class: "grey lighten-2",
        },
        {
          textKey: "report_detail.th_cashbox_stat",
          text: this.$t("report_detail.th_cashbox_stat"),
          align: "center",
          value: "cashbox_stat",
          class: "grey lighten-2",
          width: "150px",
        },
        {
          textKey: "report_detail.th_refill_stat",
          text: this.$t("report_detail.th_refill_stat"),
          align: "center",
          value: "refill_stat",
          class: "grey lighten-2",
        },
        {
          textKey: "report_detail.th_empty_stat",
          text: this.$t("report_detail.th_empty_stat"),
          align: "center",
          value: "empty_stat",
          class: "grey lighten-2",
        },
      ],
    };
  },
};
</script>
