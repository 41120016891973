<template>
  <v-tooltip v-if="getInfoText(device)" left max-width="400" close-delay="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon>
        <v-icon
            v-bind="attrs"
            v-on="on"
            small
            class="pa-0 pt-3 pb-3"
            color="purple">
          circle
        </v-icon>
      </v-btn>
    </template>
    <div class="pa-0">
      <DeviceInfoChips :device="device"/>
    </div>
  </v-tooltip>
</template>

<script>

import DeviceInfoChips from "@/components/main/devices/DeviceInfoChips";
export default {
  name: "DeviceInfoIcon",
  components: {DeviceInfoChips},
  props: ["device"],
  methods: {
    getInfoText(item) {
      if ((item.hasOwnProperty("infoText")) && (item.infoText !== '')) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style scoped>

</style>