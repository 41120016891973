<template>
  <v-card class=" elevation-0">
    <v-row>
      <v-col cols="2">
        <StateFilter @input="stateSelected"/>
      </v-col>
      <v-col cols="5">
        <TypeFilter @input="typeSelected"/>
      </v-col>
      <v-col cols="5">
        <InstallationFilter @input="installationSelected"/>
      </v-col>
      <v-col cols="5">
        <UserTextFilter @input="userTextSelected"/>
      </v-col>
      <v-col cols="5">
        <GroupFilter @input="groupSelected"/>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('general.search')"
            single-line
            hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters style="border-bottom: solid #dddddd 1px">
      <v-col cols="2">

      </v-col>
      <v-col cols="2">

      </v-col>
      <v-col cols="4" class="font-weight-medium row-pointer" @click="clickSort('name')">
       {{ $t("device.device_name") }}<v-icon right v-if="sortBy === 'name' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'name' && !descending">mdi-arrow-up</v-icon>
      </v-col>
      <v-col cols="4" class="font-weight-medium row-pointer" @click="clickSort('installation_name')">
        {{ $t("device.hub_name") }}<v-icon right v-if="sortBy === 'installation_name' && descending">mdi-arrow-down</v-icon><v-icon right v-if="sortBy === 'installation_name' && !descending">mdi-arrow-up</v-icon>
      </v-col>
    </v-row>

    <v-virtual-scroll :items="devices" :item-height="37" :height="height" ref="list" class="mt-3">
      <template v-slot:default="{ item }">
        <v-row :key="item._id" class="align-center row-pointer ma-0" no-gutters @click="onClickItem(item)"
                :class="{'active': item._id === selectedId}">
          <v-col cols="2" class="align-center">
            <DeviceStatusIcon :device="item" />
            <DeviceInfoIcon :device="item" />
          </v-col>
        
          <v-col cols="2">
            <DeviceTypeIcon :type="item.type"/>
          </v-col>
          <v-col cols="4" class="overflow-hidden">
              {{ item.name }}
          </v-col>
          <v-col cols="4" class="overflow-hidden">
            {{ item.installation_name }}
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </template>

    </v-virtual-scroll>
  </v-card>
</template>

<script>

import DeviceCreateDialog from "@/components/main/devices/management/DeviceCreateDialog";
import DeviceStatusIcon from "@/components/main/devices/DeviceStatusIcon";
import DeviceInfoIcon from "@/components/main/devices/DeviceInfoIcon";
import DeviceDeleteDialog from "@/components/main/devices/management/DeviceDeleteDialog";
import DeviceTypeIcon from "@/components/main/devices/DeviceTypeIcon";
import {mapGetters} from "vuex";
import StateFilter from "@/components/main/devices/control/filters/StateFilter";
import TypeFilter from "@/components/main/devices/control/filters/TypeFilter";
import InstallationFilter from "@/components/main/devices/control/filters/InstallationFilter";
import UserTextFilter from "@/components/main/devices/control/filters/UserTextFilter.vue";
import GroupFilter from "@/components/main/devices/control/filters/GroupFilter.vue";
import router from "@/router";

export default {
  name: "DeviceList",
  components: {
    InstallationFilter,
    UserTextFilter,
    TypeFilter, StateFilter, GroupFilter, DeviceTypeIcon, DeviceDeleteDialog, DeviceStatusIcon, DeviceInfoIcon, DeviceCreateDialog},
  data() {
    return {
      selectedInstallations: [],
      selectedStates: [],
      selectedTypes: [],
      selectedUserTexts: [],
      selectedGroups: [],
      selectedId: undefined,
      descending: false,
      sortBy: "name",
      search: "",
      sortFields: [
        {
          text: this.$t("device.device_name"),
          value: "name"
        }, {
          text: this.$t("device.hub_name"),
          value: "installation_name"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["getAllDevices"]),

    descAscIcon() {
      return this.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
    },

    height() {
      return this.$windowHeight - 210
    },   

    devices() {
      let filtered = this.filterByInstallation(this.getAllDevices)
      filtered = this.filterByType(filtered)
      filtered = this.filterByState(filtered)
      filtered = this.filterByFulltextSearch(filtered)
      filtered = this.filterByUserText(filtered)
      filtered = this.filterByGroup(filtered)

      const selectedId = this.selectedId
      const selected = filtered.find(a => a._id === selectedId)
      if(selected)
        this.$nextTick(() => this.$emit("input", JSON.parse(JSON.stringify(selected))))
      else {
        this.selectedId = undefined
        this.$nextTick(() => this.$emit("input", null))
      }

      return filtered.sort((left, right) => {
        if (this.descending)
          return -1 * left[this.sortBy].localeCompare(right[this.sortBy])
        else
          return left[this.sortBy].localeCompare(right[this.sortBy])
      })
    }
  },

  methods: {
    onClickItem: async function (item) {
      if(this.selectedId === item._id)
        return

      this.selectedId = item._id
      await router.push({name: "Devices", params: { selectedDevice: item._id }})
    },
    clickSort: function(field) {
      if(this.sortBy === field)
        this.descending = !this.descending
      else
        this.sortBy = field
    },
    installationSelected(data) {
      this.selectedInstallations = data;
    },
    userTextSelected(data) {
      this.selectedUserTexts = data;
    },
    groupSelected(data) {
      this.selectedGroups = data
    },
    stateSelected(data) {
      this.selectedStates = data;
    },
    typeSelected(data) {
      this.selectedTypes = data;
    },
    filterByInstallation(data) {
      let filtered = data.filter((elem) => {
        let contains = false;
        if (this.selectedInstallations === undefined || this.selectedInstallations.length === 0) {
          return true;
        }
        for (const installationID of this.selectedInstallations) {
          if (elem.installation_id === installationID) {
            contains = true;
          }
        }
        return contains;
      })
      return filtered;
    },
    filterByType(data) {
      let filtered = data.filter((elem) => {
        let contains = false;
        if (this.selectedTypes === undefined || this.selectedTypes.length === 0) {
          return true;
        }
        for (const type of this.selectedTypes) {
          if (elem.type === type) {
            contains = true;
          }
        }
        return contains;
      })
      return filtered;
    },
    filterByState(data) {
      let filtered = data.filter((elem) => {
        let contains = false;
        if (this.selectedStates === undefined || this.selectedStates.length === 0) {
          return true;
        }
        for (const status of this.selectedStates) {
          if (status === 'issues' && elem.status === false) {
            contains = true;
          } else if (status === 'online' && elem.is_connected == true && (elem.status === undefined || elem.status === true)) {
            contains = true;
          } else if (status === 'offline' && elem.is_connected == false && (elem.status === undefined || elem.status === true)) {
            contains = true;
          }
        }
        return contains;
      })
      return filtered;
    },
    filterByUserText(data) {
      let filtered = data.filter((elem) => {
        let contains = false;
        if (this.selectedUserTexts === undefined || this.selectedUserTexts.length === 0) {
          return true;
        }
        for (const userText of this.selectedUserTexts) {
          if (elem.infoText === userText) {
            contains = true;
          } else {
          }
        }
        return contains;
      })
      return filtered;
    },
    filterByGroup(data) {
      let filtered = data.filter((elem) => {
        let contains = false;
        if (this.selectedGroups === undefined || this.selectedGroups.length === 0) {
          return true;
        }
        for (const group of this.selectedGroups) {
          if (elem.group === group) {
            contains = true;
          } else {
          }
        }
        return contains;
      })
      return filtered;
    },
    filterByFulltextSearch(data) {
      if(this.search === "")
        return data

      let low_search = this.search.toLowerCase()

      return data.filter((elem) =>
          elem.name.toLowerCase().includes(low_search) ||
          elem.installation_name.toLowerCase().includes(low_search)
      )
    }
  },
}
</script>

<style scoped>
  .row-pointer  {
    cursor: pointer;
  }

  .active {
    background-color: #dddddd;
  }

  .overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
  }
</style>