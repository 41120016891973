<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on:click="dialogDelete = true" icon>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t("general.delete_sanity_check") }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
        >{{ $t("general.ok") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Provider from "@/api/provider";
import Config from "@/config/config";
import util from "util";

export default {
  name: "InstallationDeleteDialog",
  props: ["device", "installation"],
  data() {
    return {
      dialogDelete: false
    }
  },
  methods: {
    deleteItemConfirm() {
      Provider.deleteData(
          util.format(Config.endpointDevicePathVar, this.device._id), {})
          .then((response) => {
            this.installation.devices = this.installation.devices.filter(item => item._id !== this.device._id)
            this.$emit("deleted", this.device)
            this.closeDelete();
          })
          .catch((error) => {
            //chyba
          });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.selectedInstallation);
        this.editedIndex = -1;
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
}
</script>

<style scoped>

</style>