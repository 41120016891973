<template>
  <v-app>
    <v-main style="flex: 0 0 auto;">
      <AppView />
    </v-main>

    <Snackbar :value="snackbar" :text="snackbar_text" :type="snackbar_type" @closed="snackbar = false" />
  </v-app>
</template>

<script>
import Provider from "@/api/provider";

const axios = require("axios");
import AppView from "@/components/AppView";
import { mapActions } from "vuex";
import Config from "@/config/config";
import Snackbar from "@/components/main/Snackbar"

export default {
  name: "App",

  components: {
    AppView, Snackbar
  },

  data: () => ({
    snackbar: false,
    snackbar_text: "",
    snackbar_type: "",
    reloader: null
  }),

  mounted() {
    this.loadData()
    this.reloader = setInterval(this.loadData, runtime_config.device_refresh_interval_ms)
  },

  beforeDestroy() {
    if(this.reloader != null)
      clearInterval(this.reloader)
    this.reloader = null
  },

  methods: {
    ...mapActions(["selectCard", "setAllInstallations", "setAllDevices", "setAllUserTexts"]),

    async loadData() {
      await this.loadInstallations()
      await this.loadDevices()
      await this.loadAllUserTexts()
    },

    async loadInstallations() {
      await Provider.getData(Config.endpointInstallationList, {})
          .then((response) => {
            this.setAllInstallations(response);
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          });
    },

    async loadDevices() {
      await Provider.getData(Config.endpointDevice, {})
          .then(async (response) => {
            await this.setAllDevices(response)
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          })
    },

    async loadAllUserTexts() {
      await Provider.getData(Config.endpointTexts, {})
          .then(async (response) => {
            await this.setAllUserTexts(response)
          })
          .catch((error) => {
            this.snackbar_type = "error"
            this.snackbar_text = error.message
            this.snackbar = true
          })
    }
  },
};
</script>

<style>

</style>
