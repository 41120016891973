<template lang="">
    <v-col cols="12" class="pr-6">
        <v-btn @click="$emit('create',{filterType:'group',filterValues:[],channel:'email',recipient:''})" color="primary" block>{{$t('notifications.createNewBtn')}}</v-btn>
    </v-col>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>