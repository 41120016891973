<template>
  <router-view name="appView"></router-view>
</template>

<script>
export default {
  name: "AppView",

  data: () => ({}),
};
</script>
