<template>
  <div class="mt-6 pl-2">
    <GP4AM_parameters :device="device" v-if="device.type==='paybox'"/>
    <GP4BC_parameters :device="device" v-if="device.type==='gp4bc'"></GP4BC_parameters>
  </div>
</template>

<script>
import GP4AM_parameters from "@/components/main/devices/control/parameters/GP4AM/GP4AM_parameters";
import GenericDevice from "@/components/main/devices/control/parameters/GenericDevice";
import GP4BC_parameters from "@/components/main/devices/control/parameters/GP4BC/GP4BC_parameters";

export default {
  name: "DeviceParameters",
  components: {GP4BC_parameters, GenericDevice, GP4AM_parameters},
  props: ["device"],
}
</script>

<style scoped>

</style>