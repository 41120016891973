<template>
  <div>
    <v-data-table :items="logs"
                  :headers="headersLocalized">

    </v-data-table>
  </div>
</template>

<script>
import provider from "@/api/provider.js";
import config from "@/config/config.js";
import * as util from "util";

export default {
  name: "DeviceLogs",
  props: ["deviceId"],
  data() {
    return {
      logs: [],
      headers: [
        {
          text: "",
          align: "center",
          value: "data.msg_event",
          class: "grey lighten-2 pa-0",
          sortable: false,
        },
      ],
    }
  },
  mounted() {
    provider.getData(util.format(config.endpointDeviceLogs, this.deviceId)).then((response) => {
      this.logs = response
    })
  },
  watch: {
    deviceId() {
      provider.getData(util.format(config.endpointDeviceLogs, this.deviceId)).then((response) => {
        this.logs = response
      })
    }
  },
  computed: {
    headersLocalized() {
      let ret = [...this.headers];
      for (let i = 0; i < this.headers.length; ++i) {
        if (ret[i].textKey) {
          ret[i].text = this.$t(this.headers[i].textKey);
        }
      }
      return ret;
    },
  }
}
</script>

<style scoped>

</style>